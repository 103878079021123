import {
  Label,
  Panel,
  PanelType,
  Stack,
  PrimaryButton,
  Modal,
} from "office-ui-fabric-react";
import styles from "../Components/SCSS/Ed.module.scss";
import {
  encryptData,
  getCurrentUser,
  VERSION,
} from "./Helpers/HelperFunctions";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import MiniModals from "./SelectSource/MiniModals";
import { LangOptions } from "../Language/LanguageOptions";
import { useLanguage } from "../Language/LanguageContext";
import { updateSettingJson, USER_LIST } from "../api/storage";
import { useLists } from "../context/store";
import { SweetAlerts } from "./SelectSource/Utils/SweetAlert";

const qr = require("../Components/assets/images/hr265QR.png");

const HelpPage = ({ isOpen, onDismiss }) => {
  const { SweetAlert: SweetAlerthelpPanel } = SweetAlerts("#helppanel", true);
  const isUserAdmin=useSelector((state:any)=>state.storeSettings.isCurrntUserAdmin);
  const stylesReactSelect = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: "0px",
      minHeight: "32px",
      width: "400px",
      boxShadow: state.isFocused ? `0 0 0 .5px black` : provided.boxShadow,
      borderColor: state.isFocused ? `black` : provided.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? `black` : provided.borderColor,
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#AAA",
      fontSize: "15px",
    }),
  };

  const { usersList, setUsersList } = useLists();
  const { setLanguagePartUpdate, setLanguage } = useLanguage();
  const M365User = useSelector((state: any) => state.storeSettings.M365Users);
  const currentUser = getCurrentUser().cu;
  const [Lang, setLang] = useState<any>([]);
  const [showLangPopup, setShowLangPopup] = useState(false);

  useEffect(() => {
    let lang = {};
    if (usersList?.UsersLang) {
      lang = usersList?.UsersLang[currentUser]?.SelectedLanguage;
    } else {
      lang = {
        key: "en",
        text: "English",
        value: "en",
        label: "English",
      };
    }

    setLang(lang);
  }, []);

  const handleLanguage = async () => {
    const lang = Lang.key as any;
    setLanguage(lang);
    const updatedUserList = {
      ...usersList,
      UsersLang: {
        ...usersList?.UsersLang,
        [currentUser]: {
          SelectedLanguage: {
            key: Lang.key,
            text: Lang.text,
            value: Lang.value,
            label: Lang.label,
          },
          isAdmin:isUserAdmin??false,
        },
      },
    };

    setUsersList(updatedUserList);
    SweetAlerthelpPanel("success","Setting saved successfully")
    await updateSettingJson(
      USER_LIST,
      encryptData(JSON.stringify(updatedUserList))
    );
    setLanguagePartUpdate((prev) => !prev);
    setTimeout(() => {
      
      setShowLangPopup(false);
    }, 2100);
  };

  const renderLanguageOption = () => {
    return Lang ? Lang?.label : "English";
  };

  const renderLink = (href: string, label: string) => (
    <div style={linkStyle}>
      <a style={linkTextStyle} href={href} target="_blank">
        {label}
      </a>
    </div>
  );

  const linkStyle: any = {
    padding: "10px 0",
    width: "100%",
    background: "#fff",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
  };

  const linkTextStyle = {
    textDecoration: "none",
    color: "#000",
  };

  return (
    <Panel
      type={PanelType.custom}
      customWidth="650px"
      className={styles.helpPanelStyles}
      styles={{
        root: {
          ".ms-Panel-content": {
            padding: "0px",
          },
          "& .ms-Panel-contentInner": {
            display: "block",
          },
        },
      }}
      headerText="Help"
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <Stack id="helppanel" horizontal className="panel">
        {/* Left Panel */}
        <div className="left-panel">
          <div style={linkStyle}>
            <img
              width={160}
              src="https://ik.imagekit.io/zn4au2jftpm5/hr365/HR365%20for%20site%20official%20PNG_UiZ5jBToP.png?updatedAt=1690782512755"
              alt="HR365"
            />
          </div>
          {renderLink(`mailto:${currentUser}`, "CORS")}
          <div style={linkStyle} onClick={() => setShowLangPopup(true)}>
            Language: {renderLanguageOption()}
          </div>
          {renderLink("https://www.hr365.us/support/", "Support")}
          {renderLink("https://www.hr365.us/privacy-policy/", "Privacy Policy")}
          {renderLink(
            "https://kb.hr365.us/sharepoint-employee-directory/modern/faq/",
            "FAQ"
          )}
          {renderLink("#", "Release Notes")}
        </div>

        {/* Right Panel */}
        <div
          className="right-panel"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "230px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-start",
                }}
              >
                <p style={{ flex: "0 0 150px" }}>Licence Type:</p>
                <p style={{ flex: "0 0 150px" }}>Valid Till:</p>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-start",
                }}
              >
                <p style={{ flex: "0 0 150px", whiteSpace: "nowrap" }}>
                  Version: {VERSION}
                </p>
                <p style={{ flex: "0 0 150px" }}>
                  Total Users: {M365User?.length}
                </p>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <img src={qr} />
            <a
              style={{ color: "#000", textAlign: "center" }}
              target="_blank"
              href="https://www.hr365.us/"
            >
              www.hr365.us
            </a>
          </div>
        </div>
      </Stack>

      {/* Language Modal */}
      {showLangPopup && (
        <MiniModals
          isPanel={false}
          crossButton={true}
          heading="Language"
          closeAction={() => setShowLangPopup(false)}
        >
          <div
            style={{
              height: "180px",
              display: "flex",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: "-15px" }}>
              <ReactSelect
                options={LangOptions}
                value={Lang}
                onChange={(option) => setLang(option)}
                isClearable
                styles={stylesReactSelect}
                maxMenuHeight={100}
              />
            </div>
            <PrimaryButton onClick={handleLanguage} text="Save" />
          </div>
        </MiniModals>
      )}
    </Panel>
  );
};

export default HelpPage;
