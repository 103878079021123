// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 112, 220, 0.4);
  width: 100%;
  margin: auto;
  border: 2px dotted rgb(0, 112, 220);
}

.drag_text {
  font-size: 20px;
  color: rgb(0, 112, 220);
}`, "",{"version":3,"sources":["webpack://./src/Components/SCSS/DragDrop.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,wCAAA;EACA,WAAA;EACA,YAAA;EACA,mCAAA;AACJ;;AACE;EACA,eAAA;EACA,uBAAA;AAEF","sourcesContent":[".file-input-icon {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgb(0,112,220,0.4);\n    width: 100%;\n    margin: auto;\n    border: 2px dotted rgb(0,112,220);\n  }\n  .drag_text{\n  font-size: 20px;\n  color: rgb(0,112,220);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
