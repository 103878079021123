import { IconButton, Label } from "@fluentui/react";
import FullOrgChart from "../OrgChart/FullOrgChart";
import styles from "../SCSS/OrgChartPage.module.scss";
import { useLanguage } from "../../Language/LanguageContext";
import { Home_KEY } from "../Helpers/HelperFunctions";
import { setSelectedLetter, setShowHidePages } from "../../Store/appSettingsSlice";
import { useDispatch } from "react-redux";

const OrgChartPage = ({
  setShowOrgChart,
  setShowHomePage,
  filterByLetter,
  setSettings,
  setshowDashboard,
}) => {
  const { translation } = useLanguage();
  const dispatch=useDispatch();
  return (
    <div>
      <div className={styles.container}>
        <div>
          <Label className={styles.orgChartHeader}>
            {translation.OrganizationalChart
              ? translation.OrganizationalChart
              : "Organization Chart"}
          </Label>
        </div>
        <div className={styles.cancleBtn}>
          <IconButton
            title={"Close"}
            iconProps={{ iconName: "ChromeClose" }}
            style={{
              backgroundColor: "transparent",
              marginRight: "10px",
            }}
            onClick={() => {
              // setShowOrgChart(false);
              // setShowHomePage(true);
              dispatch(setShowHidePages(Home_KEY))
              dispatch(setSelectedLetter("All"));
              // filterByLetter("ALL");
              // document.getElementById(`letter-ALL`).click();

            }}
          />
        </div>
      </div>
      <FullOrgChart
        setSettings={setSettings}
        setShowHomePage={setShowHomePage}
        setShowOrgChart={setShowOrgChart}
        setshowDashboard={setshowDashboard}
      />
    </div>
  );
};

export default OrgChartPage;
