// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reactOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {
  border-right: 1px solid #c8c8c8ed;
}
`, "",{"version":3,"sources":["webpack://./src/Components/OrgChart/modal.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC","sourcesContent":[".reactOrgChart .orgNodeChildGroup .nodeGroupLineVerticalMiddle {\n  border-right: 1px solid #c8c8c8ed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
