// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalOrgChart_topbar__RhD-c {
  height: 10px;
  width: 230px;
  background-color: rgb(0, 112, 220);
}

.ModalOrgChart_singleNodeTopBar__\\+56-y {
  height: 10px;
  width: 100%;
  background-color: rgb(51, 51, 51);
}

.ModalOrgChart_warpper__dsPTu {
  border: 1px solid rgba(200, 200, 200, 0.9294117647);
  background-color: #f5f5f5;
  display: flex;
  padding: 8px 14px;
  gap: 10px;
  align-items: center;
  min-width: 200px;
  max-width: 200px;
}

.ModalOrgChart_textContainer__Q4fjZ {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.ModalOrgChart_name__Xyg81 {
  font-size: 13px;
  font-weight: 500;
}

.ModalOrgChart_text__yc1SY {
  font-size: 11px;
  font-weight: 400;
}

.ModalOrgChart_lines__oU1uQ {
  width: 130px;
  height: 102px;
  margin-top: -3px;
  margin-left: -2px;
  border-bottom: 1px solid rgba(200, 200, 200, 0.9294117647);
  border-right: 1px solid rgba(200, 200, 200, 0.9294117647);
}`, "",{"version":3,"sources":["webpack://./src/Components/OrgChart/ModalOrgChart.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,kCAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,iCAAA;AACF;;AAEA;EACE,mDAAA;EACA,yBAAA;EACA,aAAA;EACA,iBAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,0DAAA;EACA,yDAAA;AACF","sourcesContent":[".topbar {\n  height: 10px;\n  width: 230px;\n  background-color: rgb(0, 112, 220);\n}\n\n.singleNodeTopBar {\n  height: 10px;\n  width: 100%;\n  background-color:rgb(51, 51, 51);\n}\n\n.warpper {\n  border: 1px solid #c8c8c8ed;\n  background-color: #f5f5f5;\n  display: flex;\n  padding: 8px 14px;\n  gap: 10px;\n  align-items: center;\n  min-width: 200px;\n  max-width: 200px;\n}\n\n.textContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: start;\n}\n\n.name {\n  font-size: 13px;\n  font-weight: 500;\n}\n\n.text {\n  font-size: 11px;\n  font-weight: 400;\n}\n\n.lines {\n  width: 130px;\n  height: 102px;\n  margin-top: -3px;\n  margin-left: -2px;\n  border-bottom: 1px solid #c8c8c8ed;\n  border-right: 1px solid #c8c8c8ed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topbar": `ModalOrgChart_topbar__RhD-c`,
	"singleNodeTopBar": `ModalOrgChart_singleNodeTopBar__+56-y`,
	"warpper": `ModalOrgChart_warpper__dsPTu`,
	"textContainer": `ModalOrgChart_textContainer__Q4fjZ`,
	"name": `ModalOrgChart_name__Xyg81`,
	"text": `ModalOrgChart_text__yc1SY`,
	"lines": `ModalOrgChart_lines__oU1uQ`
};
export default ___CSS_LOADER_EXPORT___;
