import React, { createContext, useState, useContext, useEffect } from "react";
import { Language } from "./Languages";
import { gapi } from "gapi-script";
import { updateData } from "../Components/Utils/ApiCalls/ApiCalls";
import { useSttings } from "../Components/SelectSource/store";
import { getSettingJson, IMAGES_LIST, SETTING_LIST } from "../api/storage";
import { useDispatch, useSelector } from "react-redux";
import { setAllSettings } from "../Store/appSettingsSlice";
import { useLists } from "../context/store";
import { getCurrentUser } from "../Components/Helpers/HelperFunctions";

const LanguageContext = createContext(null);

export const LanguageProvider = ({ children }) => {
  const dispatch = useDispatch();
  const CurrentUserData = useSelector(
    (state: any) => state.storeSettings.CurrentUserData
  );
  const { usersList, setUsersList } = useLists();

  const [languagePartUpdate, setLanguagePartUpdate] = useState(false);
  const [languages, setLanguage] = useState<any>("en");
  const [translation, setTranslation] = useState({});
  const { setAppSettings } = useSttings();

  function getLanguageObject(langCode) {
    let newTranslation = {};

    Language.forEach((item) => {
      const transl = item?.convertText?.find(
        (textObj) => textObj?.LangCode === langCode
      );

      newTranslation = {
        ...newTranslation,
        [item.Key]: transl?.Text || item?.Text,
      };
    });

    return Object.keys(newTranslation).length > 0
      ? newTranslation
      : Language.reduce((acc, item) => {
          acc[item.Key] = item.Text;
          return acc;
        }, {});
  }

  useEffect(() => {
    let lang;
    let settingData: any;

    let currentUserEmail = getCurrentUser()?.cu;
    let CurrentUsers: any;
    if (Object.keys(CurrentUserData)?.length) {
      CurrentUsers = CurrentUserData?.CurrentUsers?.find(
        (item) => item?.email == currentUserEmail
      );
    }
    getSettingJson(SETTING_LIST).then((data) => {
      settingData = data;
      if (
        usersList?.UsersLang?.[currentUserEmail]?.SelectedLanguage?.key &&
        usersList?.UsersLang?.[currentUserEmail]?.isAdmin
      ) {
        if (usersList?.UsersLang?.[currentUserEmail]?.BrowserLanguageActive) {
          lang = window.navigator.language;
        } else {
          lang =
            usersList?.UsersLang?.[currentUserEmail]?.SelectedLanguage?.key ??
            "en";
        }
      } else {
        lang =
          usersList?.UsersLang?.[currentUserEmail]?.SelectedLanguage?.key ??
          "en";
      }

      const newTranslation = getLanguageObject(lang);
      setTranslation(newTranslation);
      console.log("only one", newTranslation);
    });
  }, [languagePartUpdate]);

  return (
    <LanguageContext.Provider
      value={{
        languagePartUpdate,
        setLanguagePartUpdate,
        translation,
        setTranslation,
        languages,
        setLanguage,
        getLanguageObject,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
