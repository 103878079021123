import { useState, useEffect } from "react";
import { PrimaryButton, Checkbox, Panel, PanelType } from "@fluentui/react";
import { useLanguage } from "../../Language/LanguageContext";
import { SweetAlerts } from "../SelectSource/Utils/SweetAlert";
import { useFields, useLists } from "../../context/store";
import UserSearchBox from "../SelectSource/UserSearchBox";
import { useSttings } from "../SelectSource/store";
import { getSettingJson, SETTING_LIST, updateSettingJson } from "../../api/storage";
import { defaultSettingList } from "../../api/defaultSettings";
import Select from 'react-select';
import { getCurrentUser, removeDuplicatesFromObject } from "../Helpers/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";

const KEY = "RolesAndPermisstions";

function RolesAndPermisstions(props: any) {
  const appSettings=useSelector((state:any)=>state.storeSettings.appSettings);
  const dispatch=useDispatch();
  const { isOpen, onDismiss } = props;
  const [isClearUserSearch, setClearUserSearch] = useState(false);
  const { SweetAlert: SweetAlertInPanel } = SweetAlerts("#RAN", true);
  const { SweetAlert: deleteSweetAlert } = SweetAlerts("#RAN", true);
  const { translation } = useLanguage();
  const [admins, setAdmins] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedUsers, setSelectedUser] = useState([]);
  const { allUsers } = useFields();
  const {setAppSettings } = useSttings();
  const { settingList, setSettingList } = useLists();
  useEffect(()=>{
  setClearUserSearch(true);
  },[])
  // Get the current user's email
  const currentUserEmail = getCurrentUser()?.cu;

  function transformUserDataToFormat(users) {
    return users?.map((user) => ({
      id: user?.id,
      image: user?.image,
      initials: user?.initials,
      name: user?.name,
      job: user?.job,
      email: user?.email,
      department: user?.department,
      dob: user?.DOB,
      doj: user?.DOJ,
      location: user?.location,
    }));
  }

  const handleAdd = () => {
    if (!user|| !user.email) {
      SweetAlertInPanel("error", "Please select a valid user.");
      return;
    }

    if (admins.some((x) => x.email === user.email)) {
      SweetAlertInPanel("error", "User is already admin.");
      return;
    }

    const updatedAdmins = [...admins, { name: user.name, email: user.email }];
    setAdmins(updatedAdmins);
    const setting = {  [KEY]: updatedAdmins };
    updateSettingJson(SETTING_LIST, setting,true);
    SweetAlertInPanel("success", translation.SettingSaved);
    setSelectedUser([]);
    setClearUserSearch(prev=>!prev); 
  };

  const handleDelete = () => {
    // Check if the current user is trying to delete themselves
    if (selectedUsers.includes(currentUserEmail)) {
      SweetAlertInPanel("error", "You cannot delete yourself.");
      return;
    }

    const remainingAdmins = admins.filter((x) => !selectedUsers.includes(x.email));
    setAdmins(remainingAdmins);
    const setting = {  [KEY]: remainingAdmins };
    updateSettingJson(SETTING_LIST, setting,true);
    // setAppSettings(setting);

    // setSettingList(setting);
    setSelectedUser([]);
    deleteSweetAlert("success", translation.SettingSaved);
  };

  useEffect(() => {
    const getSettingData = async () => {
      const data = (await getSettingJson(SETTING_LIST)) || defaultSettingList;
      setAdmins(data[KEY] ?? []);
    };
    getSettingData();
  }, []);

  return (
    <div>
      <Panel
        type={PanelType.custom}
        customWidth="650px"
        headerText={translation.Updateusersroleandpermissions ?? "Role and permissions"}
        isOpen={isOpen}
        onDismiss={onDismiss}
        closeButtonAriaLabel="Close"
      >
        <div id="RAN">

          <div style={{ margin: "10px 0px", display: "flex", justifyContent: "space-between", gap: "5px" }}>
            <UserSearchBox
              clearInput={isClearUserSearch}
              options={transformUserDataToFormat(allUsers)}
              onSelect={setUser}
              
             
            />
            <PrimaryButton style={{ height: "fit-content" }} onClick={handleAdd}>
              Add
            </PrimaryButton>
            {selectedUsers.length > 0 && (
              <PrimaryButton style={{ height: "fit-content" }} onClick={handleDelete}>
                Delete
              </PrimaryButton>
            )}
          </div>
          <div>
            <ShowTable
              admins={removeDuplicatesFromObject(admins,"email")}
              selectedUsers={selectedUsers}
              setSelectedUser={setSelectedUser}
              currentUserEmail={currentUserEmail}
            />
          </div>
        </div>
      </Panel>
    </div>
  );
}

const ShowTable = ({ admins, selectedUsers, setSelectedUser, currentUserEmail }) => {
  const { translation } = useLanguage();

  const handleSelectEmail = (checked, email) => {
    if (checked) {
      setSelectedUser((prev) => [...prev, email]);
    } else {
      setSelectedUser((prev) => prev.filter((x) => x !== email));
    }
  };

  return (
    <table className="excludeTable">
      <thead>
        <tr>
          <th>Select</th>
          <th>Employee Name</th>
          <th>Email</th>
          <th>Role</th>
        </tr>
      </thead>
      {admins && admins.length > 0 ? (
        <tbody>
          {admins.map((x, i) => {
            const isCurrentUser = x.email === currentUserEmail;
            return (
              <tr key={i}>
                <td>
                  <Checkbox
                    // disabled={isCurrentUser}
                    checked={selectedUsers.includes(x.email)}
                    onChange={(e, checked) => handleSelectEmail(checked, x.email)}
                  />
                </td>
                <td>{x.name}</td>
                <td>{x.email}</td>
                <td>Admin</td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td colSpan={4}>{translation.NoRecordsFound ?? "No Records Found"}</td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default RolesAndPermisstions;