// import React, { useState, useEffect } from 'react';
// import { gapi } from 'gapi-script';
// import "./Test.css"
// const UserCard = ({ user }) => {
//   return (
//     <div className="user-card">
//       <img src={user.photoUrl} alt={user.name} />
//       <h3>{user.name}</h3>
//       <p>{user.email}</p>
//       <p>{user.phone}</p>
//     </div>
//   );
// };

// const Test = () => {
//   const [users, setUsers] = useState([]);
//   const [nextPageToken, setNextPageToken] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const fetchUsers = (pageToken = '') => {
//     setLoading(true);
//     gapi.client.load('admin', 'directory_v1', () => {
//       const request = gapi.client.directory.users.list({
//         domain: 'megazap.us', // replace with your domain
//         maxResults: 1,
//         pageToken: pageToken,
//       });

//       request.execute((response) => {
//         if (response.error) {
//           console.error('Error fetching users:', response.error);
//         } else {
//             let staffMember = response.users?.map((user) => {
//                 // console.log("custom fileds->",user.customSchemas)
//                 var arr123 = {};
          
//                 let DOB=checkCustomSchemasForKey(user?.customSchemas,['dob', 'birthday','DOB'])
//                 let DOJ=checkCustomSchemasForKey(user?.customSchemas,['doj', 'dateofjoining', 'joinDate','DOJ'])
//                 let customUrl=checkCustomSchemasForKey(user?.customSchemas,['customUrl'])
//                 departments.push(user.organizations?.[0]?.department)
//                 JobTitles.push(user.organizations?.[0]?.title)
//                 if (user.hasOwnProperty("customSchemas")) {
//                   const cfield = user?.customSchemas;
      
//                   Object.keys(cfield)?.map((key) => {
//                     var exchangefld = cfield[key];
      
//                     for (var i in exchangefld) {
//                       arr123[i] =
//                         exchangefld[i] instanceof Array
//                           ? exchangefld[i].reduce((a, b) => {
//                               return (
//                                 (a?.value?.toString() || a?.toString()) +
//                                 " !! " +
//                                 b?.value
//                               );
//                             })
//                           : exchangefld[i];
//                     }
      
//                     return [key];
//                   });
//                 }
      
//                 if (user?.name?.givenName != null) {
//                   var names = user?.name?.givenName.split(" "),
//                     initials = names[0]?.substring(0, 1)?.toUpperCase();
//                   if (names.length > 1) {
//                     initials += names[names.length - 1]?.substring(0, 1)?.toUpperCase();
//                   }
//                 }
      
//                 var _allex, _allexu;
//                 if (_excspecuser != null && _excspecuser != undefined) {
//                   _allexu =
//                     _excspecuser.filter(
//                       (x) =>
//                         user?.name?.fullName?.toLowerCase()?.indexOf(x.toLowerCase()) >
//                         -1
//                     )?.length > 0;
//                 } else {
//                   _allexu = null;
//                 }
//                 _allex = _allexu;
//                 var _allexuHide =
//                   arrmanager?.filter(
//                     (x) =>
//                       user?.primaryEmail?.toLowerCase()?.indexOf(x?.toLowerCase()) > -1
//                   ).length > 0;
      
//                 const y = parsedData?.AdditionalManagerData?.map((item) => {
//                   if (user?.customSchemas?.OtherFields[item.email]) {
//                     additionManagerData.push(
//                       user?.customSchemas?.OtherFields[item.email]
//                     );
//                   }
//                 });
//                 const assistResult = parsedData?.AssistantData?.map((item) => {
//                   if (user?.customSchemas?.OtherFields[item.email]) {
//                     AssistantData.push(user?.customSchemas?.OtherFields[item.email]);
//                   }
//                 });
      
//                 const xx = {
//                   ...arr123,
//                   firstName: user?.name?.givenName,
//                   lastName: user?.name?.familyName,
//                   name: user?.name?.fullName,
//                   DOB:DOB,
//                   DOJ:DOJ,
//                   customUrl:customUrl,
//                   email: user.primaryEmail,
//                   id: user?.id,
//                   job: user.hasOwnProperty("organizations")
//                     ? user?.organizations[0].hasOwnProperty("title")
//                       ? user?.organizations[0].title
//                       : ""
//                     : "",
//                   initials: initials,
//                   department: user?.hasOwnProperty("organizations")
//                     ? user?.organizations[0]?.hasOwnProperty("department")
//                       ? user?.organizations[0]?.department
//                       : ""
//                     : "",
      
//                   workphone: getUserOrgWPItem(user),
//                   mobile: _allex ? "" : getUserOrgMBItem(user),
//                   manager: _allexuHide ? "" : getUserOrgMGItem(user),
//                   employeeid: getUserOrgEIDItem(user),
//                   costcenter: user.hasOwnProperty("organizations")
//                     ? user.organizations[0].hasOwnProperty("costCenter")
//                       ? user?.organizations[0]?.costCenter
//                       : ""
//                     : "",
//                   buildingid: user.hasOwnProperty("locations")
//                     ? user.locations[0].hasOwnProperty("buildingId")
//                       ? user?.locations[0]?.buildingId
//                       : ""
//                     : "",
//                   floorname: user.hasOwnProperty("locations")
//                     ? user?.locations[0].hasOwnProperty("floorName")
//                       ? user?.locations[0]?.floorName
//                       : ""
//                     : "",
//                   floorsection: user.hasOwnProperty("locations")
//                     ? user?.locations[0].hasOwnProperty("floorSection")
//                       ? user?.locations[0]?.floorSection
//                       : ""
//                     : "",
//                   location: getUserOrgLOCItem(user),
//                   address: getUserOrgADDItem(user),
//                   image: user.hasOwnProperty("thumbnailPhotoUrl")
//                     ? user?.thumbnailPhotoUrl
//                     : DefaultImage,
//                   AdditionalManager: additionManagerData,
//                   AssistantData: AssistantData,
//                   isAdmin: user?.isAdmin,
//                 };
               
                
               
               
//                 return xx;
//               });
//               if (
//                 // usersJson?.Users &&
//                 parsedData?.SyncUserInfoFrom == "Google & Imported User"
//               ) {
//                 customFunctionUser = customFunctionFilter(staffMember, parsedData);
//                 let usersData = ExcludeExternalUsers(customFunctionUser, parsedData);
//                 setUsers((prev)=>[...prev,...usersData])
        
//           setNextPageToken(response.nextPageToken);
//           setLoading(false);
//         }
//       });
//     });
//   };

//   useEffect(() => {
//     gapi.load('client:auth2', () => {
//       gapi.auth2.init({
//         client_id: 'YOUR_CLIENT_ID',
//       });
//     });

//     // Initial load of users when component mounts
//     fetchUsers();
//   }, []);

//   const handleLoadMore = () => {
//     if (nextPageToken) {
//       fetchUsers(nextPageToken);
//     }
//   };

//   return (
//     <div className="users-list">
//       <div className="user-cards">
//         {users.map((user, index) => (
//           <UserCard key={index} user={user} />
//         ))}
//       </div>

//       {loading && <div>Loading...</div>}

//       {!loading && nextPageToken && (
//         <button onClick={handleLoadMore}>Load More</button>
//       )}
//     </div>
//   );
// };

// export default Test;
import React from 'react'

const Test = () => {
  return (
    <div>Test</div>
  )
}

export default Test