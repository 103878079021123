// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrgChartPage_container__tsiM7 {
  text-align: end;
  padding: 5px 0px;
  font-size: 10px;
  display: flex;
  justify-content: center;
}

.OrgChartPage_cancleBtn__cmzqu {
  justify-content: end;
  justify-self: flex-end;
  position: absolute;
  right: 15px;
}

/*------------------ Org Chart Settings SCSS----------------------*/
.OrgChartPage_nameStyles__PfXlu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OrgChartPage_bold__XzBbM {
  background-color: rgb(0, 120, 212);
  color: #fff;
}
.OrgChartPage_bold__XzBbM:hover {
  background-color: rgb(0, 120, 212);
  color: #fff;
}

.OrgChartPage_italic__fAkNq {
  background-color: rgb(0, 120, 212);
  color: #fff;
}
.OrgChartPage_italic__fAkNq:hover {
  background-color: rgb(0, 120, 212);
  color: #fff;
}

.OrgChartPage_underline__XmPaS {
  background-color: rgb(0, 120, 212);
  color: #fff;
}
.OrgChartPage_underline__XmPaS:hover {
  background-color: rgb(0, 120, 212);
  color: #fff;
}

.OrgChartPage_fontStylesGroup__GRNtG {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.OrgChartPage_orgChartHeader__khyRg {
  margin-left: 10px;
  color: rgb(0, 120, 212);
  text-align: center;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/Components/SCSS/OrgChartPage.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,oBAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA,mEAAA;AACA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,kCAAA;EACA,WAAA;AACF;AAAE;EACE,kCAAA;EACA,WAAA;AAEJ;;AAEA;EACE,kCAAA;EACA,WAAA;AACF;AAAE;EACE,kCAAA;EACA,WAAA;AAEJ;;AAEA;EACE,kCAAA;EACA,WAAA;AACF;AAAE;EACE,kCAAA;EACA,WAAA;AAEJ;;AAEA;EACE,aAAA;EACA,QAAA;EACA,gBAAA;AACF;;AAEA;EACE,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;AACF","sourcesContent":[".container {\n  text-align: end;\n  padding: 5px 0px;\n  font-size: 10px;\n  display: flex;\n  justify-content: center;\n}\n\n.cancleBtn{\n  justify-content: end;\n  justify-self: flex-end;\n  position: absolute;\n  right: 15px;\n}\n\n/*------------------ Org Chart Settings SCSS----------------------*/\n.nameStyles {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.bold {\n  background-color: rgb(0, 120, 212);\n  color: #fff;\n  &:hover{\n    background-color: rgb(0, 120, 212);\n    color: #fff;\n  }\n}\n\n.italic {\n  background-color: rgb(0, 120, 212);\n  color: #fff;\n  &:hover{\n    background-color: rgb(0, 120, 212);\n    color: #fff;\n  }\n}\n\n.underline {\n  background-color: rgb(0, 120, 212);\n  color: #fff;\n  &:hover{\n    background-color: rgb(0, 120, 212);\n    color: #fff;\n  }\n}\n\n.fontStylesGroup{\n  display: flex;\n  gap: 5px;\n  margin-top: 10px;\n}\n\n.orgChartHeader{\n  margin-left: 10px;\n  color: rgb(0, 120, 212);\n  text-align: center;\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `OrgChartPage_container__tsiM7`,
	"cancleBtn": `OrgChartPage_cancleBtn__cmzqu`,
	"nameStyles": `OrgChartPage_nameStyles__PfXlu`,
	"bold": `OrgChartPage_bold__XzBbM`,
	"italic": `OrgChartPage_italic__fAkNq`,
	"underline": `OrgChartPage_underline__XmPaS`,
	"fontStylesGroup": `OrgChartPage_fontStylesGroup__GRNtG`,
	"orgChartHeader": `OrgChartPage_orgChartHeader__khyRg`
};
export default ___CSS_LOADER_EXPORT___;
