import { SpinnerSize } from "@fluentui/react";
import { MessageBar, MessageBarType, Spinner } from "@fluentui/react";
import ViewEmployee from "../SelectSource/ViewEmployee";
import { useLanguage } from "../../Language/LanguageContext";
import { Margin } from "react-to-pdf";
import styles from  "../SCSS/Ed.module.scss"
import { useSelector } from "react-redux";
const messageBarInfoStyles = {
  root: {
    ".ms-MessageBar-icon": {
      color: "#333",
    },
    backgroundColor: "rgb(243, 242, 241)",
    Margin:"10px 0",
  },
};

function Home({
  nextPageToken,
  fetchUsers,
  showProgress,
  UserArray,
  listFiles,
  allFetchedUsersd,
  NonM365,
  isTile,
  isGrid,
  isList,
  _gridWidth,
  parsedData,
  allUsers,
  isImportedUser,
  setUserArray
}) {
 const {translation}=useLanguage();
 const userfetcherror=useSelector((state:any)=>state.storeSettings.FetchUserError);
 const code = userfetcherror?.result?.error?.code;
 const message = userfetcherror?.result?.error?.message;
console.log("lokkk",userfetcherror)

  return (
    <div>
      <div className="mainDiv">
        {showProgress ? (
          <Spinner
            size={SpinnerSize.large}
            className="spinnerLoadStyle"
            label={translation.Loading?translation.Loading:"Loading..."}
          />
        ) : UserArray.length == 0  ? (
          <div style={{margin:"15px 0"}}>
          <MessageBar
            messageBarType={MessageBarType.info}
            isMultiline={false}
            styles={messageBarInfoStyles}
            dismissButtonAriaLabel={"Close"}
            style={{ justifyContent: "center" }}
          >
           {code==400 && message=="Invalid Input"?"Looks like you are logged in with personal account, kindly login with the workspace account to continue using the Employee Directory 365": "No Records Found"}
          </MessageBar>
          </div>
        ) : (
          <div id="ViewDiv" style={{ overflow: "hidden" }}>
            <ViewEmployee
            nextPageToken={nextPageToken}
              fetchUsers={fetchUsers}
              employees={UserArray}
              NonM365={NonM365}
              isTile={isTile}
              isGrid={isGrid}
              isList={isList}
              allFetchedUsersd={allFetchedUsersd}
              isImportedUser={isImportedUser}
              DynWidth={_gridWidth}
              settingData={parsedData}
              users={allUsers}
              setUsers={setUserArray}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
