import { useEffect, useState } from "react";
import MiniModals from "../SelectSource/MiniModals";
import "../SCSS/SweetAlert.scss";
import {
  DetailsList,
  IColumn,
  Icon,
  IconButton,
  Label,
  Link,
  Panel,
  PanelType,
  SelectionMode,
  TextField,
  PrimaryButton,
  Toggle,
} from "@fluentui/react";
import { useFields } from "../../context/store";

import { useSttings } from "../SelectSource/store";
import { SweetAlerts } from "../SelectSource/Utils/SweetAlert";
import { SETTING_LIST, updateSettingJson } from "../../api/storage";
import { addNewDepartments, fetchSettings, setAllDepartments, setAllJobTitle, setAllSettings } from "../../Store/appSettingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { dispatch } from "d3";
import { useLanguage } from "../../Language/LanguageContext";

const KEY = "FilterAttribute";

const FilterAttributes = ({ isOpen, onDismiss }:any) => {
    const {translation} = useLanguage();
  const dispatch=useDispatch();
  const appSettings=useSelector((state:any)=>state.storeSettings.appSettings);
  const [openDepPanel, setOpenDepPanel] = useState(false);
  const [openJobPanel, setOpenJobPanel] = useState(false);
  const [openLocationPanel, setOpenLocationPanel] = useState(false);
  const { departments, locations, jobTitles } = useFields();
  const { setAppSettings } = useSttings();
  const [allDepartments, setAllDepartments] = useState([]);
  const [allJobTitles, setAllJballJobTitles] = useState([]);
  const [allLocations, setAllLocations] = useState(locations);
    useEffect(()=>{
        dispatch(fetchSettings())
        setAllDepartments(appSettings.FilterAttribute.departments)
        setAllJballJobTitles(appSettings.FilterAttribute.jobTitles)
    },[])
  const update = (data) => {
    const setting = {
      
      [KEY]: {
        departments: allDepartments,
        jobTitles: allJobTitles,
        locations: allLocations,
      },
    };
    updateSettingJson(SETTING_LIST, setting,true);
    // setAppSettings(setting);
    dispatch(setAllSettings({
      [KEY]: {
        departments: allDepartments,
        jobTitles: allJobTitles,
        locations: allLocations,
      },
    }))
  };

  return (
   <div>
    {
      isOpen &&  <MiniModals
      width={"500px"}
      isPanel={false}
      crossButton={true}
      heading={"Filter attributes"}
      closeAction={() => onDismiss(false)}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Label>{translation.Updatedepartment??"Update department"}</Label>
            <Link onClick={() => setOpenDepPanel(true)}>{translation.Updatedepartment??"Update department"}</Link>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Label>{translation.UP??"Update job title"}</Label>
            <Link onClick={() => setOpenJobPanel(true)}>{translation.UP??"Update job title"}</Link>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Label>{translation.uplocation??"Update location"}</Label>
            <Link onClick={() => setOpenLocationPanel(true)}>
            {translation.uplocation??"Update location"}
            </Link>
          </div>
        </div>
        {/*------------------------------ Panels for settings---------------------- */}
        <div>
          <Panel
            type={PanelType.custom}
            customWidth="500px"
            headerText={"Add Department"}
            isOpen={openDepPanel}
            onDismiss={() => setOpenDepPanel(false)}
            closeButtonAriaLabel={"Close"}
          >
            <AddDepartment
              allDepartments={allDepartments}
              setAllDepartment={setAllDepartments}
              update={update}
              appSettings={appSettings}
            />
          </Panel>

          <Panel
            type={PanelType.custom}
            customWidth="500px"
            headerText={"Add job title"}
            isOpen={openJobPanel}
            onDismiss={() => setOpenJobPanel(false)}
            closeButtonAriaLabel={"Close"}
          >
            <AddJobTitle
              allJobTitles={allJobTitles}
              setAllJballJobTitles={setAllJballJobTitles}
              update={update}
            />
          </Panel>

          <Panel
            type={PanelType.custom}
            customWidth="500px"
            headerText={"Add Location"}
            isOpen={openLocationPanel}
            onDismiss={() => setOpenLocationPanel(false)}
            closeButtonAriaLabel={"Close"}
          >
            <AddLocation
              allLocations={allLocations}
              setAllLocations={setAllLocations}
              update={update}
            />
          </Panel>
        </div>
      </div>
    </MiniModals>
    }
   </div>
  );
};

const ListStyles = {
  headerWrapper: {
    flex: "0 0 auto",
    selectors: {
      "& [role=presentation]": {
        selectors: {
          "& [role=row]": {
            background: "rgb(0, 120, 212, .4)",
            padding: "4px 0px",
            color: "inherit",

            selectors: {
              ".ms-DetailsHeader-cellTitle": {
                color: "rgb(0, 120, 212)",
              },
              ".ms-DetailsHeader-cell:hover": {
                color: "#fff",
                background: "rgb(0, 120, 212,0)",
              },
            },
          },
        },
      },
    },
  },

  contentWrapper: {
    flex: "1 1 auto",
    ".ms-DetailsRow-fields": {
      alignItems: "center",
    },
  },
};
const AddDepartment = ({
  allDepartments,
  setAllDepartment,
  update,
  appSettings
}) => {
  const dispatch = useDispatch();
  const {translation} = useLanguage();
  const { SweetPrompt: SweetAlertDeleteDept } = SweetAlerts("#addDepartment", true);
  const { SweetAlert: SweetAlertUpdateDep } = SweetAlerts("#addDepartment", true);
  const { SweetAlert: SweetAlertAddedDep } = SweetAlerts("#department", true);

  const [openEditPanel, setOpenEditPanel] = useState(false);

  const [addedDep, setAddedDep] = useState<any>({
    Department: "",
    Active: true,
 
  });

  const [Department, setDepartment] = useState<any>("");
  const [Active, setActive] = useState(false);

  const handleAdd = () => {
    if (addedDep.Department === "") {
      SweetAlertAddedDep("error", "Department name is required");
      return;
    }
    const newDepartments = [...allDepartments, { ...addedDep }]; 
    setAllDepartment(newDepartments); 
    dispatch(setAllDepartments(newDepartments?.map(item=>item.Department)))
    updateSettingJson(SETTING_LIST, { [KEY]: { ...appSettings?.FilterAttribute, departments: newDepartments } }, true);
    dispatch(fetchSettings())
    SweetAlertAddedDep("success", "Department added successfully");
    setAddedDep({
      Department: "",
      Active: true,
   
    });
    // update();
  };

  const handleUpdate = () => {
    const index = allDepartments?.findIndex((dep) => dep.Department === Department);
    if (index !== -1) {
      const newDep = [...allDepartments]; 
      newDep[index] = { Department, Active, };
      setAllDepartment(newDep); 
      updateSettingJson(SETTING_LIST, { [KEY]: { ...appSettings?.FilterAttribute, departments: newDep } }, true);
      SweetAlertUpdateDep("success", "Department updated successfully");
    }
  };

  const handleDelete = () => {
    const newDep = allDepartments.filter((dep) => dep.Department !== Department); 
    setAllDepartment(newDep); 
    dispatch(setAllDepartments(newDep?.map(item=>item.Department)))
    updateSettingJson(SETTING_LIST, { [KEY]: { ...appSettings?.FilterAttribute, departments: newDep } }, true);
    SweetAlertUpdateDep("success", "Department deleted successfully");
    setTimeout(() => {
      setOpenEditPanel(false);
    }, 2000);
    update();
  };

  const columns = [
    {
      key: "column1",
      name: translation.DepartmentName??"Department Name",
      fieldName: "Department",
      minWidth: 180,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isPadded: true,
    },
    {
      key: "column2",
      name: translation.Status??"Status",
      fieldName: "Active",
      minWidth: 30,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isPadded: true,
      onRender: (item) =>
        item.Active ? (
          <Icon iconName="CheckMark" />
        ) : (
          <Icon iconName="Cancel" />
        ),
    },
    {
      key: "column3",
      name: translation.Action??"Action",
      fieldName: "Action",
      minWidth: 30,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isPadded: true,
      onRender: (item) => (
        <IconButton
          iconProps={{ iconName: "Edit" }}
          onClick={() => {
            setOpenEditPanel(true);
            setDepartment(item.Department);
            setActive(item.Active);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        {/* <div>
          <PrimaryButton
            styles={{
              root: {
                backgroundColor: "rgb(0, 112, 220, .4)",
                border: "none",
                color: "rgb(0, 112, 220)",
              },
            }}
          >
            Import csv
          </PrimaryButton>
        </div> */}
        <div id="department">
          <Label>{translation.AddDepartment??"Add Department"}</Label>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <TextField
              styles={{ root: { minWidth: "365px" } }}
              placeholder={translation.EnterDepartment??"Enter Department"}
              value={addedDep.Department}
              onChange={(e:any) => setAddedDep({ ...addedDep, Department: e.target.value })}
            />
            <PrimaryButton onClick={handleAdd}>{translation.Add??"Add"}</PrimaryButton>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <DetailsList
            columns={columns}
            selectionMode={SelectionMode.none}
            styles={ListStyles}
            items={allDepartments || []}
          />
        </div>
      </div>
      <div>
        <Panel
          type={PanelType.custom}
          customWidth="450px"
          isOpen={openEditPanel}
          onDismiss={() => setOpenEditPanel(false)}
          headerText="Edit Department"
        >
          <div id="addDepartment" style={{ marginTop: "10px" }}>
            <TextField
              label="Department"
              name="Department"
              value={Department}
              onChange={(e:any) => setDepartment(e.target.value)}
              required
            />
            <Toggle
              onChange={(_, checked) => setActive(checked)}
              checked={Active}
              label="Active"
            />
            <div style={{ marginTop: "10px", display: "flex", gap: "5px" }}>
              <PrimaryButton onClick={handleUpdate}>{translation.Update??"Update"}</PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  SweetAlertDeleteDept("warning", "Do you want to delete it ?").then((res) => {
                    if (res.isConfirmed) {
                      handleDelete();
                    }
                  });
                }}
              >
                {translation.Delete??"Delete"}
              </PrimaryButton>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};


const AddJobTitle = ({ allJobTitles, setAllJballJobTitles, update }) => {
  const dispatch=useDispatch();
  const {translation} = useLanguage();
  const { SweetPrompt: SweetAlertAddJobTitle } = SweetAlerts("#addJobTitle",true);
  const { SweetAlert: SweetAlertUpdateJobTitle } = SweetAlerts("#addJobTitle",true);
  const { SweetAlert: SweetAlertAddedJobTitle } = SweetAlerts("#addedJobTitle",true);
   const appSettings=useSelector((state:any)=>state.storeSettings.appSettings)
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const [addedJobTitle, setAddedJobTitle] = useState({

    JobTitle: "",
    Active: true,
  
  });

  const [JobTitle, setJobTitles] = useState("");
  const [Active, setActive] = useState(false);
  const [Key, setKey] = useState(0);

  const handleAdd = () => {
    if (addedJobTitle.JobTitle === "") {
      SweetAlertAddedJobTitle("error", "Job title is required");
      return;
    }
    // allJobTitles.push(addedJobTitle);
    // setAllJballJobTitles(allJobTitles);

 
    const newJobTitles = [...allJobTitles, { ...addedJobTitle }]; 
    setAllJballJobTitles(newJobTitles); 
    dispatch(setAllJobTitle(newJobTitles?.map(item=>item.JobTitle)))
    updateSettingJson(SETTING_LIST, { [KEY]: { ...appSettings?.FilterAttribute, jobTitles: newJobTitles } }, true);
    dispatch(fetchSettings())

    SweetAlertAddedJobTitle("success", "Job title added successfully");
    setAddedJobTitle({
      JobTitle: "",
      Active: true,
    
    });
    // update();
  };

  const handleUpdate = () => {
    const index = allJobTitles.findIndex((dep: any) => dep.JobTitle === JobTitle);
    const data = {  JobTitle, Active };
    const newJob = allJobTitles;
    newJob[index] = data;
    setAllJballJobTitles(newJob);
    updateSettingJson(SETTING_LIST, { [KEY]: { ...appSettings?.FilterAttribute, jobTitles: newJob } }, true);
    SweetAlertUpdateJobTitle("success", "Job title updated successfully");
    // update();
  };

  const handleDelete = () => {
    const index = allJobTitles.findIndex((dep: any) => dep.JobTitle === JobTitle);
    const newDep = allJobTitles;
    if (index !== -1) {
      newDep.splice(index, 1);
      setAllJballJobTitles(newDep);
    }
    dispatch(setAllJobTitle(newDep?.map(item=>item.JobTitle)))
    updateSettingJson(SETTING_LIST, { [KEY]: { ...appSettings?.FilterAttribute, jobTitles: newDep } }, true);
    SweetAlertUpdateJobTitle("success", "Job title deleted successfully");
    setTimeout(() => {
      setOpenEditPanel(false);
    }, 2000);
    // update();
  };

  const columns: IColumn[] = [
    {
      key: "column1",
      name: translation.JobTitle??"Job title",
      fieldName: "JobTitle",
      minWidth: 180,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isPadded: true,
    },
    {
      key: "column2",
      name: translation.Status??"Status",
      fieldName: "Active",
      minWidth: 30,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isPadded: true,
      onRender: (item) =>
        item.Active ? (
          <Icon iconName="CheckMark"></Icon>
        ) : (
          <Icon iconName="Cancel"></Icon>
        ),
    },
    {
      key: "column3",
      name: translation.Action??"Action",
      fieldName: "Action",
      minWidth: 30,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isPadded: true,
      onRender: (item) => (
        <IconButton
          iconProps={{ iconName: "Edit" }}
          onClick={() => {
            setOpenEditPanel(true);
            setJobTitles(item.JobTitle);
            setActive(item.Active);
            setKey(item.Key);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        {/* <div>
          <PrimaryButton
            styles={{
              root: {
                backgroundColor: "rgb(0, 112, 220, .4)",
                border: "none",
                color: "rgb(0, 112, 220)",
              },
            }}
          >
            Import csv
          </PrimaryButton>
        </div> */}
        <div id="addedJobTitle">
          <Label>{translation.Addjob??"Add Job title"}</Label>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <TextField
              styles={{ root: { minWidth: "365px" } }}
              placeholder="Enter job title"
              value={addedJobTitle.JobTitle}
              onChange={(e: any) =>
                setAddedJobTitle((prev) => ({
                  ...prev,
                  JobTitle: e.target.value,
                }))
              }
            />
            <PrimaryButton onClick={handleAdd}>{translation.Add??"Add"}</PrimaryButton>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <DetailsList
            columns={columns}
            selectionMode={SelectionMode.none}
            styles={ListStyles}
            items={allJobTitles || []}
          />
        </div>
      </div>
      <div>
        <Panel
          type={PanelType.custom}
          customWidth="450px"
          isOpen={openEditPanel}
          onDismiss={() => setOpenEditPanel(false)}
          headerText={translation.Enterjob??"Edit job title"}
        >
          <div id="addJobTitle" style={{ marginTop: "10px" }}>
            <TextField
              label={translation.JobTitle??"Job title"}
              onChange={(e: any) => setJobTitles(e.target.value)}
              value={JobTitle}
              required
            />
            <Toggle
              onChange={(_, checked: boolean) => setActive(checked)}
              checked={Active}
              label={translation.Active??"Active"}
            />
            <div style={{ marginTop: "10px", display: "flex", gap: "5px" }}>
              <PrimaryButton onClick={handleUpdate}>{translation.Update??"Update"}</PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  SweetAlertAddJobTitle(
                    "warning",
                    "Do you want to delete it ? "
                  ).then((res) => {
                    if (res.isConfirmed) {
                      handleDelete();
                    }
                  });
                }}
              >
                {translation.Delete??"Delete"}
              </PrimaryButton>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

const AddLocation = ({ allLocations, setAllLocations, update }) => {
 const  dispatch=useDispatch();
 const {translation} = useLanguage();
  const { SweetPrompt: SweetAlertDeleteLocation } = SweetAlerts("#addLocation",true);
  const { SweetAlert: SweetAlertUpdateLocation } = SweetAlerts("#addLocation",true);
  const { SweetAlert: SweetAlertAddedLocation } = SweetAlerts("#addedLocation",true);
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const appSettings=useSelector((state:any)=>state.storeSettings.appSettings)
  const [Location, setLocation] = useState("");
  const [Active, setActive] = useState(false);
  const [Key, setKey] = useState(0);

  const [addedLocation, setAddedLocation] = useState({
  
    Location: "",
    Active: true,
  });

  const handleAdd = () => {
    
    if (addedLocation.Location === "") {
      SweetAlertAddedLocation("error", "Location name is required");
      return;
    }
  
 




    const newLocations = [...allLocations, { ...addedLocation }]; 
    setAllLocations(newLocations);
    // dispatch(setAllDepartments(newDepartments?.map(item=>item.Department)))
    updateSettingJson(SETTING_LIST, { [KEY]: { ...appSettings?.FilterAttribute, locations: newLocations } }, true);
    dispatch(fetchSettings())
    SweetAlertAddedLocation("success", "Job title added successfully");
    setAddedLocation({

      Location: "",
      Active: true,
    });
    // update();
  };

  const handleUpdate = () => {
    const index = allLocations.findIndex((dep: any) => dep.Location === Location);
    const data = { Location, Active };
    const newJob = allLocations;
    newJob[index] = data;
    setAllLocations(newJob);
    updateSettingJson(SETTING_LIST, { [KEY]: { ...appSettings?.FilterAttribute, locations: newJob } }, true);
    SweetAlertUpdateLocation("success", "Job title updated successfully");
    // update();
  };

  const handleDelete = () => {
    const index = allLocations.findIndex((dep: any) => dep.Key === Key);
    const newDep = allLocations;
    if (index !== -1) {
      newDep.splice(index, 1);
      setAllLocations(newDep);
    }
    updateSettingJson(SETTING_LIST, { [KEY]: { ...appSettings?.FilterAttribute, locations: newDep } }, true);
    SweetAlertUpdateLocation("success", "Location deleted successfully");
    setTimeout(() => {
      setOpenEditPanel(false);
    }, 2000);
    // update();
  };

  const columns: IColumn[] = [
    {
      key: "column1",
      name: translation.Location??"Location",
      fieldName: "Location",
      minWidth: 180,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isPadded: true,
    },
    {
      key: "column2",
      name: translation.Status??"Status",
      fieldName: "Active",
      minWidth: 30,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isPadded: true,
      onRender: (item) =>
        item.Active ? (
          <Icon iconName="CheckMark"></Icon>
        ) : (
          <Icon iconName="Cancel"></Icon>
        ),
    },
    {
      key: "column3",
      name: translation.Action??"Action",
      fieldName: "Action",
      minWidth: 30,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isPadded: true,
      onRender: (item) => (
        <IconButton
          iconProps={{ iconName: "Edit" }}
          onClick={() => {
            setOpenEditPanel(true);
            setLocation(item.Location);
            setActive(item.Active);
            setKey(item.Key);
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        {/* <div>
          <PrimaryButton
            styles={{
              root: {
                backgroundColor: "rgb(0, 112, 220, .4)",
                border: "none",
                color: "rgb(0, 112, 220)",
              },
            }}
          >
            Import csv
          </PrimaryButton>
        </div> */}
        <div id="addedLocation">
          <Label>Add Location</Label>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <TextField
              styles={{ root: { minWidth: "365px" } }}
              placeholder="Enter Location"
              value={addedLocation.Location}
              onChange={(e: any) =>
                setAddedLocation((prev) => ({
                  ...prev,
                  Location: e.target.value,
                }))
              }
            />
            <PrimaryButton onClick={handleAdd}>{translation.Location??"Add"}</PrimaryButton>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <DetailsList
            columns={columns}
            selectionMode={SelectionMode.none}
            styles={ListStyles}
            items={allLocations || []}
          />
        </div>
      </div>
      <div>
        <Panel
          type={PanelType.custom}
          customWidth="450px"
          isOpen={openEditPanel}
          onDismiss={() => setOpenEditPanel(false)}
          headerText="Edit Location"
        >
          <div id="addLocation" style={{ marginTop: "10px" }}>
            <TextField
              onChange={(e: any) => setLocation(e.target.value)}
              label={translation.Location??"Location"}
              value={Location}
              required
            />
            <Toggle
              onChange={(_, checked: boolean) => setActive(checked)}
              checked={Active}
              label={translation.Active??"Active"}
            />
            <div style={{ marginTop: "10px", display: "flex", gap: "5px" }}>
              <PrimaryButton onClick={handleUpdate}>Update</PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  SweetAlertDeleteLocation(
                    "warning",
                    "Do you want to delete it ?"
                  ).then((res) => {
                    if (res.isConfirmed) {
                      handleDelete();
                    }
                  });
                }}
              >
                {translation.Active??"Delete"}
              </PrimaryButton>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default FilterAttributes;
