import React, { useEffect, useRef, useState } from "react";
import {
  CommandBarButton,
  DatePicker,
  Dropdown,
  Icon,
  TextField,
} from "@fluentui/react";
import "sweetalert2/dist/sweetalert2.css";
import { SweetAlerts } from "./SelectSource/Utils/SweetAlert";
import { Buffer } from "buffer";
import LineChart, { ColorArray } from "./Charts/LineChart";
import * as XLSX from "xlsx";
import Excel from "exceljs";
import {
  averageAgeByDepartmentDataSample,
  averageAgeByLocationDataSample,
  averageTenureByDepartmentDataSample,
  averageTenureByLocationDataSample,
  employmentTypeDataSample,
  emptyBarData,
  emptyDoughnutData,
  emptyLineData,
  genderDiversityByDepartmentDataSample,
  genderDiversityByLocationDataSample,
  newEmployeesDataSample,
  totalEmployeesByDepartmentDataSample,
} from "./Charts/SampleData";

import BarCharts from "./Charts/BarChart";
import DonutChart from "./Charts/DonutCharts";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import styles from "./SCSS/Ed.module.scss";
import {
  Label,
  Checkbox,
  PrimaryButton,
  IconButton,
  Stack,
  TeachingBubble,
  DirectionalHint,
} from "@fluentui/react";
import "./SelectSource/Edp.scss";
import { useSttings } from "./SelectSource/store";
import { useCustomSwalContainerStyle } from "./SelectSource/Utils/useCustomSwalContainerStyle";
import { useLanguage } from "../Language/LanguageContext";
import UserSearchBox from "./SelectSource/UserSearchBox";
import { useFields, useLists } from "../context/store";
import { DefaultButton } from "office-ui-fabric-react";
import { SETTING_LIST, updateSettingJson, USER_LIST } from "../api/storage";
import { encryptData, getCurrentUser, Home_KEY } from "./Helpers/HelperFunctions";
import { fetchSettings, setAllSettings, setSelectedLetter, setShowHidePages } from "../Store/appSettingsSlice";
import { useDispatch, useSelector } from "react-redux";
// import "./SelectSource/Edp.scss"
// Define the container style
const containerStyle: React.CSSProperties = {
  display: "grid",
  gap: "4px",
  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  gridAutoRows: "70px",
  position: "relative",
  width: "calc(100% - 30px)",
  padding: "15px",
  boxSizing: "border-box",
};

// Define the style for individual items
const itemStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid #ddd",
  backgroundColor: "#0078d4",
  padding: "10px",
  color: "white",

  textAlign: "center",
};

const iconContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  color: "rgb(0, 108, 190)",
  cursor: "pointer",
  marginLeft: "auto",
  marginRight: "15px",
  marginTop: "15px",
};
const gridStyle: React.CSSProperties = {
  display: "grid",
  gridTemplateColumns: "30% 30% 30%",
  backgroundColor: "#f5f5f5",
  padding: "20px 0px",
  gap: "5px 20px",
  placeContent: "space-evenly",
};
let employees = [
  {
    DOB: "1979-02-24",
    DOJ: "2018-03-21",
    CF1: "Customer Support",
    firstName: "Charlotte",
    lastName: "Johnson",
    name: "Charlotte Johnson",
    email: "charlotte.johnson@example.com",
    id: "krbstjo05i7wyn76mahqrq",
    job: "Operations Manager",
    initials: "CJ",
    department: "Product",
    workphone: "01724459861",
    mobile: "01724251063",
    manager: "ethan.d@megazap.us",
    employeeid: "54",
    costcenter: "93",
    buildingid: "128",
    floorname: "JKL",
    floorsection: "central area",
    location: "115 JKL",
    address: "789 Pine Road",
    image: "https://example.com/image6.jpg",
    gender: "Male",
    employmentType: "Temporary",
  },
  {
    DOB: "1979-01-24",
    DOJ: "2021-01-06",
    CF1: "Legal",
    firstName: "Sophia",
    lastName: "Johnson",
    name: "Sophia Johnson",
    email: "sophia.johnson@example.com",
    id: "59oz9x71c0mjdbioiz2edn",
    job: "HR Manager",
    initials: "SJ",
    department: "Sales",
    workphone: "01724603127",
    mobile: "01724982489",
    manager: "michael.s@megazap.us",
    employeeid: "14",
    costcenter: "38",
    buildingid: "111",
    floorname: "STU",
    floorsection: "north wing",
    location: "115 JKL",
    address: "123 Elm Street",
    image: "https://example.com/image6.jpg",
    gender: "Male",
    employmentType: "Part-Time",
  },
  {
    DOB: "1979-06-19",
    DOJ: "2015-02-10",
    CF1: "Operations",
    firstName: "John",
    lastName: "Moore",
    name: "John Moore",
    email: "john.moore@example.com",
    id: "kslicbstaurlxf0w3unobg",
    job: "Product Manager",
    initials: "JM",
    department: "Operations",
    workphone: "01724943674",
    mobile: "01724447527",
    manager: "ethan.d@megazap.us",
    employeeid: "62",
    costcenter: "50",
    buildingid: "114",
    floorname: "JKL",
    floorsection: "north wing",
    location: "122 DEF",
    address: "123 Elm Street",
    image: "https://example.com/image1.jpg",
    gender: "Male",
    employmentType: "Temporary",
  },
  {
    DOB: "1977-07-28",
    DOJ: "2023-06-06",
    CF1: "Product",
    firstName: "Liam",
    lastName: "Smith",
    name: "Liam Smith",
    email: "liam.smith@example.com",
    id: "brgxbnqad3f2mka2x2o63y",
    job: "Product Manager",
    initials: "LS",
    department: "Product",
    workphone: "01724213509",
    mobile: "01724701542",
    manager: "ethan.d@megazap.us",
    employeeid: "97",
    costcenter: "92",
    buildingid: "123",
    floorname: "DEF",
    floorsection: "south area",
    location: "112 ABC",
    address: "123 Elm Street",
    image: "https://example.com/image8.jpg",
    gender: "Male",
    employmentType: "Part-Time",
  },
  {
    DOB: "1980-09-14",
    DOJ: "2019-04-23",
    CF1: "Sales",
    firstName: "James",
    lastName: "Johnson",
    name: "James Johnson",
    email: "james.johnson@example.com",
    id: "qdc1rteakc9hisjo8lpzm",
    job: "Operations Manager",
    initials: "JJ",
    department: "HR",
    workphone: "01724294501",
    mobile: "01724638156",
    manager: "john.d@megazap.us",
    employeeid: "77",
    costcenter: "96",
    buildingid: "113",
    floorname: "JKL",
    floorsection: "north wing",
    location: "122 DEF",
    address: "456 Oak Avenue",
    image: "https://example.com/image4.jpg",
    gender: "Female",
    employmentType: "Full-Time",
  },
  {
    DOB: "1980-06-05",
    DOJ: "2023-03-22",
    CF1: "Sales",
    firstName: "Ethan",
    lastName: "Lee",
    name: "Ethan Lee",
    email: "ethan.lee@example.com",
    id: "uhjyfmzbyvlgor66uk9jk",
    job: "Accountant",
    initials: "EL",
    department: "Sales",
    workphone: "01724822439",
    mobile: "01724168186",
    manager: "emma.w@megazap.us",
    employeeid: "90",
    costcenter: "64",
    buildingid: "110",
    floorname: "DEF",
    floorsection: "north wing",
    location: "122 DEF",
    address: "202 Birch Lane",
    image: "https://example.com/image9.jpg",
    gender: "Male",
    employmentType: "Part-Time",
  },
  {
    DOB: "1992-02-28",
    DOJ: "2014-04-01",
    CF1: "R&D",
    firstName: "Charlotte",
    lastName: "Lee",
    name: "Charlotte Lee",
    email: "charlotte.lee@example.com",
    id: "f8wolglptxtb6fzh2borq",
    job: "Marketing Specialist",
    initials: "CL",
    department: "Operations",
    workphone: "01724352971",
    mobile: "01724190791",
    manager: "emma.w@megazap.us",
    employeeid: "57",
    costcenter: "87",
    buildingid: "125",
    floorname: "VWX",
    floorsection: "central area",
    location: "117 PQR",
    address: "789 Pine Road",
    image: "https://example.com/image1.jpg",
    gender: "Female",
    employmentType: "Temporary",
  },
];

const Dashboard = ({
  users,
  appSettings,
  setSettings,
  setShowHomePage,
  setShowOrgChart,
  setshowDashboard,
}) => {
  const isUserAdmin=useSelector((state:any)=>state.storeSettings.isCurrntUserAdmin);
  const CurrentuserData=useSelector((state:any)=>state.storeSettings.CurrentUserData)
  const dispatch = useDispatch();
  const M365Users = useSelector((state: any) => state.storeSettings.M365Users);
  const NonM365Users = useSelector(
    (state: any) => state.storeSettings.NonM365Users
  );
  const [OpenPanel, setOpenPanel] = useState(false);
  const [addmsg, setAddmsg] = useState(false);
  const [selectedUserPeoplePicker, setSelectedUserPeoplePicker] =
    useState(null);
  const { allUsers } = useFields();
  const [teachingBubbleAction, setTeachingBubbleAction] = useState(false);
  const [teachingBubbleUUD, setTeachingBubbleUUD] = useState(false);
  const [isOpenCSV, setIsOpenCSV] = useState(false);
  const [databyMonth, setDatabyMonth] = useState(true);
  const [isClearUserSearch, setClearUserSearch] = useState(false);
  const [dept, setDept] = useState(true);
  const [updateDataArray, setupdateDataArray] = useState<any>([]);
  const [fileNameUser, setFileNameUser] = useState<any>([]);
  const [fullTimeCount, setFullTimeCount] = useState(0);
  const [avgTenureHeader, setAvgTenureHeader] = useState(0);
  const [genderDiversityRatioForHeader, setGenderDiversityRatioForHeader] =
    useState<any>(0);
  const [avgAgeHeader, setAvgAgeHeader] = useState(0);
  const [empType, setEmpType] = useState(true);
  const [avg, setAvg] = useState(true);
  const [agebylocation, setAgebylocation] = useState(true);
  const [avgTenByDept, setAvgTenByDept] = useState(true);
  const [den, setDen] = useState(true);
  const [deptbylocation, setDeptbylocation] = useState(true);
  const [AvgTenByLoc, setAvgTenByLoc] = useState(true);
  const [showSampleData, setShowSampleData] = useState(false);
  const [sampleData, setSampleData] = useState(false);
  const [genDivByDept, setGenDivByDept] = useState(true);
  const [genDivByLoc, setGenDivByLoc] = useState(true);
  const [selectedKeysUserlist, setSelectedKeysUserlist] = useState([]);
  const [loadingAL, setLoadingAL] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [isOpenUpdatePanel, setOpenUpdatePanel] = useState(false);
  let searchUserRef = useRef(null);
  const customSwalProps = {
    addmsg,
    setAddmsg,
  };
  useCustomSwalContainerStyle(customSwalProps);
  const {
    languagePartUpdate,
    setLanguagePartUpdate,
    translation,
    setTranslation,
    languages,
    setLanguage,
    getTranslation,
  } = useLanguage();
  const { setAppSettings } = useSttings();
  // const appSettings=useSelector((state:any)=>state.storeSettings.appSettings);
  const toggleTeachingBubbleAction = () =>
    setTeachingBubbleAction(!teachingBubbleAction);
  const toggleTeachingBubbleUUD = () =>
    setTeachingBubbleUUD(!teachingBubbleUUD);
  const openPanelCustomPanel = () => setIsOpenCSV(true);
  const dismissPanelCustomPanel = () => setIsOpenCSV(false);
  const openPanelCSV = () => setIsOpenCSV(true);
  const dismissPanelCSV = () => setIsOpenCSV(false);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedDept, setSelectedDept] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedLoc, setSelectedLoc] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateOfJoin, setDateOfJoin] = useState(null);
  const [employmentType, setEmploymentType] = useState("");
  const [genderType, setGenderType] = useState("");
  const { SweetAlert: SweetAlertDashboard } = SweetAlerts("#dashboard", true);

  const onTextChangedesign = (e) => setSelectedTitle(e.target.value);
  const onTextChangedept = (e) => setSelectedDept(e.target.value);
  const onTextChangeemail = (e) => setSelectedEmail(e.target.value);
  const onTextChangeofc = (e) => setSelectedLoc(e.target.value);
  const ondobChange = (date) => setDateOfBirth(date);
  const ondojChange = (date) => setDateOfJoin(date);
  const onTextChangeetype = (e, option) => setEmploymentType(option.key);
  const onTextChangegender = (e, option) => setGenderType(option.key);
  const { unFormatedUserData, setUnFormatedUserData } = useFields();
  const [searchBoxData, setSearchBoxData] = useState([]);
  console.log("unformated", unFormatedUserData);

  const { setUsersList } = useLists();

  // let employees=[];
  let employees = [
    {
      DOB: "1979-02-24",
      DOJ: "2018-03-21",
      CF1: "Customer Support",
      firstName: "Charlotte",
      lastName: "Johnson",
      name: "Charlotte Johnson",
      email: "charlotte.johnson@example.com",
      id: "krbstjo05i7wyn76mahqrq",
      job: "Operations Manager",
      initials: "CJ",
      department: "Product",
      workphone: "01724459861",
      mobile: "01724251063",
      manager: "ethan.d@megazap.us",
      employeeid: "54",
      costcenter: "93",
      buildingid: "128",
      floorname: "JKL",
      floorsection: "central area",
      location: "115 JKL",
      address: "789 Pine Road",
      image: "https://example.com/image6.jpg",
      gender: "Male",
      employmentType: "Temporary",
    },
    {
      DOB: "1979-01-24",
      DOJ: "2021-01-06",
      CF1: "Legal",
      firstName: "Sophia",
      lastName: "Johnson",
      name: "Sophia Johnson",
      email: "sophia.johnson@example.com",
      id: "59oz9x71c0mjdbioiz2edn",
      job: "HR Manager",
      initials: "SJ",
      department: "Sales",
      workphone: "01724603127",
      mobile: "01724982489",
      manager: "michael.s@megazap.us",
      employeeid: "14",
      costcenter: "38",
      buildingid: "111",
      floorname: "STU",
      floorsection: "north wing",
      location: "115 JKL",
      address: "123 Elm Street",
      image: "https://example.com/image6.jpg",
      gender: "Male",
      employmentType: "Part-Time",
    },
    {
      DOB: "1979-06-19",
      DOJ: "2015-02-10",
      CF1: "Operations",
      firstName: "John",
      lastName: "Moore",
      name: "John Moore",
      email: "john.moore@example.com",
      id: "kslicbstaurlxf0w3unobg",
      job: "Product Manager",
      initials: "JM",
      department: "Operations",
      workphone: "01724943674",
      mobile: "01724447527",
      manager: "ethan.d@megazap.us",
      employeeid: "62",
      costcenter: "50",
      buildingid: "114",
      floorname: "JKL",
      floorsection: "north wing",
      location: "122 DEF",
      address: "123 Elm Street",
      image: "https://example.com/image1.jpg",
      gender: "Male",
      employmentType: "Temporary",
    },
    {
      DOB: "1977-07-28",
      DOJ: "2023-06-06",
      CF1: "Product",
      firstName: "Liam",
      lastName: "Smith",
      name: "Liam Smith",
      email: "liam.smith@example.com",
      id: "brgxbnqad3f2mka2x2o63y",
      job: "Product Manager",
      initials: "LS",
      department: "Product",
      workphone: "01724213509",
      mobile: "01724701542",
      manager: "ethan.d@megazap.us",
      employeeid: "97",
      costcenter: "92",
      buildingid: "123",
      floorname: "DEF",
      floorsection: "south area",
      location: "112 ABC",
      address: "123 Elm Street",
      image: "https://example.com/image8.jpg",
      gender: "Male",
      employmentType: "Part-Time",
    },
    {
      DOB: "1980-09-14",
      DOJ: "2019-04-23",
      CF1: "Sales",
      firstName: "James",
      lastName: "Johnson",
      name: "James Johnson",
      email: "james.johnson@example.com",
      id: "qdc1rteakc9hisjo8lpzm",
      job: "Operations Manager",
      initials: "JJ",
      department: "HR",
      workphone: "01724294501",
      mobile: "01724638156",
      manager: "john.d@megazap.us",
      employeeid: "77",
      costcenter: "96",
      buildingid: "113",
      floorname: "JKL",
      floorsection: "north wing",
      location: "122 DEF",
      address: "456 Oak Avenue",
      image: "https://example.com/image4.jpg",
      gender: "Female",
      employmentType: "Full-Time",
    },
    {
      DOB: "1980-06-05",
      DOJ: "2023-03-22",
      CF1: "Sales",
      firstName: "Ethan",
      lastName: "Lee",
      name: "Ethan Lee",
      email: "ethan.lee@example.com",
      id: "uhjyfmzbyvlgor66uk9jk",
      job: "Accountant",
      initials: "EL",
      department: "Sales",
      workphone: "01724822439",
      mobile: "01724168186",
      manager: "emma.w@megazap.us",
      employeeid: "90",
      costcenter: "64",
      buildingid: "110",
      floorname: "DEF",
      floorsection: "north wing",
      location: "122 DEF",
      address: "202 Birch Lane",
      image: "https://example.com/image9.jpg",
      gender: "Male",
      employmentType: "Part-Time",
    },
    {
      DOB: "1992-02-28",
      DOJ: "2014-04-01",
      CF1: "R&D",
      firstName: "Charlotte",
      lastName: "Lee",
      name: "Charlotte Lee",
      email: "charlotte.lee@example.com",
      id: "f8wolglptxtb6fzh2borq",
      job: "Marketing Specialist",
      initials: "CL",
      department: "Operations",
      workphone: "01724352971",
      mobile: "01724190791",
      manager: "emma.w@megazap.us",
      employeeid: "57",
      costcenter: "87",
      buildingid: "125",
      floorname: "VWX",
      floorsection: "central area",
      location: "117 PQR",
      address: "789 Pine Road",
      image: "https://example.com/image1.jpg",
      gender: "Female",
      employmentType: "Temporary",
    },
    {
      DOB: "1999-07-22",
      DOJ: "2011-01-11",
      CF1: "Customer Support",
      firstName: "James",
      lastName: "Moore",
      name: "James Moore",
      email: "james.moore@example.com",
      id: "38mfs880494pctjyikanv",
      job: "Legal Advisor",
      initials: "JM",
      department: "IT",
      workphone: "01724491108",
      mobile: "01724790952",
      manager: "ethan.d@megazap.us",
      employeeid: "30",
      costcenter: "12",
      buildingid: "120",
      floorname: "VWX",
      floorsection: "east wing",
      location: "115 JKL",
      address: "789 Pine Road",
      image: "https://example.com/image8.jpg",
      gender: "Female",
      employmentType: "Temporary",
    },
    {
      DOB: "1977-10-25",
      DOJ: "2024-04-26",
      CF1: "Legal",
      firstName: "Jane",
      lastName: "Brown",
      name: "Jane Brown",
      email: "jane.brown@example.com",
      id: "hn3htcarz047mnn60im4md",
      job: "Marketing Specialist",
      initials: "JB",
      department: "Legal",
      workphone: "01724968442",
      mobile: "01724983534",
      manager: "john.d@megazap.us",
      employeeid: "36",
      costcenter: "40",
      buildingid: "116",
      floorname: "ABC",
      floorsection: "east wing",
      location: "122 DEF",
      address: "123 Elm Street",
      image: "https://example.com/image7.jpg",
      gender: "Male",
      employmentType: "Temporary",
    },
    {
      DOB: "1974-04-12",
      DOJ: "2013-03-03",
      CF1: "Legal",
      firstName: "Jane",
      lastName: "Moore",
      name: "Jane Moore",
      email: "jane.moore@example.com",
      id: "bkcl3i631ebslh9aq75t0s",
      job: "Sales Executive",
      initials: "JM",
      department: "Marketing",
      workphone: "01724279304",
      mobile: "01724544581",
      manager: "susan.t@megazap.us",
      employeeid: "31",
      costcenter: "49",
      buildingid: "126",
      floorname: "MNO",
      floorsection: "west area",
      location: "122 DEF",
      address: "404 Willow Street",
      image: "https://example.com/image3.jpg",
      gender: "Female",
      employmentType: "Part-Time",
    },
    {
      DOB: "1982-07-31",
      DOJ: "2012-02-10",
      CF1: "Customer Support",
      firstName: "Lucas",
      lastName: "Martinez",
      name: "Lucas Martinez",
      email: "lucas.martinez@example.com",
      id: "2s79oe0l8mfu201a0gtq9d",
      job: "Legal Advisor",
      initials: "LM",
      department: "Engineering",
      workphone: "01724445916",
      mobile: "01724297012",
      manager: "ethan.d@megazap.us",
      employeeid: "34",
      costcenter: "20",
      buildingid: "112",
      floorname: "MNO",
      floorsection: "south area",
      location: "112 ABC",
      address: "123 Elm Street",
      image: "https://example.com/image1.jpg",
      gender: "Male",
      employmentType: "Temporary",
    },
    {
      DOB: "1972-09-07",
      DOJ: "2019-12-09",
      CF1: "Product",
      firstName: "Jane",
      lastName: "Brown",
      name: "Jane Brown",
      email: "jane.brown@example.com",
      id: "r9kps80g7ygcuw210dbjh",
      job: "Customer Support Specialist",
      initials: "JB",
      department: "IT",
      workphone: "01724574400",
      mobile: "01724379995",
      manager: "susan.t@megazap.us",
      employeeid: "41",
      costcenter: "68",
      buildingid: "113",
      floorname: "VWX",
      floorsection: "south area",
      location: "112 ABC",
      address: "101 Maple Street",
      image: "https://example.com/image2.jpg",
      gender: "Male",
      employmentType: "Full-Time",
    },
    {
      DOB: "1976-12-15",
      DOJ: "2024-04-22",
      CF1: "HR",
      firstName: "Sophia",
      lastName: "Lee",
      name: "Sophia Lee",
      email: "sophia.lee@example.com",
      id: "2lc6b1zty7n4gd35zikx7l",
      job: "Accountant",
      initials: "SL",
      department: "Marketing",
      workphone: "01724267973",
      mobile: "01724332499",
      manager: "olivia.b@megazap.us",
      employeeid: "32",
      costcenter: "17",
      buildingid: "111",
      floorname: "DEF",
      floorsection: "west area",
      location: "115 JKL",
      address: "123 Elm Street",
      image: "https://example.com/image2.jpg",
      gender: "Female",
      employmentType: "Full-Time",
    },
    {
      DOB: "1988-03-09",
      DOJ: "2014-04-24",
      CF1: "Marketing",
      firstName: "Olivia",
      lastName: "Williams",
      name: "Olivia Williams",
      email: "olivia.williams@example.com",
      id: "8dw6jlm7iuxq0rk5t5xg",
      job: "Marketing Specialist",
      initials: "OW",
      department: "Legal",
      workphone: "01724276346",
      mobile: "01724928852",
      manager: "olivia.b@megazap.us",
      employeeid: "29",
      costcenter: "93",
      buildingid: "117",
      floorname: "ABC",
      floorsection: "central area",
      location: "112 ABC",
      address: "456 Oak Avenue",
      image: "https://example.com/image7.jpg",
      gender: "Male",
      employmentType: "Part-Time",
    },
    {
      DOB: "1996-01-25",
      DOJ: "2022-01-07",
      CF1: "HR",
      firstName: "Charlotte",
      lastName: "Moore",
      name: "Charlotte Moore",
      email: "charlotte.moore@example.com",
      id: "vutxilgujqjvsc0bqsea",
      job: "Operations Manager",
      initials: "CM",
      department: "HR",
      workphone: "01724524251",
      mobile: "01724321254",
      manager: "emma.w@megazap.us",
      employeeid: "22",
      costcenter: "14",
      buildingid: "119",
      floorname: "ABC",
      floorsection: "north wing",
      location: "117 PQR",
      address: "101 Maple Street",
      image: "https://example.com/image6.jpg",
      gender: "Female",
      employmentType: "Full-Time",
    },
    {
      DOB: "1971-08-07",
      DOJ: "2011-08-05",
      CF1: "Sales",
      firstName: "Olivia",
      lastName: "Brown",
      name: "Olivia Brown",
      email: "olivia.brown@example.com",
      id: "fpjfyoj6ui5c2xnup46h14",
      job: "Marketing Specialist",
      initials: "OB",
      department: "Marketing",
      workphone: "01724140329",
      mobile: "01724714983",
      manager: "susan.t@megazap.us",
      employeeid: "64",
      costcenter: "88",
      buildingid: "111",
      floorname: "GHI",
      floorsection: "east wing",
      location: "112 ABC",
      address: "404 Willow Street",
      image: "https://example.com/image10.jpg",
      gender: "Male",
      employmentType: "Part-Time",
    },
    {
      DOB: "1980-09-10",
      DOJ: "2022-07-16",
      CF1: "Engineering",
      firstName: "Michael",
      lastName: "Smith",
      name: "Michael Smith",
      email: "michael.smith@example.com",
      id: "914s5grboyae4x7c5ycgfs",
      job: "Accountant",
      initials: "MS",
      department: "R&D",
      workphone: "01724203662",
      mobile: "01724540738",
      manager: "james.j@megazap.us",
      employeeid: "32",
      costcenter: "83",
      buildingid: "113",
      floorname: "GHI",
      floorsection: "west area",
      location: "121 ABC",
      address: "123 Elm Street",
      image: "https://example.com/image4.jpg",
      gender: "Male",
      employmentType: "Part-Time",
    },
    {
      DOB: "1991-10-05",
      DOJ: "2015-02-04",
      CF1: "Operations",
      firstName: "Emma",
      lastName: "Garcia",
      name: "Emma Garcia",
      email: "emma.garcia@example.com",
      id: "qffwmnx7gobdgoprp75nu5",
      job: "HR Manager",
      initials: "EG",
      department: "Engineering",
      workphone: "01724197171",
      mobile: "01724166639",
      manager: "susan.t@megazap.us",
      employeeid: "18",
      costcenter: "86",
      buildingid: "126",
      floorname: "JKL",
      floorsection: "south area",
      location: "114 GHI",
      address: "101 Maple Street",
      image: "https://example.com/image7.jpg",
      gender: "Female",
      employmentType: "Temporary",
    },
    {
      DOB: "1995-01-31",
      DOJ: "2014-10-28",
      CF1: "Sales",
      firstName: "Michael",
      lastName: "Smith",
      name: "Michael Smith",
      email: "michael.smith@example.com",
      id: "h7jzhk6nh3rt63i3lyvhld",
      job: "Accountant",
      initials: "MS",
      department: "Finance",
      workphone: "01724846900",
      mobile: "01724208597",
      manager: "michael.s@megazap.us",
      employeeid: "31",
      costcenter: "15",
      buildingid: "115",
      floorname: "MNO",
      floorsection: "central area",
      location: "120 YZ",
      address: "303 Cedar Avenue",
      image: "https://example.com/image11.jpg",
      gender: "Male",
      employmentType: "Part-Time",
    },
  ];
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  React.useEffect(() => {
    console.log(appSettings?.SyncUserInfoFrom, "selected data");

    if (appSettings?.SyncUserInfoFrom == "Google & Imported User") {
      setSearchBoxData(
        appSettings?.AllUsersData?.GoogleUsers?.length
          ? appSettings?.AllUsersData?.GoogleUsers
          : M365Users
      );
      console.log("ss", appSettings);
      employees = appSettings?.AllUsersData?.GoogleUsers?.length
        ? appSettings?.AllUsersData?.GoogleUsers
        : M365Users;
    } else {
      setSearchBoxData(appSettings?.AllUsersData?.NonGoogleUser ?? []);
      console.log("ss", appSettings?.AllUsersData);
      employees = appSettings?.AllUsersData?.NonGoogleUser?.length
        ? appSettings?.AllUsersData?.NonGoogleUser
        : [];
    }
    // employees=[...users]
    console.log(employees, "employees");
  }, []);

  console.log(users);
  function createUpdateObject(selectedDept, genderType, employmentType, DOB) {
    let updateObject: any = {};

    if (selectedDept) {
      updateObject.organizations = [{ department: selectedDept }];
    }

    if (genderType || employmentType || DOB) {
      updateObject.customSchemas = {};

      if (genderType) {
        updateObject.customSchemas.OtherFields = { Gender: genderType };
      }
      if (DOB) {
        updateObject.customSchemas.OtherFields = { DOB: DOB };
      }

      if (employmentType) {
        if (!updateObject.customSchemas.OtherFields) {
          updateObject.customSchemas.OtherFields = {};
        }
        updateObject.customSchemas.OtherFields.employmentType = employmentType;
      }
    }

    return updateObject;
  }

  const updateRecord = async () => {
    // Handle form submission
    console.log({
      selectedTitle,
      selectedDept,
      selectedEmail,
      selectedLoc,
      dateOfBirth,
      dateOfJoin,
      employmentType,
      genderType,
    });
    if (appSettings?.SyncUserInfoFrom == "Google & Imported User") {
      let user = employees?.find((item) => {
        return item?.email == selectedEmail;
      });
      let userIndex = employees?.findIndex((item) => {
        return item?.email == selectedEmail;
      });

      let userData = {
        ...user,
        gender: genderType,
        employmentType: employmentType,
        department: selectedDept,
      };

      if (userIndex > -1) {
        let data = [...employees];
        data.splice(userIndex, 1, userData);
        // console.log("77777",data);

        updateSettingJson(
          USER_LIST,
          encryptData(
            JSON.stringify({
              ...appSettings,
              AllUsersData: {
                GoogleUsers: data ?? [],
                NonGoogleUser: appSettings.NonGoogleUser ?? [],
              },
            })
          )
        );
        console.log({
          ...appSettings,
          AllUsersData: {
            GoogleUsers: data ?? [],
            NonGoogleUser: appSettings.NonGoogleUser ?? [],
          },
        });
        setUsersList({
          ...appSettings,
          AllUsersData: {
            GoogleUsers: data ?? [],
            NonGoogleUser: appSettings?.NonGoogleUser ?? [],
          },
        });
      }
      SweetAlertDashboard("success", translation.SettingSaved);
      setClearUserSearch(true);
      clearData();
    } else {
    }
    // let user=unFormatedUserData.filter((item)=>{
    //   return item.primaryEmail==selectedEmail as any;
    // })
    var date = new Date(dateOfBirth);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    //var formatted=month+"/"+day+"/"+year;
    var formattedDOB =
      year +
      "-" +
      month.toString().replace(/(^|\D)(\d)(?!\d)/g, "$10$2") +
      "-" +
      day.toString().replace(/(^|\D)(\d)(?!\d)/g, "$10$2");
    let updatedData = createUpdateObject(
      selectedDept,
      genderType,
      employmentType,
      formattedDOB
    );

    console.log(
      updatedData,
      "sadfasfasdfasdfsafasdpfasdfosadpofdpofasdfasdpfsdfoasdpofoasdfoaspof"
    );

    console.log("[][][][][][]", updatedData);
  };

  const [totalEmpDeptData, setTotalEmpDeptData] = React.useState<any>({
    labels: [],

    datasets: [
      {
        label: "Development, Sales,Marketing",
        data: [1, 3, 2],
        borderWidth: 1,
      },
    ],
  });
  const [genderDiversityData, setGenderDiversityData] = React.useState({
    labels: [],
    datasets: [
      {
        label: "Male",
        data: [],
        backgroundColor: "#FF64B8",
        borderColor: "#FF64B8",
        borderWidth: 1,
      },
      {
        label: "Female",
        data: [],
        backgroundColor: "#17AEFF",
        borderColor: "#17AEFF",
        borderWidth: 1,
      },
    ],
  });
  const [genderDiversityByDept, setGenderDiversityByDept] = React.useState({
    labels: [],
    datasets: [
      {
        label: "Male",
        data: [],
        backgroundColor: ColorArray,
        borderColor: ColorArray,
        borderWidth: 1,
      },
      {
        label: "Female",
        data: [],
        backgroundColor: ColorArray,
        borderColor: ColorArray,
        borderWidth: 1,
      },
    ],
  });
  const [employmentTypeData, setEmploymentTypeData] = useState({
    labels: [],
    datasets: [
      {
        label: "Employee type",
        data: [],
        backgroundColor: ColorArray, // Example color
        borderColor: ColorArray,
        borderWidth: 1,
      },
    ],
  });
  const [avgAgeByLoc, setAvgAgeByLoc] = useState({
    labels: [],
    datasets: [
      {
        label: "Avarage Age By Location",
        data: [],
        borderColor: "#0078d4",
        backgroundColor: "#0078d440",
        borderWidth: 1,
      },
    ],
  });
  const [avgAgeByDpt, setAvgAgeByDpt] = useState({
    labels: [],
    datasets: [
      {
        label: "Avarage Age By Depatment",
        data: [],
        backgroundColor: ColorArray,
        borderColor: ColorArray,
        borderWidth: 1,
        fill: true,
      },
    ],
  });
  const [newEmpByMonth, setNewEmpByMonth] = useState({
    labels: [],
    datasets: [
      {
        label: "New employee by month",
        data: [],
        backgroundColor: ColorArray,
        borderColor: ColorArray,
        borderWidth: 1,
        fill: true,
      },
    ],
  });
  const [tenureData, setTenureData] = useState({
    labels: [],
    datasets: [],
  });

  const [tenureByDept, setTenureByDept] = useState({
    labels: [],
    datasets: [],
  });

  // calculation functions for charts

  const aggregateEmployeesByDepartment = (employees) => {
    const departmentCounts = employees?.reduce((acc, employee) => {
      if (employee.department) {
        acc[employee.department] = (acc[employee.department] || 0) + 1;
      }
      return acc;
    }, {});

    return departmentCounts;
  };
  const formatChartData = (departmentCounts) => {
    return {
      labels: Object.keys(departmentCounts) ?? [],
      datasets: [
        {
          label: "Number of Employees by Department",
          data: Object.values(departmentCounts),
          backgroundColor: ColorArray,
          borderColor: ColorArray,
          borderWidth: 1,
        },
      ],
    };
  };
  const aggregateGenderByLocation = (employees) => {
    const locationGenderCounts = {};

    // Initialize counts
    employees?.forEach((employee) => {
      const { location, gender } = employee;

      if (!locationGenderCounts[location]) {
        locationGenderCounts[location] = { Male: 0, Female: 0, Total: 0 };
      }

      // Increment the count based on gender
      if (gender === "Male") {
        locationGenderCounts[location].Male += 1;
      } else if (gender === "Female") {
        locationGenderCounts[location].Female += 1;
      }

      // Increment the total count
      locationGenderCounts[location].Total += 1;
    });

    return locationGenderCounts;
  };

  const formatGenderChartData = (locationGenderCounts) => {
    const locations = Object.keys(locationGenderCounts);

    const malePercentages = locations.map((location) => {
      const { Male, Total } = locationGenderCounts[location];
      return Total ? ((Male / Total) * 100).toFixed(0) : 0;
    });

    const femalePercentages = locations.map((location) => {
      const { Female, Total } = locationGenderCounts[location];
      return Total ? ((Female / Total) * 100).toFixed(0) : 0;
    });

    return {
      labels: locations,
      datasets: [
        {
          label: "Male (%)",
          data: malePercentages,
          backgroundColor: "#FF64B8",
          borderColor: "#FF64B8",
          borderWidth: 1,
        },
        {
          label: "Female (%)",
          data: femalePercentages,
          backgroundColor: "#17AEFF",
          borderColor: "#17AEFF",
          borderWidth: 1,
        },
      ],
    };
  };

  const calculateGenderDiversityByDept = (employees) => {
    const departmentGenderCounts = {};

    // Count genders by department
    employees?.forEach((employee) => {
      const { department, gender } = employee;

      if (!departmentGenderCounts[department]) {
        departmentGenderCounts[department] = { Male: null, Female: null };
      }

      if (gender === "Male") {
        departmentGenderCounts[department].Male++;
      } else if (gender === "Female") {
        departmentGenderCounts[department].Female++;
      }
    });

    // Calculate percentages and prepare data for chart
    const labels = [];
    const maleData = [];
    const femaleData = [];

    Object.keys(departmentGenderCounts).forEach((department) => {
      const { Male, Female } = departmentGenderCounts[department];
      const total = Male + Female;

      const malePercentage = ((Male / total) * 100).toFixed(0);
      const femalePercentage = ((Female / total) * 100).toFixed(0);

      labels.push(department);
      maleData.push(malePercentage);
      femaleData.push(femalePercentage);
    });

    return {
      labels,
      datasets: [
        {
          label: "Male (%)",
          data: maleData,
          backgroundColor: "#FF64B8",
          borderColor: "#FF64B8",
          borderWidth: 1,
        },
        {
          label: "Female (%)",
          data: femaleData,
          backgroundColor: "#17AEFF",
          borderColor: "#17AEFF",
          borderWidth: 1,
        },
      ],
    };
  };
  const calculateEmploymentTypeCounts = (employees) => {
    const employmentTypeCounts = {};
    let fullTimeCount = 0;
    // Count employment types
    employees?.forEach((employee) => {
      const { employmentType } = employee;

      if (!employmentTypeCounts[employmentType]) {
        employmentTypeCounts[employmentType] = 0;
      }
      if (employmentType == "fulltime") {
        fullTimeCount++;
      }
      employmentTypeCounts[employmentType]++;
    });
    setFullTimeCount(fullTimeCount);

    // Prepare data for chart
    const labels = [];
    const data = [];

    Object.keys(employmentTypeCounts).forEach((type) => {
      labels.push(type);
      data.push(employmentTypeCounts[type]);
    });

    return {
      labels,
      datasets: [
        {
          label: "Number of Employees",
          data,
          backgroundColor: ColorArray,
          borderColor: ColorArray,
          borderWidth: 1,
        },
      ],
    };
  };

  const calculateAverageAgeByLocation = (employees) => {
    const locationAgeMap = {};

    employees?.forEach((employee) => {
      const { location, DOB } = employee;
      const age = calculateAge(DOB);

      if (!locationAgeMap[location]) {
        locationAgeMap[location] = { totalAge: 0, count: 0 };
      }

      locationAgeMap[location].totalAge += age;
      locationAgeMap[location].count++;
    });

    // Prepare data for chart
    const labels = [];
    const data = [];

    Object.keys(locationAgeMap).forEach((location) => {
      const { totalAge, count } = locationAgeMap[location];
      const averageAge = totalAge / count;

      labels.push(location);
      data.push(averageAge.toFixed(0));
    });

    return {
      labels,
      datasets: [
        {
          label: "Average Age (Yrs)",
          data,
          borderColor: "#0078d4",
          backgroundColor: "#0078d440",
          borderWidth: 1,
          fill: true,
        },
      ],
    };
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const calculateAverageAgeByDepartment = (employees) => {
    const departmentAgeMap = {};

    // Calculate total age and count of employees for each department
    employees?.forEach((employee) => {
      const { department, DOB } = employee;
      const age = calculateAge(DOB);

      if (!departmentAgeMap[department]) {
        departmentAgeMap[department] = { totalAge: 0, count: 0 };
      }

      departmentAgeMap[department].totalAge += age;
      departmentAgeMap[department].count++;
    });

    // Prepare data for chart
    const labels = [];
    const data = [];

    Object.keys(departmentAgeMap).forEach((department) => {
      const { totalAge, count } = departmentAgeMap[department];
      const averageAge = totalAge / count;

      labels.push(department);
      data.push(averageAge.toFixed(0));
    });

    return {
      labels,
      datasets: [
        {
          label: "Average Age (Yrs)",
          data,
          borderColor: "#0078d4",
          backgroundColor: "#0078d440",
          borderWidth: 1,
          fill: true,
        },
      ],
    };
  };
  const calculateNewEmployeesByMonth = (employees) => {
    const monthCountMap = {};

    employees?.forEach((employee) => {
      const { DOJ } = employee;
      const monthYear = formatMonthYear(new Date(DOJ));

      if (!monthCountMap[monthYear]) {
        monthCountMap[monthYear] = 0;
      }

      monthCountMap[monthYear]++;
    });

    const labels = [];
    const data = [];

    const sortedMonthKeys = Object.keys(monthCountMap).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    sortedMonthKeys.forEach((monthYear) => {
      labels.push(formatMonthLabel(new Date(monthYear)));
      data.push(monthCountMap[monthYear]);
    });

    return {
      labels,
      datasets: [
        {
          label: "Number of New Employees",
          data,
          borderColor: "#0078d4",
          backgroundColor: "#0078d440",
          borderWidth: 1,
          fill: true,
        },
      ],
    };
  };

  const formatMonthYear = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JS
    return `${year}-${month}`;
  };

  const formatMonthLabel = (date) => {
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  };

  const calculateAverageTenureByLocation = (employees) => {
    const locationTenureMap = {};
    const locationCountMap = {};

    // Calculate tenure and accumulate by location
    employees?.forEach((employee) => {
      const { DOJ, location } = employee;
      const tenure = calculateTenure(new Date(DOJ));

      if (!locationTenureMap[location]) {
        locationTenureMap[location] = 0;
        locationCountMap[location] = 0;
      }

      locationTenureMap[location] += tenure;
      locationCountMap[location]++;
    });

    // Prepare data for chart
    const labels = [];
    const data = [];

    Object.keys(locationTenureMap).forEach((location) => {
      labels.push(location);
      data.push(
        (locationTenureMap[location] / locationCountMap[location]).toFixed(0)
      );
    });

    return {
      labels,
      datasets: [
        {
          label: "Average Tenure (Years)",
          data,
          backgroundColor: ColorArray, // Example color
          borderColor: ColorArray,
          borderWidth: 1,
        },
      ],
    };
  };

  const calculateTenure = (dojDate) => {
    const today = new Date();
    let years = today.getFullYear() - dojDate.getFullYear();
    const monthDiff = today.getMonth() - dojDate.getMonth();

    // Adjust tenure if the current month is before the DOJ month
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < dojDate.getDate())
    ) {
      years--;
    }

    return years;
  };
  const calculateAverageTenureByDepartment = (employees) => {
    const departmentTenureMap = {};
    const departmentCountMap = {};

    // Calculate tenure and accumulate by department
    employees?.forEach((employee) => {
      const { DOJ, department } = employee;
      const tenure = calculateTenure(new Date(DOJ));

      if (!departmentTenureMap[department]) {
        departmentTenureMap[department] = 0;
        departmentCountMap[department] = 0;
      }

      departmentTenureMap[department] += tenure;
      departmentCountMap[department]++;
    });

    // Prepare data for chart
    const labels = [];
    const data = [];

    Object.keys(departmentTenureMap).forEach((department) => {
      labels.push(department);
      data.push(
        (
          departmentTenureMap[department] / departmentCountMap[department]
        ).toFixed(0)
      );
    });

    return {
      labels,
      datasets: [
        {
          label: "Average Tenure (Years)",
          data,
          backgroundColor: ColorArray,
          borderColor: ColorArray,
          borderWidth: 1,
        },
      ],
    };
  };

  // ------------------------------

  //------------- Header Calculation functions

  function calculateGenderDiversityRatio(employees) {
    const genderCount = employees?.reduce((acc, employee) => {
      const gender = employee.gender;
      acc[gender] = (acc[gender] || 0) + 1;
      return acc;
    }, {});

    const totalEmployees = employees?.length;
    let maleRatio = "0.00";
    let femaleRatio = "0.00";

    if (totalEmployees > 0) {
      maleRatio = (((genderCount["Male"] || 0) / totalEmployees) * 100).toFixed(
        2
      );
      femaleRatio = (
        ((genderCount["Female"] || 0) / totalEmployees) *
        100
      ).toFixed(2);
    }

    return {
      maleRatio,
      femaleRatio,
    };
  }

  function calculateAverageAge(employees) {
    const currentDate = new Date();

    const totalAge = employees?.reduce((acc, employee) => {
      const dob = new Date(employee.DOB);
      const age =
        currentDate.getFullYear() -
        dob.getFullYear() -
        (currentDate.getMonth() < dob.getMonth() ||
        (currentDate.getMonth() === dob.getMonth() &&
          currentDate.getDate() < dob.getDate())
          ? 1
          : 0);
      return acc + age;
    }, 0);

    return totalAge / employees?.length;
  }

  function calculateAverageEmployeeTenure(employees) {
    const currentDate = new Date();

    const totalTenure = employees?.reduce((acc, employee) => {
      const doj = new Date(employee.DOJ);
      const tenure =
        currentDate.getFullYear() -
        doj.getFullYear() -
        (currentDate.getMonth() < doj.getMonth() ||
        (currentDate.getMonth() === doj.getMonth() &&
          currentDate.getDate() < doj.getDate())
          ? 1
          : 0);
      return acc + tenure;
    }, 0);

    return totalTenure / employees?.length;
  }

  //-----------------------

  useEffect(() => {
    const departmentEmployeeCounts =
      aggregateEmployeesByDepartment(employees) ?? 0;
    const departmentChartData = formatChartData(departmentEmployeeCounts);
    setTotalEmpDeptData(departmentChartData);

    const genderDistributionByLocation =
      aggregateGenderByLocation(employees) ?? 0;
    const genderDiversityChartData = formatGenderChartData(
      genderDistributionByLocation
    );
    setGenderDiversityData(genderDiversityChartData as any);

    const genderDiversityByDepartment: any =
      calculateGenderDiversityByDept(employees);
    console.log("Gender Diversity by Department:", genderDiversityByDepartment);
    setGenderDiversityByDept(genderDiversityByDepartment);

    const employmentTypeDistribution: any =
      calculateEmploymentTypeCounts(employees);
    setEmploymentTypeData(employmentTypeDistribution);

    const averageAgeByLocation: any = calculateAverageAgeByLocation(employees);
    setAvgAgeByLoc(averageAgeByLocation);

    const averageAgeByDepartment: any =
      calculateAverageAgeByDepartment(employees);
    setAvgAgeByDpt(averageAgeByDepartment);

    const newEmployeesByMonth: any = calculateNewEmployeesByMonth(employees);
    setNewEmpByMonth(newEmployeesByMonth as any);

    const averageTenureByLocation = calculateAverageTenureByLocation(employees);
    setTenureData(averageTenureByLocation as any);

    const averageTenureByDepartment =
      calculateAverageTenureByDepartment(employees);
    setTenureByDept(averageTenureByDepartment as any);
  }, []);

  function handleUpdateDetail() {
    setOpenUpdatePanel(true);
  }

  // useEffect(()=>{
  //   const departmentCounts = aggregateEmployeesByDepartment(employees);

  //   // Format the chart data
  //   const formattedData = formatChartData(departmentCounts);

  //   // Set the formatted data in state
  //   setTotalEmpDeptData(formattedData);

  //   const locationGenderCounts = aggregateGenderByLocation(employees);

  //       // Format the chart data
  //       const formattedData1 = formatGenderChartData(locationGenderCounts);

  //       // Set the formatted data in state
  //       setGenderDiversityData(formattedData1 as any);

  //         const updatedData:any = calculateGenderDiversityByDept(employees);
  //         console.log("d",updateData)
  //         setGenderDiversityByDept(updatedData);
  //         const EmploymentTypeCounts = calculateEmploymentTypeCounts(employees);
  //     setEmploymentTypeData(EmploymentTypeCounts);
  //     let a:any=calculateAverageAgeByLocation(employees);
  //     setAvgAgeByLoc(a);
  //     let avgAgeByDept:any=calculateAverageAgeByDepartment(employees);
  //     setAvgAgeByDpt(avgAgeByDept);
  //     let newEmpByMonths:any=calculateNewEmployeesByMonth(employees);
  //     setNewEmpByMonth(newEmpByMonths);
  //     setTenureData(calculateAverageTenureByLocation(employees)as any)
  //     setTenureByDept(calculateAverageTenureByDepartment(employees)as any)

  // },[])

  useEffect(() => {
    setDatabyMonth(appSettings?.dashboardSettings?.newEmpByMonth);
    setDept(appSettings?.dashboardSettings?.totalEmpBYDept);
    setEmpType(appSettings?.dashboardSettings?.empType);
    setAvg(appSettings?.dashboardSettings?.avgAgeByDept);
    setAvgTenByLoc(appSettings?.dashboardSettings?.avgAgeByLoc);
    setAvgTenByDept(appSettings?.dashboardSettings?.avgTenByDept);
    setAvgTenByDept(appSettings?.dashboardSettings?.avgTenByDept);
    setAvgTenByLoc(appSettings?.dashboardSettings?.avgTenByLoc);
    setSampleData(appSettings?.dashboardSettings?.sampleData);
    let genderDiversityRatioForHeader: any =
      calculateGenderDiversityRatio(employees);
    setGenderDiversityRatioForHeader(genderDiversityRatioForHeader);
    let avgAgeHeader: any = calculateAverageAge(employees);
    setAvgAgeHeader(avgAgeHeader);
    let avgTenure: any = calculateAverageEmployeeTenure(employees);
    setAvgTenureHeader(avgTenure);
  }, []);
  function transformUserDataToFomat(users) {
    return users?.map((user) => ({
      id: user?.id,
      image: user?.image,
      initials: user?.initials,
      name: user?.name,
      job: user?.job,
      email: user?.email,
      department: user?.department,
      dob: user?.DOB,
      doj: user?.DOJ,
      location: user?.location,
    }));
  }
  const saveallview = () => {
    let temp = {
      dashboardSettings: {
        newEmpByMonth: databyMonth ?? false,
        totalEmpBYDept: dept ?? false,
        empType: empType ?? false,
        avgAgeByDept: avg ?? false,
        avgAgeByLoc: agebylocation ?? false,
        sampleData: sampleData ?? false,
        avgTenByDept: avgTenByDept ?? false,
        avgTenByLoc: AvgTenByLoc ?? false,
        genDivByDept: genDivByDept ?? false,
        genDivByLoc: genDivByLoc ?? false,
      },
    };
    updateSettingJson(SETTING_LIST, temp, true);
    // setAppSettings(temp);
    dispatch(
      setAllSettings({
        dashboardSettings: {
          newEmpByMonth: databyMonth ?? false,
          totalEmpBYDept: dept ?? false,
          empType: empType ?? false,
          avgAgeByDept: avg ?? false,
          avgAgeByLoc: agebylocation ?? false,
          sampleData: sampleData ?? false,
          avgTenByDept: avgTenByDept ?? false,
          avgTenByLoc: AvgTenByLoc ?? false,
          genDivByDept: genDivByDept ?? false,
          genDivByLoc: genDivByLoc ?? false,
        },
      })
    );
    setAddmsg(true);
    setTimeout(() => {
      setAddmsg(false);
    }, 3000);
  };

  const OnmonthChnage = () => setDatabyMonth(!databyMonth);
  const ondeptChnage = () => setDept(!dept);
  const onEmpTypeChnage = () => setEmpType(!empType);
  const _onchangeGenderDevDept = () => setGenDivByDept(!genDivByDept);
  const _onchangeGenderDevLoc = () => setGenDivByLoc(!genDivByLoc);
  const Avg = () => setAvg(!avg);
  const Agebylocation = () => setAgebylocation(!agebylocation);
  const AvgTenureByDept = () => setAvgTenByDept(!avgTenByDept);
  const ondenChnage = () => setDen(!den);
  const Deptbylocation = () => setDeptbylocation(!deptbylocation);
  const AvgTenureByLoc = () => setAvgTenByLoc(!AvgTenByLoc);
  const samplDataOnchange = () => setShowSampleData(!showSampleData);
  const _onchangeSampleData = () => setSampleData(!sampleData);
  function handleHomeButton() {
    // setSettings(false);
    // setShowOrgChart(false);
    // setshowDashboard(false);
    // setShowHomePage(true);
    // document.getElementById(`letter-ALL`).click();
    dispatch(setShowHidePages(Home_KEY))
    dispatch(setSelectedLetter("All"));
  }

  const checkboxStyle = { root: { marginTop: "0px" } };
  const options = [
    { key: "option1", text: "Option 1" },
    { key: "option2", text: "Option 2" },
  ];
  const defaultKey = "option1";

  const handleSelect = async (item) => {
    console.log("Selected item:", item);

    setSelectedDept(item?.department || "");
    setSelectedLoc(item?.location || "");
    setSelectedEmail(item?.email || "");
    setSelectedTitle(item?.job || "");
  };
  function clearData() {
    setSelectedDept("");
    setSelectedLoc("");
    setSelectedEmail("");
    setSelectedTitle("");
  }

  const onAttachmentChange = (ev: any) => {
    if (ev.target.files.length > 0) {
      setFileNameUser(ev.target.files[0]?.name);
    }
    processDataBulkUser(ev);
  };

  type UpdateField = {
    email: string;
    gender?: string;
    employmentType?: string;
    department?: string;
  };

  const updateBulkUsers = (updates: UpdateField[]) => {
    if (!appSettings?.AllUsersData?.GoogleUsers) {
      console.error("User data not available.");
      return;
    }
    let users = [...appSettings.AllUsersData.GoogleUsers];
    const updateMap = new Map<string, Partial<UpdateField>>();
    updates.forEach(({ email, ...fields }) => {
      updateMap.set(email, fields);
    });

    users = users.map((user) => {
      const updateFields = updateMap.get(user.email);
      if (updateFields) {
        return {
          ...user,
          ...updateFields,
        };
      }
      return user;
    });
    return users;
  };

  const processDataBulkUser = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    const wb = new Excel.Workbook();
    const reader = new FileReader();

    try {
      reader.readAsArrayBuffer(file);

      reader.onload = async () => {
        const buffer = reader.result;
        if (buffer instanceof ArrayBuffer) {
          try {
            await wb.xlsx.load(Buffer.from(buffer));
            const updateDataArray1: any[] = [];

            wb.eachSheet((sheet) => {
              sheet.eachRow((row, rowIndex) => {
                if (rowIndex === 1) return; // Skip header row if necessary

                const mappedData: { [key: string]: any } = {};
                row.eachCell((cell: any, colNumber) => {
                  const headerCell = sheet.getRow(1).getCell(colNumber);
                  const key = headerCell.value as string;
                  let value: any;
                  if (key == "email") {
                    value = cell.value?.text;
                  } else {
                    value = cell.value;
                  }
                  if (key) {
                    mappedData[key] = value;
                  }
                });

                console.log(mappedData, "mappeddat");
                updateDataArray1.push(mappedData);
              });
            });

            setupdateDataArray(updateDataArray1); // Update state after processing all rows
            console.log("updateDataArray1", updateDataArray1);
          } catch (error) {
            console.log("Error loading workbook:", error);
          }
        } else {
          console.log("Failed to load file as ArrayBuffer.");
        }
      };
    } catch (error) {
      console.log("Error reading file:", error);
    }
  };

  let bulkExcelDataExample = [
    { email: "", DOB: "", DOJ: "", employmentType: "", gender: "" },
  ];
  const downloadTemplate = (csvData, fileName, sheetName) => {
    var workbook = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(csvData);
    XLSX.utils.book_append_sheet(workbook, ws, sheetName);
    XLSX.writeFile(workbook, `${fileName}` + ".xlsx", { type: "file" });
  };
  function getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    var m = today.getMonth() + 1; // JavaScript months are 0-based.
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + "" + m + "" + d + "" + h + "" + mi + "" + s;
  }
  const saveDataBulkUsers = () => {
    let KEY_NAME = "GoogleUsers";
    if (updateDataArray?.length) {
      // setLoading1(true);
      // setButtonSaveText1("");
      const Data = updateDataArray.slice(1);
      let results = updateBulkUsers(Data);
      let data = {
        GoogleUsers: results,
        NonGoogleUser: appSettings?.NonGoogleUser,
      };
      updateSettingJson(
        USER_LIST,
        encryptData(JSON.stringify({ ...appSettings, AllUsersData: data }))
      );
      setUsersList({ ...appSettings, AllUsersData: data });
      SweetAlertDashboard("success", translation.SettingSaved);
    } else {
      SweetAlertDashboard("info", "Please select file");
    }
  };

  return (
    <>
      <div
        className=""
        style={{ width: "100%", display: "flex", alignItems: "flex-start" }}
      >
        <div style={containerStyle}>
          <div className="chartHeaders" style={{ ...itemStyle }}>
            <p>{sampleData ? "161" : searchBoxData?.length ?? users?.length}</p>
            <p>{translation.Head ? translation.Headcount : "Count Head "}</p>
          </div>
          <div
            className="chartHeaders"
            style={{ ...itemStyle, background: "#ff7f2b" }}
          >
            <p>{sampleData ? "150" : fullTimeCount}</p>
            <p>{translation?.FullTime ? translation?.FullTime : "Full Time"}</p>
          </div>
          <div
            className="chartHeaders"
            style={{ ...itemStyle, background: "#5a9316" }}
          >
            <p>
              {sampleData
                ? "63.5%"
                : `${genderDiversityRatioForHeader.femaleRatio}%`}
            </p>
            <p>
              {translation?.GenderDivRatio
                ? translation?.GenderDivRatio
                : "Gender Diversity Ratio"}
            </p>
          </div>
          <div
            className="chartHeaders"
            style={{ ...itemStyle, background: "#ffa126" }}
          >
            <p>
              {" "}
              {sampleData
                ? "33.5"
                : typeof avgAgeHeader === "number" && !isNaN(avgAgeHeader)
                ? avgAgeHeader.toFixed(2)
                : "0"}
            </p>
            <p>
              {translation.AvgAgeYrs
                ? translation.AvgAgeYrs
                : "Average Age (Yrs)"}
            </p>
          </div>
          <div
            className="chartHeaders"
            style={{ ...itemStyle, background: "#37c42d" }}
          >
            <p>
              {sampleData
                ? "3.5"
                : typeof avgTenureHeader === "number" && !isNaN(avgTenureHeader)
                ? avgTenureHeader.toFixed(2)
                : "0"}
            </p>
            <p>
              {translation.AvgEmpTen
                ? translation.AvgEmpTen
                : "Avg Employee Tenure (Yrs)"}
            </p>
          </div>
        </div>
        <div style={iconContainerStyle}>
          <Icon
            iconName="Home"
            style={{ fontSize: "17px", marginBottom: "10px" }}
            onClick={handleHomeButton}
          />
          <Icon
            iconName="Refresh"
            style={{ fontSize: "17px", marginBottom: "10px" }}
          />
          <Icon
            iconName="Settings"
            style={{ fontSize: "17px" }}
            onClick={() => setOpenPanel(true)}
          />
        </div>
      </div>
      {sampleData ? (
        <div style={gridStyle}>
          <LineChart
            data={newEmployeesDataSample}
            heading={
              translation.NumEmpMonth
                ? translation.NumEmpMonth
                : "Number of New Employees by Month"
            }
          />
          <BarCharts
            data={totalEmployeesByDepartmentDataSample}
            heading={
              translation.NumEmpDept
                ? translation.NumEmpDept
                : "Number of Total Employees by Department"
            }
          />
          <DonutChart
            data={employmentTypeDataSample}
            heading={
              translation.NumEmpType
                ? translation.NumEmpType
                : "Number of Employment Type"
            }
          />
          <LineChart
            data={averageAgeByDepartmentDataSample}
            heading={
              translation.AvgAgeDept
                ? translation.AvgAgeDept
                : "Average Age(Yrs) by Department"
            }
          />
          <LineChart
            data={averageAgeByLocationDataSample}
            heading={
              translation.AvgAgeLoc
                ? translation.AvgAgeLoc
                : "Average Age(Yrs) by Location"
            }
          />
          <DonutChart
            data={averageTenureByDepartmentDataSample}
            heading={
              translation.AvgTenByDept
                ? translation.AvgTenByDept
                : "Average Tenure(Yrs) by Department"
            }
          />
          <BarCharts
            data={genderDiversityByDepartmentDataSample}
            heading={
              translation.GenDivDept
                ? translation.GenDivDept
                : "Gender Diversity by Department"
            }
          />
          <BarCharts
            data={genderDiversityByLocationDataSample}
            heading={
              translation.GenDivLoc
                ? translation.GenDivLoc
                : "Gender Diversity by Location"
            }
          />
          <DonutChart
            data={averageTenureByLocationDataSample}
            heading={
              translation.AvgTenByLoc
                ? translation.AvgTenByLoc
                : "Average Tenure(Yrs) by Location"
            }
          />
        </div>
      ) : (
        <div style={gridStyle}>
          {databyMonth && (
            <LineChart
              data={newEmpByMonth}
              heading={
                translation.NumEmpMonth
                  ? translation.NumEmpMonth
                  : "Number of New Employees by Month"
              }
            />
          )}
          {dept && (
            <BarCharts
              data={totalEmpDeptData}
              heading={
                translation.NumEmpDept
                  ? translation.NumEmpDept
                  : "Number of Total Employees by Department"
              }
            />
          )}
          {empType && (
            <DonutChart
              data={employmentTypeData}
              heading={
                translation.NumEmpType
                  ? translation.NumEmpType
                  : "Number of Employment Type"
              }
            />
          )}
          {avg && (
            <LineChart
              data={avgAgeByDpt}
              heading={
                translation.AvgAgeDept
                  ? translation.AvgAgeDept
                  : "Average Age(Yrs) by Department"
              }
            />
          )}
          {agebylocation && (
            <LineChart
              data={avgAgeByLoc}
              heading={
                translation.AvgAgeLoc
                  ? translation.AvgAgeLoc
                  : "Average Age(Yrs) by Location"
              }
            />
          )}
          {avgTenByDept && (
            <DonutChart
              data={tenureByDept}
              heading={
                translation.AvgTenByDept
                  ? translation.AvgTenByDept
                  : "Average Tenure(Yrs) by Department"
              }
            />
          )}
          {genDivByDept && (
            <BarCharts
              data={genderDiversityByDept}
              heading={
                translation.GenDivDept
                  ? translation.GenDivDept
                  : "Gender Diversity by Department"
              }
            />
          )}
          {genDivByLoc && (
            <BarCharts
              data={genderDiversityData}
              heading={
                translation.GenDivLoc
                  ? translation.GenDivLoc
                  : "Gender Diversity by Location"
              }
            />
          )}
          {AvgTenByLoc && (
            <DonutChart
              data={tenureData}
              heading={
                translation.AvgTenByLoc
                  ? translation.AvgTenByLoc
                  : "Average Tenure(Yrs) by Location"
              }
            />
          )}
        </div>
      )}
      <Panel
        type={PanelType.custom}
        customWidth="500px"
        headerText="Dashboard Setting"
        isOpen={OpenPanel}
        onDismiss={() => {
          setOpenPanel(false);
          clearData();
        }}
        closeButtonAriaLabel="Close"
      >
        <div className="ms-Grid" id="dashboard">
          <Pivot id="DashBoardSettings">
            <PivotItem headerText="General">
              <div className={"A"}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Label style={{ fontSize: "13px" }}>
                      <b>
                        {translation.SelectChartDisplay
                          ? translation.SelectChartDisplay
                          : "Select the charts to display"}
                      </b>
                    </Label>
                  </div>
                  <div
                    style={{
                      marginTop: "-5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ fontSize: "13px" }}>
                      <b>
                        {translation.Action ? translation.Action : "Action"}
                      </b>
                    </Label>
                    <IconButton
                      id="iconWithActiveTooltip"
                      style={{ height: "22px", marginLeft: "0px" }}
                      title="Selecting the checkbox will make charts visible."
                      onClick={toggleTeachingBubbleAction}
                      iconProps={{ iconName: "Info" }}
                    />
                    {teachingBubbleAction && (
                      <TeachingBubble
                        calloutProps={{
                          directionalHint: DirectionalHint.bottomCenter,
                        }}
                        target="#iconWithActiveTooltip"
                        isWide={true}
                        hasCloseButton={true}
                        closeButtonAriaLabel="Close"
                        onDismiss={toggleTeachingBubbleAction}
                        headline=""
                      >
                        {translation.actiontooltip
                          ? translation.actiontooltip
                          : " Selecting the checkbox will make charts visible."}
                      </TeachingBubble>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.NumberOfNewEmployeesByMonth
                        ? translation.NumberOfNewEmployeesByMonth
                        : "Number of New Employees by Month"}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        defaultChecked={databyMonth}
                        onChange={OnmonthChnage}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.NumEmpDept
                        ? translation.NumEmpDept
                        : "Number of Total Employees by Department"}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox defaultChecked={dept} onChange={ondeptChnage} />
                    </div>
                  </div>
                </div>

                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.NumEmpType
                        ? translation.NumEmpType
                        : "Number of Employment Type"}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        defaultChecked={empType}
                        onChange={onEmpTypeChnage}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.AvgAgeDept
                        ? translation.AvgAgeDept
                        : "Average Age(Yrs) by Department"}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox defaultChecked={avg} onChange={Avg} />
                    </div>
                  </div>
                </div>

                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.AvgAgeLoc
                        ? translation.AvgAgeLoc
                        : "Average Age(Yrs) by Location"}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        defaultChecked={agebylocation}
                        onChange={Agebylocation}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.AvgTenByDept
                        ? translation.AvgTenByDept
                        : "Average Tenure(Yrs) by Department"}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        defaultChecked={avgTenByDept}
                        onChange={AvgTenureByDept}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.AvgTenByLoc
                        ? translation.AvgTenByLoc
                        : "Average Tenure(Yrs) by Location"}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        defaultChecked={AvgTenByLoc}
                        onChange={AvgTenureByLoc}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.DisSmplDataDB
                        ? translation.DisSmplDataDB
                        : "Display Sample data on Dashboard"}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        defaultChecked={sampleData}
                        onChange={_onchangeSampleData}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.GenDivDept
                        ? translation.GenDivDept
                        : "Gender Diversity by Department"}{" "}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        defaultChecked={genDivByDept}
                        onChange={_onchangeGenderDevDept}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.settingBlock}>
                  <div className={styles.checkboxRow}>
                    <Label className={styles.checkboxLabel}>
                      {translation.GenDivLoc
                        ? translation.GenDivLoc
                        : "Gender Diversity by Location"}
                    </Label>
                    <div className={styles.checkboxWrapper}>
                      <Checkbox
                        defaultChecked={genDivByLoc}
                        onChange={_onchangeGenderDevLoc}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton
                      text={translation.save ? translation.save : "Save"}
                      onClick={saveallview}
                      ariaLabel="Save"
                    />
                  </Stack>
                </div>
              </div>
            </PivotItem>

          {isUserAdmin?
          <PivotItem
              headerText={
                translation.AdvancedSettings
                  ? translation.AdvancedSettings
                  : "Advanced Settings"
              }
            >
              {/* <CustomPeoplePicker onUserSelect={setSelectedUserPeoplePicker} setSelectedDept={setSelectedDept} setSelectedLoc={setSelectedLoc}  /> */}
              <div className="ms-Grid-row" style={{ margin: "10px 0" }}>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <UserSearchBox
                    clearInput={isClearUserSearch}
                    options={transformUserDataToFomat(searchBoxData)}
                    onSelect={handleSelect}
                    placeholder={
                      translation.SearchUser
                        ? translation.SearchUser
                        : "Search user"
                    }
                  />
                </div>
              </div>
              {selectedDept?.length ? (
                <>
                  <div className={styles.editorBlockStyles}>
                    <div className={styles.RichEditorBlk}>
                      <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <Label>
                              {translation.Title ? translation.Title : "Title"}
                            </Label>
                            <TextField
                              onChange={onTextChangedesign}
                              value={selectedTitle}
                            />
                          </div>
                        </div>

                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <Label>
                              {translation.Department
                                ? translation.Department
                                : "Department"}
                            </Label>
                            <TextField
                              onChange={onTextChangedept}
                              value={selectedDept}
                            />
                          </div>
                          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <Label>
                              {translation.Email ? translation.Email : "Email"}
                            </Label>
                            <TextField
                              onChange={onTextChangeemail}
                              // value={selectedUserPeoplePicker?.email}
                              value={selectedEmail}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <Label>
                              {translation.Location
                                ? translation.Location
                                : "Location"}
                            </Label>
                            <TextField
                              onChange={onTextChangeofc}
                              value={selectedLoc}
                            />
                          </div>
                        </div>

                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <Label>
                              {translation.Dateofbirth
                                ? translation.Dateofbirth
                                : "Date of birth"}
                            </Label>
                            <DatePicker
                              placeholder={
                                translation.Selectdate
                                  ? translation.Selectdate
                                  : "Select date"
                              }
                              ariaLabel="Select a date"
                              value={dateOfBirth}
                              onSelectDate={ondobChange}
                              // formatDate={
                              //   onFormatDate
                              // }
                            />
                          </div>
                        </div>

                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            {/* <div className={styles.tab1}>          */}
                            <Label>
                              {translation.Dateofjoining
                                ? translation.Dateofjoining
                                : "Date of joining"}
                            </Label>
                            <DatePicker
                              placeholder={
                                translation.Selectdate
                                  ? translation.Selectdate
                                  : "Select date"
                              }
                              ariaLabel="Select a date"
                              value={dateOfJoin}
                              onSelectDate={ondojChange}
                              // formatDate={
                              //   onFormatDate
                              // }
                            />
                          </div>
                        </div>

                        <div className="ms-Grid-row">
                          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            {/* <div className={styles.tab1}> */}
                            <Label>
                              {translation.EmpTypeLabel
                                ? translation.EmpTypeLabel
                                : "Employment Type"}
                            </Label>
                            {/* <TextField onChange={onTextChangeetype}
                          value={selectedetype}
                        /> */}
                            <Dropdown
                              // styles={dropdownstyles}
                              placeholder={
                                translation.Selectanoption
                                  ? translation.Selectanoption
                                  : "Select an option"
                              }
                              onChange={onTextChangeetype}
                              defaultSelectedKey={employmentType}
                              options={[
                                { key: "fulltime", text: "Full Time" },
                                { key: "parttime", text: "Part Time" },
                              ]}

                              // options={dropdownEmploymentType}
                            />
                          </div>

                          <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                            <Label>
                              {translation.Gender
                                ? translation.Gender
                                : "Gender"}
                            </Label>
                            {/* <TextField onChange={onTextChangegender}
                          value={selectedgender}
                        /> */}
                            <Dropdown
                              // styles={dropdownstyles}
                              placeholder={
                                translation.Selectanoption
                                  ? translation.Selectanoption
                                  : "Select an option"
                              }
                              options={[
                                { key: "male", text: "Male" },
                                { key: "female", text: "Female" },
                              ]}
                              onChange={onTextChangegender}
                              defaultSelectedKey={genderType ?? "male"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <PrimaryButton
                    style={{ margin: "10px" }}
                    text={translation.Submit ? translation.Submit : "Submit"}
                    onClick={updateRecord}
                  />
                </>
              ) : (
                ""
              )}

              {!selectedEmail?.length ? (
                <>
                  <div className={"uploadCSVblkStyles"}>
                    <Label>Update user(s) in bulk</Label>
                    <Stack>
                      <DefaultButton
                        className={`${styles.dashboardbulkFileDownBtn} downloadButton`}
                        onClick={() =>
                          downloadTemplate(
                            bulkExcelDataExample,
                            "user_detail:-" + getFormattedTime(),
                            "sheet1"
                          )
                        }
                      >
                        <CommandBarButton text={"Download sample file"} />
                      </DefaultButton>
                    </Stack>
                    <div className={"importCSVFileBlk"}>
                      <div
                        className={`${styles.ImportCsvDashboardflexContainer} importCSVFile`}
                      >
                        <Label
                          onClick={() =>
                            document.getElementById("bulkdata").click()
                          }
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {"Import File"}
                        </Label>
                        <Icon
                          onClick={() =>
                            document.getElementById("bulkdata").click()
                          }
                          iconName="Attach"
                          style={{ cursor: "pointer" }}
                        />
                        <span style={{ whiteSpace: "nowrap" }}>
                          {fileNameUser}
                        </span>
                        <input
                          id="bulkdata"
                          onChange={onAttachmentChange}
                          type="file"
                          style={{ display: "none" }}
                        />
                      </div>
                      <div>
                        <PrimaryButton
                          text="save"
                          onClick={saveDataBulkUsers}
                        ></PrimaryButton>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3>
                      <b>{"Instructions:-"}</b>
                    </h3>
                    <ul style={{ margin: "15px 30px" }}>
                      <li>
                        <label>
                          {"Add multiple records up to 100 records per file.."}
                        </label>
                      </li>
                      <li>
                        {
                          "Please fill the details with exact match to avoid conflicts of CSV file."
                        }
                      </li>
                      <li>
                        {
                          "Please don't add any commas or any other fields to avoid conflicts with commas of CSV file."
                        }
                      </li>
                      <li>
                        {
                          "Please don't change the order of the columns or add new columns; this may lead to feeding values in incorrect columns."
                        }
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                ""
              )}

              <Panel
                type={PanelType.custom}
                customWidth="650px"
                headerText={
                  translation.UpdtUserDetail
                    ? translation.UpdtUserDetail
                    : "Update user(s) detail"
                }
                isOpen={isOpenUpdatePanel}
                onDismiss={() => setOpenUpdatePanel(false)}
                closeButtonAriaLabel={
                  translation.Close ? translation.Close : "Close"
                }
              >
                {/* <DashboardImport langcode={translation} /> */}
              </Panel>
            </PivotItem>:""}
          </Pivot>
        </div>
      </Panel>
    </>
  );
};

export default Dashboard;
