import { useEffect, useState } from "react";
import styled from "styled-components";
import CommandBar from "./CommandBar";
import { gapi } from "gapi-script";
import { style } from "./styles";
import useStore, { useSttings } from "./store";
import SettingsPage from "../Pages/SettingsPage";
import Home from "../Pages/Home";
import styles from "../SCSS/Ed.module.scss";
import './SignInPage.css';
import '../SCSS/TopBar.css';
import OrgChartPage from "../Pages/OrgChartPage";
import {
  changeFavicon,
  decryptData,
  downloadCSV,
  ExcludeExternalUsers,
  ExcludeUsers,
  filterTrueValues,
  getFirstAvailableView,
  removeDuplicatesFromObject,
  removeFavicon,
  customFunctionFilter,
  getRestrictedAccess,
  getCurrentUser,
  applyRestrictions,
  isUserAdminCheck,
  encryptData,
  DASHBOARD_KEY,
  Home_KEY,
  SETTING_KEY,
  ORGCHART_KEY,
  generatePDF,
} from "../Helpers/HelperFunctions";

import { usePDF } from "react-to-pdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SearchFilters from "../SearchFilters/SearchFilters";
import { LanguageProvider, useLanguage } from "../../Language/LanguageContext";
import ExportToCsv from "../Pages/ExportToCsv";
import BirthdayAndAnniversary from "../BithdayAndAnniversary";
import Dashboard from "../Dashboard";
import { useFields, useLists } from "../../context/store";
import {
  CurrentUser_LIST,
  getSettingJson,
  IMAGES_LIST,
  refresh,
  SETTING_LIST,
  updateSettingJson,
  USER_LIST,
} from "../../api/storage";
import {
  defaultImagesList,
  defaultSettingList,
  defaultUserList,
} from "../../api/defaultSettings";
import { ContextProvider } from "../../context/Context";
import HelpPanel from "../HelpPage";
import HelpPage from "../HelpPage";
import { useDispatch, useSelector } from "react-redux";
import { addM365User, fetchSettings, fetchUsersDataSettings, setAllDepartments, setAllJobTitle, setAllNonDepartments, setAllNonJobTitle, setAllSettings, setCurrentUserData, setfetchUserError, setIsCurrentUserAdmin, setViewSetting } from "../../Store/appSettingsSlice";
import Department from "./Department";
import { useAvatarGroupPopoverContextValues_unstable  } from "@fluentui/react-components";
import { MessageBar,MessageBarType} from 'office-ui-fabric-react';
import Test from "../Utils/Test"
const Container = styled.div`
${style}
`;
const DefaultImage = require("../assets/images/DefaultImg1.png");
const  DirectoryImage =require("../assets/images/directory-logo.png");

let _customgrps = ["Groups"];
let resultgrpCf = [];
var _arraygrpcf1 = [];


const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const API_KEY = process.env.REACT_APP_STORAGE_API_KEY;
const DISCOVERY_DOCS = [
  "https://admin.googleapis.com/$discovery/rest?version=directory_v1",
];
const scopes =
  "https://www.googleapis.com/auth/admin.directory.group.readonly https://www.googleapis.com/auth/admin.directory.user https://www.googleapis.com/auth/admin.directory.group.member.readonly https://www.googleapis.com/auth/admin.directory.user.readonly https://www.googleapis.com/auth/admin.directory.user.security https://www.googleapis.com/auth/cloud-platform https://www.googleapis.com/auth/devstorage.full_control https://www.googleapis.com/auth/devstorage.read_write";
let allUsers = [];
var allletter = "ALL";
var parsedData = "";
var sortby = "givenName";
var _gridWidth = "";
var _grpsOn = "";
var _arraygrpcf1 = [];
var isCurrentUserAdmin = false;
var specifieduser;
var specifiedmanager;
var _excspecuser = [];
var _excspecuserss = "";
var arrmanager = [];
var _excmgruserss = "";
let selectedView = "";
let NonMUsers = [];
let NonMutableFilter=[];
const messageBarInfoStyles = {
  root: {
    ".ms-MessageBar-icon": {
      color: "#333",
    },
    backgroundColor: "rgb(243, 242, 241)",
    Margin:"10px 0",
  },
};
const GoogleEmployeeDirectory = () => {
   const {
      
      setLanguagePartUpdate,
      
  
    } = useLanguage();
    const viewsSettings=useSelector((state)=>state.storeSettings.views)
  const appSettings=useSelector((state)=>state.storeSettings.appSettings)
  const VIEWS=useSelector((state)=>state.storeSettings.views)
  const pages=useSelector((state)=>state.storeSettings.pages)
  const CurrentUserData=useSelector((state)=>state.storeSettings.CurrentUserData)
  const M365Users=useSelector((state)=>state.storeSettings.M365Users)
  const userfetcherror=useSelector((state)=>state.storeSettings.FetchUserError);
  const code = userfetcherror?.result?.error?.code;
  const message = userfetcherror?.result?.error?.message;
  const invalidAccount = code==400 && message=="Invalid Input"
  console.log(code==400 && message=="Invalid Input",">====")

  const[Sett,setSett]=useState(appSettings);
  // ---------------STATES----------------
  const [isBirthAndAnivModalOpen, setBirthAndAnivModalOpen] = useState(false);
  const[refreshPage,setrefreshPage]=useState(false);
  const [NonM365, setNonM365] = useState([]);
  const [nextPageToken, setNextPageToken] = useState('');
  const [pToken, setPToken] = useState("");
  const [settingData, setSettingData] = useState({});
  const [isClickClearFilter, setClickClearFilter] = useState(false);
  const [isGrid, setisGrid] = useState(false);
  const [isTile, setisTile] = useState(false);
  const [isImportedUser, setImpotedUser] = useState(false);
  const [selectedGrp, setselectedGrp] = useState([]);
  const [isList, setisList] = useState(false);
  const [showHelpPage, setShowHelpPage] = useState(false);
  const [recordsToload, setRecordsToload] = useState(25);
  const [UserArray, setUserArray] = useState([]);
  const [currentUserAdmin, setCurrentUserAdmin] = useState(false);
  const [showDashboard, setshowDashboard] = useState(false);
  const [showProgress, setshowProgress] = useState(true);
  const [groupsOptions, setGroupsOptions] = useState([]);

  // ------------PAGES STATES------------------
  const [showOrgChart, setShowOrgChart] = useState(false);
  const [showHomePage, setShowHomePage] = useState(true);
  const [Setting, setSettings] = useState(false);
  const [isDownloadCsvModal, setDownloadCsvModal] = useState(false);
  const {
    setAdditionalManagers,
    setAssistant,
    setUnFormatedUserData,
    setFormatedUserData,
    setAllUsers,
    setRestrictedFields,
    setUsersWithHiddenManager,
    setUsersWithHiddenPhoneNo,
    setAdmins,
  } = useFields();
  const dispatch=useDispatch();
  // -------------- GLOBAL STATE------------
  const {
    changeVariable,
    changeUserData,
    changeUserArray,
    changeCheckboxesCheckedListView,
    checkboxesCheckedListView,
    changeGridWidth,
    checkboxesChecked,
    changeCheckboxesChecked,
    changeUserGridView,
    userGridView,
    changeExcludeByDepartment,
    view,
    setView,
  } = useStore();
  const {
    setDepartments,
    setLocations,
    setJobTitles,
    setDepDropDown,
    setJobDropDown,
    setExcludeOptionsForDomain,
    setEmails,
    setCommandBarItems,
    setShowHideSettings,
  } = useFields();

  const { usersList, setUsersList, setImagesList, setSettingList } = useLists();



  useEffect(() => {
    // Add event listener on mount
    window.addEventListener("resize", changeView);

    function changeView() {
      if (window.innerWidth < 768) {
        let temp = {
          ...parsedData,
          defaultMobileView: parsedData?.defaultMobileView,
        };
        
        setView(parsedData?.defaultMobileView);
        if(parsedData?.defaultMobileView =="Grid"){
          dispatch(setViewSetting("isGrid"))
        }else if(parsedData?.defaultMobileView =="List"){
          dispatch(setViewSetting("isList"))
        }else if(parsedData?.defaultMobileView =="Tile"){
          dispatch(setViewSetting("isTile"))
        }else if(parsedData?.defaultMobileView =="NonM365"){
          dispatch(setViewSetting("isNonM365"))
        }else{
          dispatch(setViewSetting("isGrid"))
        }
      } else {
        setView(parsedData.DefaultView);
        selectedView = parsedData?.DefaultView;
        setDefaultView();
        // console.log("Not in mobile view. Function not called.");
      }
    }

    return () => {
      window.removeEventListener("resize", changeView);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (view == "Grid") {
      setisGrid(true);
      setisList(false);
      setisTile(false);
      setImpotedUser(false);
    } else if (view == "List") {
      setisGrid(false);
      setisList(true);
      setisTile(false);
      setImpotedUser(false);
    } else if (view == "Tile") {
      setisGrid(false);
      setisList(false);
      setisTile(true);
      setImpotedUser(false);
    } else if (view == "NonM365") {
      setisGrid(false);
      setisList(false);
      setisTile(false);
      setImpotedUser(true);
    }
    // console.log("feom index",view)
  }, [view]);
  

  //--------------AUTH SERVICES STARTS------------------

  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const [showLoginScreen, setShowLoginScreen] = useState(false);

  const signIn = () => {
    gapi.auth2
      ?.getAuthInstance()
      .signIn()
      .then(() => {
        setShowLoginScreen(false);
        setIsUserSignedIn(true);
        // updateSettingJson(SETTING_LIST, defaultSettingList,true);
        blobCall();
      })
      .catch(() => {
        setShowLoginScreen(true);
        setIsUserSignedIn(false);
      });
  };

  const signOut = () => {
    gapi.auth2
      .getAuthInstance()
      .signOut()
      .then(() => {
        setShowLoginScreen(true);
        setIsUserSignedIn(false);
      });
  };

  const signInWithGoogle = () => {
    gapi.load("client:auth2", async () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: scopes,
        })
        .then(async() => {
          await gapi.client.load("admin", "directory_v1");
          const userSignInState = gapi.auth2?.getAuthInstance().isSignedIn.get();
          setIsUserSignedIn(userSignInState);
          if (userSignInState) {
          //   // updateSettingJson(SETTING_LIST, defaultSettingList,true);
            let usersJson = await getSettingJson(USER_LIST);
            setUsersList(usersJson);
            setLanguagePartUpdate(prev=>!prev)
          
           blobCall();
            
         

          } else {
            setShowLoginScreen(true);
          }
        });
    });
  };
  

  const signInWithPopup = () => {
    gapi.load("client:auth2", async () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: scopes,
        })
        .then(() => {
          
          const userSignedIn = gapi?.auth2?.getAuthInstance()?.isSignedIn?.get();
          setIsUserSignedIn(userSignedIn);
          signIn();
        });
    });
  };

  useEffect(() => {
    signInWithGoogle();
  }, [isUserSignedIn]);

  //--------------AUTH SERVICES EDNS------------------

  // ---------------------FUNCTIONS---------------------
  async function getAllUsersInOrg() {
    let pageToken = "";
    let staffList = [];
  
 
      try{

      
    const page = await gapi.client.directory.users.list({
        customer: "my_customer",
        projection: "full",
        orderBy: sortby,
        query:
          allletter === "ALL"
            ? `${sortby}:*`
            : `${sortby}:${allletter?.toLowerCase()}*`,
        maxResults: allletter != "ALL"?499: parsedData?.records_to_load??25,
        pageToken: pageToken,
      });
  
      // Append the fetched users to staffList
      if (page.result.users) {
        staffList = [...staffList, ...page.result.users]; // Accumulate users
      }
  
      // Update the pageToken for the next iteration
      pageToken = page.result.nextPageToken;
      setNextPageToken(pageToken)
    }
    catch(err){
      console.log(err);
      dispatch(setfetchUserError(err))
     
      

    }
    // } while (pageToken); 

    let res = ExcludeUsers(staffList, parsedData);
    return res; // Return the filtered list
  }
  async function SearchingUsers() {
    let pageToken = "";
    let staffList = [];
  
    do{
      try{

      
      const page = await gapi.client.directory.users.list({
        customer: "my_customer",
        projection: "full",
        orderBy: sortby,
        query:
          allletter === "ALL"
            ? `${sortby}:*`
            : `${sortby}:${allletter?.toLowerCase()}*`,
        maxResults: 499,
        pageToken: pageToken,
      });
  
      // Append the fetched users to staffList
      if (page.result.users) {
        staffList = [...staffList, ...page.result.users]; // Accumulate users
      }
  
      // Update the pageToken for the next iteration
      pageToken = page.result.nextPageToken;
    }
    catch(err){
      console.log(err)

    }
    } while (pageToken); 

    let res = ExcludeUsers(staffList, parsedData);
    return res; // Return the filtered list
  }
  
  function executeOncePerDay(callback) {
    const today = new Date().toDateString();

    const lastExecutionDate = localStorage.getItem("lastExecutionDate");

    if (lastExecutionDate !== today) {
      callback();

      localStorage.setItem("lastExecutionDate", today);
    }
  }

  async function blobCall() {
    
    // console.time('executionTime');
    updateSettingJson(SETTING_LIST, defaultSettingList,true);
    setshowProgress(true);
    let usersJson = await getSettingJson(USER_LIST);
    let settingJson = await getSettingJson(SETTING_LIST);
    setSett(settingJson)
    let imagesJson = await getSettingJson(IMAGES_LIST);
    console.log("ALLUSERS",usersJson);
    if(!settingJson || Object.keys(settingJson)<10){

      updateSettingJson(SETTING_LIST, defaultSettingList,true);
      // setAppSettings(defaultSettingList);
      dispatch(setAllSettings(defaultSettingList))
      settingJson=defaultSettingList;

      
    }
    if(!imagesJson){
      updateSettingJson(IMAGES_LIST, defaultImagesList);
      setImagesList(defaultImagesList);
      imagesJson=defaultImagesList;
    }
     if(!usersJson){
      updateSettingJson(
        USER_LIST,
        encryptData(JSON.stringify(defaultUserList))
      );
      setUsersList(defaultUserList);
      usersJson=defaultUserList;
    }

    console.log("usersJson.........", usersJson);

    if (imagesJson && Object?.keys(imagesJson)?.length) {
      setImagesList(imagesJson);
    }

    if (usersJson && Object?.keys(usersJson)?.length) {
      setUsersList(usersJson);
    }

    if (settingJson && Object?.keys(settingJson)?.length) {
      // setAppSettings(settingJson);
      // setSettingList(settingJson);
      dispatch(setAllSettings(settingJson))
      
      setShowHideSettings({GroupsOn:settingJson?.GroupsOn??false})
    }
    if (settingJson?.sortby) {
      sortby =
      settingJson?.sortby == "FirstName"
          ? "givenName"
          : settingJson?.sortby == "familyName"
          ? "familyName"
          : "givenName";
    }

    if ( settingJson && Object?.keys(settingJson)?.length) {
      parsedData = settingJson;
      setSettingData(settingJson);
      let data = {
        ShowOrgChart: parsedData?.ShowOrgChart,
        IsExportToCsv: parsedData?.IsExportToCsv,
        showBirthAniv: parsedData?.showBirthAniv,
        showDashboard: parsedData?.showDashboard,
        AllowUserToPrintPDF: parsedData?.AllowUserToPrintPDF,
      };
      setCommandBarItems(data);
      setAdditionalManagers(settingJson?.AdditionalManagerData);
      setAssistant(settingJson.AssistantData);

      const admins = settingJson?.RolesAndPermisstions?.map((x) => x?.email);
      setAdmins(admins ?? []);

      const hideManagerEmail = settingJson?.UsersWithHiddenManager?.map(
        (x) => x.email
      );
      const hidePhoneEmail = settingJson?.UserWithHiddenMobileNumber?.map(
        (x) => x?.email
      );

      setUsersWithHiddenManager(hideManagerEmail);
      setUsersWithHiddenPhoneNo(hidePhoneEmail);

      setRecordsToload(parsedData?.records_to_load);
      if (parsedData?.Favicon) {
        changeFavicon(imagesJson?.FavIconUrl);
      } else {
        removeFavicon();
      }

      if (parsedData.ListViewPrope) {
        const updatedCheckboxes = { ...checkboxesCheckedListView };
        for (const key in parsedData.ListViewPrope) {
          if (key in updatedCheckboxes) {
            updatedCheckboxes[key] = parsedData?.ListViewPrope[key];
          }
        }
        changeCheckboxesCheckedListView(updatedCheckboxes);
      } else {
        changeCheckboxesCheckedListView(checkboxesCheckedListView);
      }
      if (parsedData.ProfileViewPrope) {
        const updatedCheckboxesProfile = { ...checkboxesChecked };
        for (const key in parsedData?.ProfileViewPrope) {
          if (key in updatedCheckboxesProfile) {
            updatedCheckboxesProfile[key] = parsedData.ProfileViewPrope[key];
          }
        }
        changeCheckboxesChecked(updatedCheckboxesProfile);
      } else {
        changeCheckboxesChecked(checkboxesCheckedListView);
      }
     
      if (parsedData.GridViewPrope) {
        const updatedPeople = parsedData?.GridViewPrope?.map((person) => {
          const updatedPerson = parsedData["GridViewPrope"].find(
            (data) => data.id === person.id
          );
          if (updatedPerson) {
            return {
              ...person,
              checkbox: updatedPerson?.checkbox,
            };
          }
          return person;
        });

        changeUserGridView(updatedPeople);
      } else {
        changeUserGridView(userGridView);
      }
      setDefaultView();
      _gridWidth = parsedData?.gridWidth;

      if (parsedData?.ExcludeByDepartment) {
        changeExcludeByDepartment(parsedData.ExcludeByDepartment);
      }
      if (view == "Grid") {
        setisGrid(true);
        setisList(false);
        setisTile(false);
      } else if (view == "List") {
        setisGrid(false);
        setisList(true);
        setisTile(false);
      } else if (view == "Tile") {
        setisGrid(false);
        setisList(false);
        setisTile(true);
      } else if (view == "NonM365") {
        setisGrid(false);
        setisList(false);
        setisTile(false);
        setImpotedUser(true);
      }
      
      let availableViews = filterTrueValues(parsedData?.hideShowViews ?? {});
      let selectingVisibleView = getFirstAvailableView(availableViews);
      if (
        window.innerWidth < 768 
        
      ) {
        // setView(parsedData?.defaultMobileView ?? "Grid");
        dispatch(fetchSettings());
        if(parsedData?.defaultMobileView =="Grid"){
          dispatch(setViewSetting("isGrid"))
        }else if(parsedData?.defaultMobileView =="List"){
          dispatch(setViewSetting("isList"))
        }else if(parsedData?.defaultMobileView =="Tile"){
          dispatch(setViewSetting("isTile"))
        }else{
          dispatch(setViewSetting("isNonM365"))
        }
       
      }

      if (parsedData.sortby) {
        changeVariable(parsedData.sortby);
      }
    }
    _grpsOn = parsedData.GroupsOn;
    if (_grpsOn) {
      getAllUsersGrpInOrg().then((groups) => {
        groups?.map((group) => {
          _arraygrpcf1.push(group);
        });
      });
    }
    // console.timeEnd('executionTime');
    // await listFiles();
    fetchUsers();
  }
  function setDefaultView(parsedData){
    if(parsedData?.DefaultView=="Grid"){
      dispatch(setViewSetting("isGrid"))
    }else if(parsedData?.DefaultView=="List"){
      dispatch(setViewSetting("isList"))
    }else if(parsedData?.DefaultView=="Tile"){
      dispatch(setViewSetting("isTile"))
    }else if(parsedData?.DefaultView=="NonM365"){
      dispatch(setViewSetting("isNonM365"))
    }else{

      dispatch(setViewSetting("isGrid"))
    }
  }
//   async function blobCall() {
    
//     setshowProgress(true);
//     let usersJson = await getSettingJson(USER_LIST);
//     parsedData= await getSettingJson(SETTING_LIST);
//     setSett(parsedData)
//     let imagesJson = await getSettingJson(IMAGES_LIST);
//     console.log("ALLUSERS",usersJson);
    


//     // setting default values if list is empty
//     if(!parsedData || Object?.keys(parsedData)?.length<10){

//       updateSettingJson(SETTING_LIST, defaultSettingList,true);
//       dispatch(setAllSettings({appSettings:defaultSettingList}))
  
//       parsedData=defaultSettingList;

      
//     }
//     if(!imagesJson){
//       updateSettingJson(IMAGES_LIST, defaultImagesList);
//       setImagesList(defaultImagesList);
//       imagesJson=defaultImagesList;
//     }
//      if(!usersJson){
//       updateSettingJson(
//         USER_LIST,
//         encryptData(JSON.stringify(defaultUserList))
//       );
//       setUsersList(defaultUserList);
//       usersJson=defaultUserList;
//     }
// //------------------------------------------------------

// // setting values if list is not empty

//     if (imagesJson && Object?.keys(imagesJson)?.length) {
//       setImagesList(imagesJson);
//     }

//     if (usersJson && Object?.keys(usersJson)?.length) {
//       setUsersList(usersJson);
//     }
  
  
    
  
    
   

//     if ( parsedData && Object?.keys(parsedData)?.length) {
//       dispatch(setAllSettings({appSettings:parsedData}))
//       setShowHideSettings({GroupsOn:parsedData?.GroupsOn??false})
      
//       setSettingData(parsedData);
//       let data = {
//         ShowOrgChart: parsedData?.ShowOrgChart,
//         IsExportToCsv: parsedData?.IsExportToCsv,
//         showBirthAniv: parsedData?.showBirthAniv,
//         showDashboard: parsedData?.showDashboard,
//         AllowUserToPrintPDF: parsedData?.AllowUserToPrintPDF,
//       };
//       setCommandBarItems(data);
//       setAdditionalManagers(parsedData?.AdditionalManagerData);
//       setAssistant(parsedData?.AssistantData);

//       const admins = parsedData?.RolesAndPermisstions?.map((x) => x?.email);
//       setAdmins(admins ?? []);

//       const hideManagerEmail = parsedData?.UsersWithHiddenManager?.map(
//         (x) => x.email
//       );
//       const hidePhoneEmail = parsedData?.UserWithHiddenMobileNumber?.map(
//         (x) => x?.email
//       );

//       setUsersWithHiddenManager(hideManagerEmail);
//       setUsersWithHiddenPhoneNo(hidePhoneEmail);

//       setRecordsToload(parsedData?.records_to_load);
//       if (parsedData?.Favicon) {
//         changeFavicon(imagesJson?.FavIconUrl);
//       } else {
//         removeFavicon();
//       }

//       if (parsedData.ListViewPrope) {
//         const updatedCheckboxes = { ...checkboxesCheckedListView };
//         for (const key in parsedData.ListViewPrope) {
//           if (key in updatedCheckboxes) {
//             updatedCheckboxes[key] = parsedData?.ListViewPrope[key];
//           }
//         }
//         changeCheckboxesCheckedListView(updatedCheckboxes);
//       } else {
//         changeCheckboxesCheckedListView(checkboxesCheckedListView);
//       }
//       if (parsedData.ProfileViewPrope) {
//         const updatedCheckboxesProfile = { ...checkboxesChecked };
//         for (const key in parsedData?.ProfileViewPrope) {
//           if (key in updatedCheckboxesProfile) {
//             updatedCheckboxesProfile[key] = parsedData.ProfileViewPrope[key];
//           }
//         }
//         changeCheckboxesChecked(updatedCheckboxesProfile);
//       } else {
//         changeCheckboxesChecked(checkboxesCheckedListView);
//       }
     
//       if (parsedData.GridViewPrope) {
//         const updatedPeople = parsedData?.GridViewPrope?.map((person) => {
//           const updatedPerson = parsedData["GridViewPrope"].find(
//             (data) => data.id === person.id
//           );
//           if (updatedPerson) {
//             return {
//               ...person,
//               checkbox: updatedPerson?.checkbox,
//             };
//           }
//           return person;
//         });

//         changeUserGridView(updatedPeople);
//       } else {
//         changeUserGridView(userGridView);
//       }

//       _gridWidth = parsedData?.gridWidth;
//       if (parsedData?.sortby) {
//         sortby =
//         parsedData?.sortby == "FirstName"
//             ? "givenName"
//             : parsedData?.sortby == "familyName"
//             ? "familyName"
//             : "givenName";
//       }
//       if (parsedData?.ExcludeByDepartment) {
//         changeExcludeByDepartment(parsedData.ExcludeByDepartment);
//       }
//       if (
//         window.innerWidth < 768 
        
//       ) {
      
//         if(parsedData?.defaultMobileView =="Grid"){
//           dispatch(setViewSetting("isGrid"))
//         }else if(parsedData?.defaultMobileView =="List"){
//           dispatch(setViewSetting("isList"))
//         }else if(parsedData?.defaultMobileView =="Tile"){
//           dispatch(setViewSetting("isTile"))
//         }else{
//           dispatch(setViewSetting("isNonM365"))
//         }
       
//       }

//       if (parsedData?.sortby) {
//         changeVariable(parsedData.sortby);
//       }
//       _grpsOn = parsedData.GroupsOn;
//       if (_grpsOn) {
//         getAllUsersGrpInOrg().then((groups) => {
//           groups?.map((group) => {
//             _arraygrpcf1.push(group);
//           });
//         });
//       }
//       await listFiles();
//     }
   
//     // console.timeEnd('executionTime');
//   }
 console.log("redux",parsedData)
  async function getAllUsersGrpInOrg() {
    let page = "";
    let pageToken = "";
    let staffList = [];

    do {
      page = await gapi.client.directory.groups.list({
        //userKey: userEmail,
        customer: "my_customer",
        maxResults: 100,
        pageToken: pageToken,
      });

      staffList = staffList.concat(page.result.groups);

      pageToken = page.nextPageToken;
    } while (pageToken);

    return staffList;
  }

  const filterByLetter = async(Letter) => {
    setshowProgress(true);
    allletter = Letter;
    fetchUsers('');
    // let settingData=await getSettingJson(SETTING_LIST);
    // parsedData=settingData;
    // dispatch(setAllSettings({appSettings:settingData}))
  //   let ans = NonMutableFilter?.filter((item) => {
  //     if(allletter=="ALL" ||allletter=="All"){
  //       return item;
  //     }
  //     return item.name?.toLowerCase().startsWith(allletter.toLowerCase());
  // });
  
  //   //  alert(ans && ans[0]?.email)
  //   // listFiles();
  //   setUserArray([...ans]); 
  //   // setSettings(false);
  //   setTimeout(() => {
      
  //     setshowProgress(false);
  //   }, 500);
  };

  async function getAllUsersInOrg1(value, mode) {
    let page = "";
    let pageToken = "";
    let staffList = [];

    do {
      page = await gapi.client.directory.users.list({
        customer: "my_customer",
        projection: "full",
        orderBy: sortby,
        query:
          value === undefined
            ? allletter == "ALL"
              ? "" + sortby + ":*"
              : "" + sortby + ':"' + allletter + '"*'
            : mode === "name"
            ? // ? "" + sortby + ':"' + value + '"*'
              "" + sortby + ':"' + value + ""
            : mode === "title" || mode === "titles"
            ? "orgTitle:" + value + ""
            : mode === "departments"
            ? "orgDepartment:" + value + ""
            : mode == "custom"
            ? `OtherFields.CF1:"${value}"`
            : "",
        maxResults:value==""?parsedData?.records_to_load??25: 499,
        pageToken: pageToken,
      });

      staffList = staffList.concat(page.result.users);

      pageToken = page.nextPageToken;
    } while (pageToken);
    let res = ExcludeUsers(staffList, parsedData);
    return res;
  }

  function getUserOrgWPItem(user) {
    if (user.hasOwnProperty("phones")) {
      const primaryOrg = user?.phones?.filter((x) => x.type === "work")[0].value;

      return primaryOrg;
    } else {
      return "";
    }
  }

  function getUserOrgMBItem(user) {
    if (user.hasOwnProperty("phones")) {
      const obj = user?.phones?.find((x) => x.type === "mobile");
      if (obj === undefined) {
        return "";
      } else {
        return obj.value;
      }
    } else {
      return "";
    }
  }

  function getUserOrgMGItem(user) {
    if (user.hasOwnProperty("relations")) {
      const primaryOrg = user?.relations?.filter((x) => x.type === "manager")[0]
        .value;

      return primaryOrg;
    } else {
      return "";
    }
  }

  function getUserOrgEIDItem(user) {
    if (user.hasOwnProperty("externalIds")) {
      if (user?.externalIds?.length < 1) {
        return "";
      }
      const primaryOrg = user?.externalIds?.filter(
        (x) => x.type === "organization"
      )[0].value;

      return primaryOrg;
    } else {
      return "";
    }
  }

  function getUserOrgADDItem(user) {
    if (user.hasOwnProperty("addresses")) {
      if (user?.addresses?.length < 1) {
        return "";
      }
      const primaryOrg = user?.addresses?.filter((x) => x.type === "work")[0]
        .formatted;
      return primaryOrg;
    } else {
      return "";
    }
  }

  function getUserOrgLOCItem(user) {
    if (user.hasOwnProperty("locations")) {
      var bid = user.locations[0].hasOwnProperty("buildingId")
        ? user.locations[0].buildingId
        : "";
      var floorname = user?.locations[0]?.hasOwnProperty("floorName")
        ? user?.locations[0]?.floorName
        : "";
      var floorsection = user?.locations[0]?.hasOwnProperty("floorSection")
        ? user?.locations[0]?.floorSection
        : "";
      return bid + " " + floorname + " " + floorsection;
    } else {
      return "";
    }
  }

  const listFiles = async () => {
    parsedData= await getSettingJson(SETTING_LIST);
    dispatch(setAllSettings({appSettings:parsedData}))
    if(!parsedData || Object.keys(parsedData)?.length<10){
     

        updateSettingJson(SETTING_LIST, defaultSettingList,true);
        // setAppSettings(defaultSettingList);
        dispatch(setAllSettings(defaultSettingList))
        parsedData=defaultSettingList;
  
        
      
    }
    let additionManagerData = [];
    let currentUser = getCurrentUser()?.cu;
    let allData = [];
    let AssistantData = [];
    const usersJson = await getSettingJson(USER_LIST);
    setUsersList(usersJson);
    let customFunctionUser = [];
    let departments=[];
    let JobTitles=[];

    getAllUsersInOrg().then((data) => {
      console.log("username",data)
      setUnFormatedUserData(data);
      changeUserData(data);
      
      if (data === undefined) {
        setUserArray([]);
      } else {
        let staffMember = data?.map((user) => {
          // console.log("custom fileds->",user.customSchemas)
          var arr123 = {};
          if(user?.primaryEmail==currentUser && user?.isAdmin){

            dispatch(setIsCurrentUserAdmin(true));
          }
          var man = {};
          let DOB=checkCustomSchemasForKey(user?.customSchemas,['dob', 'birthday','DOB'])
          let DOJ=checkCustomSchemasForKey(user?.customSchemas,['doj', 'dateofjoining', 'joinDate','DOJ'])
          let customUrl=checkCustomSchemasForKey(user?.customSchemas,['customUrl'])
          departments.push(user.organizations?.[0]?.department)
          JobTitles.push(user.organizations?.[0]?.title)
          if (user.hasOwnProperty("customSchemas")) {
            const cfield = user?.customSchemas;

            Object.keys(cfield)?.map((key) => {
              var exchangefld = cfield[key];

              for (var i in exchangefld) {
                arr123[i] =
                  exchangefld[i] instanceof Array
                    ? exchangefld[i].reduce((a, b) => {
                        return (
                          (a?.value?.toString() || a?.toString()) +
                          " !! " +
                          b?.value
                        );
                      })
                    : exchangefld[i];
              }

              return [key];
            });
          }

          if (user?.name?.givenName != null) {
            var names = user?.name?.givenName.split(" "),
              initials = names[0]?.substring(0, 1)?.toUpperCase();
            if (names.length > 1) {
              initials += names[names.length - 1]?.substring(0, 1)?.toUpperCase();
            }
          }

          var _allex, _allexu;
          if (_excspecuser != null && _excspecuser != undefined) {
            _allexu =
              _excspecuser.filter(
                (x) =>
                  user?.name?.fullName?.toLowerCase()?.indexOf(x.toLowerCase()) >
                  -1
              )?.length > 0;
          } else {
            _allexu = null;
          }
          _allex = _allexu;
          var _allexuHide =
            arrmanager?.filter(
              (x) =>
                user?.primaryEmail?.toLowerCase()?.indexOf(x?.toLowerCase()) > -1
            ).length > 0;

          const y = parsedData?.AdditionalManagerData?.map((item) => {
            if (user?.customSchemas?.OtherFields[item.email]) {
              additionManagerData.push(
                user?.customSchemas?.OtherFields[item.email]
              );
            }
          });
          const assistResult = parsedData?.AssistantData?.map((item) => {
            if (user?.customSchemas?.OtherFields[item.email]) {
              AssistantData.push(user?.customSchemas?.OtherFields[item.email]);
            }
          });

          const xx = {
            ...arr123,
            firstName: user?.name?.givenName,
            lastName: user?.name?.familyName,
            name: user?.name?.fullName,
            DOB:DOB,
            DOJ:DOJ,
            customUrl:customUrl,
            email: user.primaryEmail,
            id: user?.id,
            job: user.hasOwnProperty("organizations")
              ? user?.organizations[0].hasOwnProperty("title")
                ? user?.organizations[0].title
                : ""
              : "",
            initials: initials,
            department: user?.hasOwnProperty("organizations")
              ? user?.organizations[0]?.hasOwnProperty("department")
                ? user?.organizations[0]?.department
                : ""
              : "",

            workphone: getUserOrgWPItem(user),
            mobile: _allex ? "" : getUserOrgMBItem(user),
            manager: _allexuHide ? "" : getUserOrgMGItem(user),
            employeeid: getUserOrgEIDItem(user),
            costcenter: user.hasOwnProperty("organizations")
              ? user.organizations[0].hasOwnProperty("costCenter")
                ? user?.organizations[0]?.costCenter
                : ""
              : "",
            buildingid: user.hasOwnProperty("locations")
              ? user.locations[0].hasOwnProperty("buildingId")
                ? user?.locations[0]?.buildingId
                : ""
              : "",
            floorname: user.hasOwnProperty("locations")
              ? user?.locations[0].hasOwnProperty("floorName")
                ? user?.locations[0]?.floorName
                : ""
              : "",
            floorsection: user.hasOwnProperty("locations")
              ? user?.locations[0].hasOwnProperty("floorSection")
                ? user?.locations[0]?.floorSection
                : ""
              : "",
            location: getUserOrgLOCItem(user),
            address: getUserOrgADDItem(user),
            image: user.hasOwnProperty("thumbnailPhotoUrl")
              ? user?.thumbnailPhotoUrl
              : DefaultImage,
            AdditionalManager: additionManagerData,
            AssistantData: AssistantData,
            isAdmin: user?.isAdmin,
          };
          additionManagerData = [];
          AssistantData = [];
          let currentUserDetails=getCurrentUser();
          
          if(xx?.isAdmin){
            let prev=parsedData?.RolesAndPermisstions||[];
            updateSettingJson(SETTING_LIST,{RolesAndPermisstions:[...prev,{name:xx.name, email:xx.email}]},true)
          }
         
          return xx;
        });
       
        let previousDeptsFormList = parsedData?.FilterAttribute?.departments
        ?.map((item) => item.Department)
        .filter((item) => typeof item === 'string' && item !== '' && item !== undefined && typeof item !== 'boolean')??[];
      
         let allDepts = [...new Set([...previousDeptsFormList, ...departments])];
         let dpts=allDepts?.map((item)=>{
          return {Department:item,Active:true}
         });
        let previousJobTitlesFormList=parsedData?.FilterAttribute?.jobTitles?.map((item)=>item.JobTitle)??[];
         let allJobtitles = [...new Set([...previousJobTitlesFormList, ...JobTitles])];
         let jts=allJobtitles?.map((item)=>{
          return {JobTitle:item,Active:true}
         });
        updateSettingJson(SETTING_LIST,{FilterAttribute:{...parsedData?.FilterAttribute,departments:dpts,jobTitles:jts}},true)
        dispatch(setAllDepartments(allDepts))
        dispatch(setAllJobTitle(allJobtitles))
   
          
        let admin = isUserAdminCheck(staffMember, currentUser);
        console.log("staffMember =>", staffMember);
        setCurrentUserAdmin(admin);
        // setAppSettings({ ...parsedData, isCurrentUserAdmin: admin });
        // dispatch(setAllSettings({isCurrentUserAdmin: admin }))

        changeUserArray(staffMember);
        setAllUsers(staffMember);

        const domainOptions = staffMember?.map((user) => ({
          value: user?.email.split("@")[1],
          label: user?.email.split("@")[1],
        }));

        const userEmails = staffMember?.map((user) => ({
          key: user?.email,
          text: user?.email,
        }));

        const userDeps = staffMember?.map((x) => ({
          value: x?.department,
          label: x?.department,
        }));
        let settingListDeps = parsedData?.FilterAttribute?.departments
          ?.filter((x) => {
            if (x?.Active) {
              return x;
            }
          })
          .map((x) => ({ label: x?.Department, value: x?.Department }));

        const userJobTitles = staffMember?.map((x) => ({
          value: x.job,
          label: x.job,
        }));
        const settingListJobs = parsedData?.FilterAttribute?.jobTitles
          ?.filter((x) => x.Active == true)?.map((x) => ({ value: x.JobTitle, label: x.JobTitle }));

        const SLJ = settingListJobs ?? [];
        const SLD = settingListDeps ?? [];

        const uniqueJobs = removeDuplicatesFromObject(
          [...userJobTitles, ...SLJ],
          "value"
        );
        const uniqueDep = removeDuplicatesFromObject(
          [...userDeps, ...SLD],
          "value"
        );

        setJobDropDown(uniqueJobs);
        if (view == "NonM365") {
        } else {
          setDepDropDown(uniqueDep);
        }

        const filterAttribute = parsedData?.FilterAttribute;
        const allFADepartments = filterAttribute?.departments;
        const allFAJobTitles = filterAttribute?.jobTitles;
        const allFALocations = filterAttribute?.locations;

        setDepartments(allFADepartments);
        setJobTitles(allFAJobTitles);
        setLocations(allFALocations);
        setEmails(userEmails);

        setExcludeOptionsForDomain(
          removeDuplicatesFromObject(domainOptions, "value")
        );
        if (
          // usersJson?.Users &&
          parsedData?.SyncUserInfoFrom == "Google & Imported User"
        ) {
          customFunctionUser = customFunctionFilter(staffMember, parsedData);
          let usersData = ExcludeExternalUsers(customFunctionUser, parsedData);
          allData = [...usersData];
          //     setUserArray(usersData);
          setUserArray(usersData);
          dispatch(addM365User(allData))
          NonMutableFilter=[...allData]
          let decryptedData = usersJson?.Users||[];
          NonMUsers = [...decryptedData];
          dispatch(setAllNonDepartments(NonMUsers?.map(item => item?.department).filter(Boolean)));
          dispatch(setAllNonJobTitle(NonMUsers?.map(item => item?.job).filter(Boolean)));
          setNonM365(decryptedData);

          executeOncePerDay(() => {
            let dataForCharts = usersJson?.AllUsersData?.GoogleUsers?.length
              ? usersJson?.AllUsersData?.GoogleUsers
              : usersData;
            // this code is for when we update some fileds from dashboard settings of any user, so spreading that dashboard fields into data
            let GoogleUsers = dataForCharts.map((item) => {
              return usersData?.map((user) => {
                if (user?.email == item?.email) {
                  return {
                    ...user,
                    ...item,
                  };
                }
              });
            });
            GoogleUsers = GoogleUsers?.flat()?.filter((item) => {
              return item != null;
            });

            let data = {
              GoogleUsers: GoogleUsers ?? [],
              NonGoogleUser: decryptedData ?? [],
            };
            dispatch(setAllSettings({AllUsersData: data }))
            updateSettingJson(
              USER_LIST,
              encryptData(JSON.stringify({ ...usersJson, AllUsersData: data }))
            );
            setUsersList({ ...usersJson, AllUsersData: data });
          });

                 } else if (
          usersJson?.Users &&
          parsedData?.SyncUserInfoFrom?.toLowerCase() == "importeduser"
        ) {
          // setImpotedUser(true);
          let decryptedData = usersJson?.Users;
          setImpotedUser(true);
          customFunctionUser = customFunctionFilter(decryptedData, parsedData);

          decryptedData = customFunctionUser;

          if (Array.isArray(customFunctionUser)) {
            let usersData = [...customFunctionUser];
            usersData = ExcludeExternalUsers(usersData, parsedData);
            dispatch(setAllNonDepartments(usersData?.map(item => item?.department).filter(Boolean)));
            dispatch(setAllNonJobTitle(usersData?.map(item => item?.job).filter(Boolean)));
            setUserArray(usersData);
            dispatch(addM365User(usersData))
            allData = [...usersData];
            executeOncePerDay(() => {
              let data = {
                ...usersJson,
                AllUsersData: {
                  GoogleUsers: staffMember ?? [],
                  NonGoogleUser: decryptedData ?? [],
                },
              };

              updateSettingJson(
                USER_LIST,
                encryptData(
                  JSON.stringify({
                    ...usersJson,
                    AllUsersData: {
                      GoogleUsers: staffMember ?? [],
                      NonGoogleUser: decryptedData ?? [],
                    },
                  })
                )
              );
              setUsersList(data);
            });
          }
        } else {
          setUserArray(staffMember);
          allData = [...staffMember];
          NonMutableFilter=[...staffMember]
          let data = {
            ...usersJson,
            AllUsersData: {
              GoogleUsers: staffMember,
              NonGoogleUser: usersJson?.NonGoogleUser || [],
            },
          };

          // let data = { ...usersList, GoogleUsers: staffMember };
          updateSettingJson(
            USER_LIST,
            encryptData(
              JSON.stringify({
                ...usersJson,
                AllUsersData: {
                  GoogleUsers: staffMember,
                  NonGoogleUser: usersJson?.NonGoogleUser || [],
                },
              })
            )
          );
          setUsersList(data);
        }
        setFormatedUserData(staffMember);

        
      }
      if (parsedData?.RestrictedAccess?.length) {
        const cu = getCurrentUser().cu;
        const user = allData?.find((x) => x.email === cu);

        const currentUserData = {
          department: user.department ?? "",
          location: user.location ?? "",
          job: user?.job ?? "",
          email: user?.email ?? "",
        };
        const restrictedData = getRestrictedAccess(
          parsedData?.RestrictedAccess || [],
          currentUserData
        );

        const restrictedPropUsers = applyRestrictions(
          customFunctionUser,
          restrictedData
        );

        setUserArray(restrictedPropUsers);
      }
      // setUserArray(customFunctionUser);

      if (parsedData?.GroupsOn) {
        showGroups();
      }
      // setloader(false);
      setshowProgress(false);
      
    });
  };

  const checkCustomSchemasForKey = (schemas, keys) => {
    for (const schemaKey in schemas) {
      const schema = schemas[schemaKey];
      for (const key in schema) {
        // Check if the key matches any of the possible keys like DOB or DOJ
        for (const searchKey of keys) {
          if (key.includes(searchKey)) {
            return schema[key];
          }
        }
      }
    }
    return 'Not found in custom schemas';
  };

  const clearFilters = () => {
    listFiles();
  };

  const optimization = async (value, mode) => {
    
    if (parsedData?.SyncUserInfoFrom?.toLowerCase() == "importeduser") {
      setshowProgress(true);
      let d = await getSettingJson(USER_LIST);
      let nongoogleUsers = d?.Users;

      if (value == "") {
        setUserArray(nongoogleUsers);
      } else {
        if (mode == "free search") {
          if (value == "") {
            setUserArray(nongoogleUsers);
          } else {
            let data = nongoogleUsers;
            let results = [];

            results = data?.filter((user) =>
              [
                "name",
                "firstName",
                "lastName",
                "workphone",
                "job",
                "location",
                "department",
                "email",
                "CF1",
              ].some((key) => {
                let res =
                  typeof user[key] === "string" &&
                  user[key]?.toLowerCase()?.includes(value?.toLowerCase());
                return res;
              })
            );

            setUserArray(results);
          }
        } else if (mode == "departments") {
          // alert("dept")
          let result = nongoogleUsers?.filter((item) => {
            return item?.department
              ?.toLowerCase()
              .includes(value?.toLowerCase());
          });

          setUserArray(result);
        } else if (mode == "title" || mode == "titles") {
          // alert("titles")
          let res = nongoogleUsers?.filter((item) => {
            return item?.job?.toLowerCase()?.includes(value?.toLowerCase());
          });
          setUserArray(res);
        } else if (mode == "name") {
          let res = nongoogleUsers?.filter((item) => {
            console.log("ittem", item);
            return item?.name?.toLowerCase()?.includes(value?.toLowerCase());
          });

          setUserArray(res);
        }
      }

      setshowProgress(false);
    } else if (
      parsedData?.SyncUserInfoFrom == "Google & Imported User" &&
      isImportedUser
    ) {
      setshowProgress(true);
      let d = await getSettingJson(USER_LIST);
      let nongoogleUsers = d?.Users;

      if (value == "") {
        setNonM365(nongoogleUsers);
      } else {
        if (mode == "free search") {
          if (value == "") {
            setNonM365(nongoogleUsers);
          } else {
            let data = nongoogleUsers;
            let results = [];

            results = data?.filter((user) =>
              [
                "name",
                "firstName",
                "lastName",
                "workphone",
                "job",
                "location",
                "department",
                "email",
                "CF1",
              ]?.some((key) => {
                let res =
                  typeof user[key] === "string" &&
                  user[key]?.toLowerCase()?.includes(value?.toLowerCase());
                return res;
              })
            );

            setNonM365(results);
          }
        } else if (mode == "departments") {
          // alert("dept")
          let result = nongoogleUsers?.filter((item) => {
            return item?.department
              ?.toLowerCase()
              .includes(value?.toLowerCase());
          });

          setNonM365(result);
        } else if (mode == "title" || mode == "titles") {
          // alert("titles")
          let res = nongoogleUsers.filter((item) => {
            return item?.job?.toLowerCase()?.includes(value?.toLowerCase());
          });
          setNonM365(res);
        } else if (mode == "name") {
          let res = nongoogleUsers.filter((item) => {
            return item?.name?.toLowerCase()?.includes(value?.toLowerCase());
          });

          setNonM365(res);
        }
      }

      setshowProgress(false);
    } else {
      setshowProgress(true);
      gapi.client.load("admin", "directory_v1", () => {});

    getAllUsersInOrg1(value, mode).then((data) => {
        if (typeof data[0] === "undefined") {
          setUserArray([]);
        } else {
          let staffMember = data?.map((user) => {
            var arr123 = {};
            var man = {};
            var mgremail = getUserOrgMGItem(user);
            var dataofmang = data?.filter(
              (person) => person.primaryEmail == mgremail
            );
            if (dataofmang.length > 0) {
              man = {
                firstName: dataofmang[0].name?.givenName,
                lastName: dataofmang[0].name?.familyName,
                name: dataofmang[0].name?.fullName,
                email: dataofmang[0].primaryEmail,
                id: dataofmang[0]?.id,
                job: dataofmang[0]?.hasOwnProperty("organizations")
                  ? dataofmang[0]?.organizations[0]?.hasOwnProperty("title")
                    ? dataofmang[0]?.organizations[0]?.title
                    : ""
                  : "",
                //initials: initials,
                department: dataofmang[0].hasOwnProperty("organizations")
                  ? dataofmang[0]?.organizations[0].hasOwnProperty("department")
                    ? dataofmang[0]?.organizations[0]?.department
                    : ""
                  : "",
                image: dataofmang[0].hasOwnProperty("thumbnailPhotoUrl")
                  ? dataofmang[0]?.thumbnailPhotoUrl
                  : DefaultImage,
              };
            }
            if (user.hasOwnProperty("customSchemas")) {
              const cfield = user?.customSchemas;

              Object.keys(cfield)?.map((key) => {
                var exchangefld = cfield[key];

                for (var i in exchangefld) {
                  arr123[i] =
                    exchangefld[i] instanceof Array
                      ? exchangefld[i]?.reduce((a, b) => {
                          return (
                            (a.value?.toString() || a?.toString()) +
                            " !! " +
                            b.value
                          );
                        })
                      : exchangefld[i];
                }

                return [key];
              });
            }
            if (user.name?.givenName != null) {
              var names = user?.name?.givenName.split(" "),
                initials = names[0]?.substring(0, 1)?.toUpperCase();
              if (names.length > 1) {
                initials += names[names.length - 1]
                  ?.substring(0, 1)
                  ?.toUpperCase();
              }
            }
            var _allex, _allexu;
            if (_excspecuser != null && _excspecuser != undefined) {
              _allexu =
                _excspecuser.filter(
                  (x) =>
                    user?.name?.fullName
                      ?.toLowerCase()
                      .indexOf(x.toLowerCase()) > -1
                ).length > 0;
            } else {
              _allexu = null;
            }
            _allex = _allexu;
            var _allexuHide =
              arrmanager.filter(
                (x) =>
                  user.primaryEmail.toLowerCase().indexOf(x.toLowerCase()) > -1
              ).length > 0;
            return {
              ...arr123,
              firstName: user?.name?.givenName,
              lastName: user?.name?.familyName,
              name: user?.name?.fullName,
              email: user?.primaryEmail,
              id: user?.id,
              job: user.hasOwnProperty("organizations")
                ? user.organizations[0].hasOwnProperty("title")
                  ? user.organizations[0]?.title
                  : ""
                : "",
              initials: initials,
              department: user.hasOwnProperty("organizations")
                ? user.organizations[0].hasOwnProperty("department")
                  ? user.organizations[0].department
                  : ""
                : "",
              workphone: getUserOrgWPItem(user),
              mobile: _allex ? "" : getUserOrgMBItem(user),
              manager: _allexuHide ? "" : getUserOrgMGItem(user),
              employeeid: getUserOrgEIDItem(user),

              costcenter: user.hasOwnProperty("organizations")
                ? user.organizations[0].hasOwnProperty("costCenter")
                  ? user.organizations[0].costCenter
                  : ""
                : "",
              buildingid: user.hasOwnProperty("locations")
                ? user.locations[0].hasOwnProperty("buildingId")
                  ? user.locations[0].buildingId
                  : ""
                : "",
              floorname: user.hasOwnProperty("locations")
                ? user.locations[0].hasOwnProperty("floorName")
                  ? user.locations[0].floorName
                  : ""
                : "",
              floorsection: user.hasOwnProperty("locations")
                ? user.locations[0].hasOwnProperty("floorSection")
                  ? user.locations[0].floorSection
                  : ""
                : "",
              location: getUserOrgLOCItem(user),
              address: getUserOrgADDItem(user),
              image: user.hasOwnProperty("thumbnailPhotoUrl")
                ? user.thumbnailPhotoUrl
                : DefaultImage,
              managerprofilecard: man,
            };
          });

          if (mode == "free search") {
            if (value == "") {
              setUserArray(staffMember);
            } else {
              let data = staffMember;
              let results = [];

              results = data?.filter((user) =>
                [
                  "name",
                  "workphone",
                  "job",
                  "location",
                  "department",
                  "email",
                  "CF1",
                ].some(
                  (key) =>
                    typeof user[key] === "string" &&
                    user[key].toLowerCase().includes(value.toLowerCase())
                )
              );

              setUserArray(results);
            }
          } else {
            setUserArray(staffMember);
          }
        }

        // setloader(false);
        setshowProgress(false);
      });
    }
  };
  async function getUserInfo(userEmail) {
    let page = "";
    let staffList = [];
    page = await gapi.client.directory.users.get({
      userKey: userEmail,
      projection: "full",
      orderBy: "givenName",
    });
    staffList = staffList.concat(page.result);
    return staffList;
  }

  const filterByTaxonomySearchMultiGrp = (item) => {
    if (item == null) {
      item = [];
    } else {
      item = [item];
    }
    // setloader(true);
    setshowProgress(true);
    if (item.length > 1) {
      // setselectedGrp(item?.slice(1));
    } else {
      setselectedGrp(item);
    }
    if (item?.length > 0) {
      //arrayofgroup=[];
      let arrayofgroup = [];
      var uniquegrp = item?.length > 1 ? item?.slice(1) : item;
      getAllUsersGrpMInOrg(uniquegrp[0]?.key).then((data1) => {
        let grpcount = 0;
        data1.map((user) => {
          getUserInfo(user.email).then((getuser) => {
            if (getuser.length > 0) {
              grpcount++;
              var arr123 = {};
              var man = {};

              // getAllUserManInOrg(user.primaryEmail).then((data) => {
              //   console.log(data);

              // })
              if (getuser.hasOwnProperty("customSchemas")) {
                const cfield = getuser[0].customSchemas;

                Object.keys(cfield).map((key) => {
                  var exchangefld = cfield[key];

                  for (var i in exchangefld) {
                    arr123[i] =
                      exchangefld[i] instanceof Array
                        ? exchangefld[i].reduce((a, b) => {
                            return (
                              (a.value?.toString() || a?.toString()) +
                              " !! " +
                              b.value
                            );
                          })
                        : exchangefld[i];
                  }

                  return [key];
                });

                //console.log(arr123);
              }

              //console.log(getuser);
              if (getuser[0].name.givenName != null) {
                var names = getuser[0].name.givenName.split(" "),
                  initials = names[0].substring(0, 1).toUpperCase();
                if (names.length > 1) {
                  initials += names[names.length - 1]
                    .substring(0, 1)
                    .toUpperCase();
                }
              }

              arrayofgroup.push({
                firstName: getuser[0].name.givenName,
                lastName: getuser[0].name.familyName,
                initials: initials,
                name: getuser[0].name.fullName,
                email: getuser[0].primaryEmail,
                id: getuser[0].id,
                job: getuser[0].hasOwnProperty("organizations")
                  ? getuser[0].organizations[0].hasOwnProperty("title")
                    ? getuser[0].organizations[0].title
                    : ""
                  : "",
                //initials: initials,
                department: getuser[0].hasOwnProperty("organizations")
                  ? getuser[0].organizations[0].hasOwnProperty("department")
                    ? getuser[0].organizations[0].department
                    : ""
                  : "",
                image: getuser[0].hasOwnProperty("thumbnailPhotoUrl")
                  ? getuser[0].thumbnailPhotoUrl
                  : DefaultImage,
                workphone: getUserOrgWPItem(getuser[0]),
                mobile: getUserOrgMBItem(getuser[0]),
                manager: getUserOrgMGItem(getuser[0]),
                employeeid: getUserOrgEIDItem(getuser[0]),
                costcenter: getuser[0].hasOwnProperty("organizations")
                  ? getuser[0]?.organizations[0].hasOwnProperty("costCenter")
                    ? getuser[0]?.organizations[0]?.costCenter
                    : ""
                  : "",
                buildingid: getuser[0].hasOwnProperty("locations")
                  ? getuser[0]?.locations[0].hasOwnProperty("buildingId")
                    ? getuser[0]?.locations[0]?.buildingId
                    : ""
                  : "",
                floorname: getuser[0].hasOwnProperty("locations")
                  ? getuser[0].locations[0].hasOwnProperty("floorName")
                    ? getuser[0].locations[0].floorName
                    : ""
                  : "",
                floorsection: getuser[0].hasOwnProperty("locations")
                  ? getuser[0].locations[0].hasOwnProperty("floorSection")
                    ? getuser[0].locations[0].floorSection
                    : ""
                  : "",
                location: getUserOrgLOCItem(getuser[0]),
                address: getUserOrgADDItem(getuser[0]),
              });

              if (data1.length == grpcount) {
                changeUserArray(arrayofgroup);
                setUserArray(arrayofgroup);
                // setloader(false);
                setshowProgress(false);
              }
            }
          });
        });
      });
    } else {
      clearFilters();
    }
    /* */
  };
  
  async function getAllUsersGrpMInOrg(userEmail) {
    let page = "";
    let pageToken = "";
    let staffList = [];
    do {
      page = await gapi.client.directory.members.list({
        groupKey: userEmail,
        maxResults: 100,
        pageToken: pageToken,
      });

      staffList = staffList.concat(page.result.members);

      pageToken = page.nextPageToken;
    } while (pageToken);

    return staffList;
  }
  function handleDownloadCSV() {
    let data = UserArray.map((item) => {
      Object.keys(item).map((val) => {
        return { [val]: item.val };
      });
      return {
        Name: item?.name||"",
        DOB: item?.DOB||"",
        DOJ: item?.DOJ||"",
        About_Me: item?.About_Me||"",
        email: item?.email||"",
        job: item?.job||"",
        department: item?.department||"",
        workphone: item?.workphone||"",
        address: item?.address||"",
        manager: item?.manager||"",
        cf1: item?.CF1||"",
      };
    });
    downloadCSV(data);
    setDownloadCsvModal(false);
  }
  function showGroups() {
    let resultgrpCf1 = [];
    {
      _customgrps.map((item) => {
        resultgrpCf = _arraygrpcf1
          ? _arraygrpcf1.map((a) => ({
              key: a?.email,
              label: a?.name,
              value: a?.name,
            }))
          : [];
        resultgrpCf = resultgrpCf.filter((items) => {
          return items?.key != null;
        });

        resultgrpCf1 = [];
        const map = new Map();
        for (const item of resultgrpCf) {
          if (!map.has(item.key)) {
            map.set(item.key, true); // set any value to Map
            if (
              resultgrpCf1.filter(
                (x) =>
                  x.key?.trim()?.toLowerCase() === item?.key?.trim().toLowerCase()
              ).length == 0
            ) {
              resultgrpCf1.push({
                key: item?.key?.trim(),
                label: item?.label?.trim(),
                value: item?.value?.trim(),
              });
            }
          }
        }

        resultgrpCf1.sort((a, b) => {
          var textA = a?.value?.toUpperCase();
          var textB = b?.value?.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      });
    }
    setGroupsOptions(resultgrpCf1);
  }

  const filterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(
      ({ value }) =>
        value?.toLowerCase() &&
        value?.toLowerCase()?.indexOf(filter.toLowerCase()) > -1
    );
  };

  const { toPDF, targetRef } = usePDF({ filename: "GoolgeED.pdf" });
  const generatingPDF = () => {
   if(!invalidAccount){

     if (viewsSettings?.isGrid) {
       generatePDF("pdf-container");
      } else if (viewsSettings?.isList) {
          generatePDF("ViewListId");
      } else if (viewsSettings?.isTile) {
          generatePDF("tileView");
      } else if (viewsSettings?.isNonM365) {
          generatePDF("ViewListId");
      } else if (viewsSettings?.isTile) {
          generatePDF("nongoogleusers");
      }else{
        generatePDF("pdf-container");
      }
  
    }
  
};
  const fetchUsers = async(pageToken = '',SearchByLetter=null) => {
    if(pageToken){
    // parsedData= await getSettingJson(SETTING_LIST);
    // dispatch(setAllSettings({appSettings:parsedData}))
    // if(!parsedData || Object.keys(parsedData)?.length<10){
    
    //     updateSettingJson(SETTING_LIST, defaultSettingList,true);
    //     dispatch(setAllSettings(defaultSettingList))
    //     parsedData=defaultSettingList;
  
        
      
    // }
    let additionManagerData = [];
    let currentUser = getCurrentUser()?.cu;
    let allData = [];
    let AssistantData = [];
  
 
    let customFunctionUser = [];
    setshowProgress(true);
    gapi.client.load('admin', 'directory_v1', () => {
      const request = gapi.client.directory.users.list({
        customer: "my_customer",
        projection: "full",
        orderBy: sortby,
        query:SearchByLetter?`${sortby}:${allletter?.toLowerCase()}*`:`givenName:*`,
        maxResults: parsedData?.records_to_load??25,
        pageToken: pageToken,
      });

      request.execute((response) => {
        if (response.error) {
          console.error('Error fetching users:', response.error);
        } else {
            let staffMember = response.users?.map((user) => {
                // console.log("custom fileds->",user.customSchemas)
                var arr123 = {};
          
                let DOB=checkCustomSchemasForKey(user?.customSchemas,['dob', 'birthday','DOB'])
                let DOJ=checkCustomSchemasForKey(user?.customSchemas,['doj', 'dateofjoining', 'joinDate','DOJ'])
                let customUrl=checkCustomSchemasForKey(user?.customSchemas,['customUrl'])
                // departments.push(user.organizations?.[0]?.department)
                // JobTitles.push(user.organizations?.[0]?.title)
                if (user.hasOwnProperty("customSchemas")) {
                  const cfield = user?.customSchemas;
      
                  Object.keys(cfield)?.map((key) => {
                    var exchangefld = cfield[key];
      
                    for (var i in exchangefld) {
                      arr123[i] =
                        exchangefld[i] instanceof Array
                          ? exchangefld[i].reduce((a, b) => {
                              return (
                                (a?.value?.toString() || a?.toString()) +
                                " !! " +
                                b?.value
                              );
                            })
                          : exchangefld[i];
                    }
      
                    return [key];
                  });
                }
      
                if (user?.name?.givenName != null) {
                  var names = user?.name?.givenName.split(" "),
                    initials = names[0]?.substring(0, 1)?.toUpperCase();
                  if (names.length > 1) {
                    initials += names[names.length - 1]?.substring(0, 1)?.toUpperCase();
                  }
                }
      
                var _allex, _allexu;
                if (_excspecuser != null && _excspecuser != undefined) {
                  _allexu =
                    _excspecuser.filter(
                      (x) =>
                        user?.name?.fullName?.toLowerCase()?.indexOf(x.toLowerCase()) >
                        -1
                    )?.length > 0;
                } else {
                  _allexu = null;
                }
                _allex = _allexu;
                var _allexuHide =
                  arrmanager?.filter(
                    (x) =>
                      user?.primaryEmail?.toLowerCase()?.indexOf(x?.toLowerCase()) > -1
                  ).length > 0;
      
                const y = parsedData?.AdditionalManagerData?.map((item) => {
                  if (user?.customSchemas?.OtherFields[item.email]) {
                    additionManagerData.push(
                      user?.customSchemas?.OtherFields[item.email]
                    );
                  }
                });
                const assistResult = parsedData?.AssistantData?.map((item) => {
                  if (user?.customSchemas?.OtherFields[item.email]) {
                    AssistantData.push(user?.customSchemas?.OtherFields[item.email]);
                  }
                });
      
                const xx = {
                  ...arr123,
                  firstName: user?.name?.givenName,
                  lastName: user?.name?.familyName,
                  name: user?.name?.fullName,
                  DOB:DOB,
                  DOJ:DOJ,
                  customUrl:customUrl,
                  email: user.primaryEmail,
                  id: user?.id,
                  job: user.hasOwnProperty("organizations")
                    ? user?.organizations[0].hasOwnProperty("title")
                      ? user?.organizations[0].title
                      : ""
                    : "",
                  initials: initials,
                  department: user?.hasOwnProperty("organizations")
                    ? user?.organizations[0]?.hasOwnProperty("department")
                      ? user?.organizations[0]?.department
                      : ""
                    : "",
      
                  workphone: getUserOrgWPItem(user),
                  mobile: _allex ? "" : getUserOrgMBItem(user),
                  manager: _allexuHide ? "" : getUserOrgMGItem(user),
                  employeeid: getUserOrgEIDItem(user),
                  costcenter: user.hasOwnProperty("organizations")
                    ? user.organizations[0].hasOwnProperty("costCenter")
                      ? user?.organizations[0]?.costCenter
                      : ""
                    : "",
                  buildingid: user.hasOwnProperty("locations")
                    ? user.locations[0].hasOwnProperty("buildingId")
                      ? user?.locations[0]?.buildingId
                      : ""
                    : "",
                  floorname: user.hasOwnProperty("locations")
                    ? user?.locations[0].hasOwnProperty("floorName")
                      ? user?.locations[0]?.floorName
                      : ""
                    : "",
                  floorsection: user.hasOwnProperty("locations")
                    ? user?.locations[0].hasOwnProperty("floorSection")
                      ? user?.locations[0]?.floorSection
                      : ""
                    : "",
                  location: getUserOrgLOCItem(user),
                  address: getUserOrgADDItem(user),
                  image: user.hasOwnProperty("thumbnailPhotoUrl")
                    ? user?.thumbnailPhotoUrl
                    : DefaultImage,
                  AdditionalManager: additionManagerData,
                  AssistantData: AssistantData,
                  isAdmin: user?.isAdmin,
                };
               
                
               
               
                return xx;
              });
              if (
                // usersJson?.Users &&
                parsedData?.SyncUserInfoFrom == "Google & Imported User"
              ) {
                customFunctionUser = customFunctionFilter(staffMember, parsedData);
                let usersData = ExcludeExternalUsers(customFunctionUser, parsedData);
                setUserArray((prev)=>removeDuplicatesFromObject([...prev,...usersData],"email"))
        
          setNextPageToken(response.nextPageToken);
          setshowProgress(false);
        }
        }
      });
    });
  }
  else{
    await listFiles();
  }
  };
  

  // ------------------FUNCTIONS END----------------
  // ------------------Props for Pages Component----------------
  const homePagePops = {
    nextPageToken,
    fetchUsers,
    showProgress,
    UserArray,
    NonM365,
    isTile:VIEWS.isTile,
    isImportedUser,
    isGrid:VIEWS.isGrid,
    isList:VIEWS.isList,
    _gridWidth,
    listFiles,
    parsedData,
    allUsers,
    setUserArray,
  };

  const CommandBarProps = {
    setBirthAndAnivModalOpen,
    filterByLetter,
    setSettings,
    setShowHelpPage,
    appSettings: parsedData,

    setshowDashboard,
    isUserAdmin: currentUserAdmin,
    setShowOrgChart,
    setShowHomePage,
    parsedData,
    UserArray,
    setDownloadCsvModal,
    toPDF,
    generatingPDF,
    showHomePage,
  };

  const settingsPageProps = {
    listFiles,
    setSettings,
    setrefreshPage,
    filterByLetter,
    changeVariable,
    changeGridWidth,
    setShowOrgChart,
    setShowHomePage,
    UserArray,
  };

  const orgChartPageProps = {
    setShowOrgChart,
    setShowHomePage,
    filterByLetter,
    setSettings,
    setShowHomePage,
    setShowOrgChart,
    setshowDashboard,
  };

  const SearchFiltersProps = {
  
    setrefreshPage,
    optimization,
    setselectedGrp,
    setClickClearFilter,
    isClickClearFilter,
    filterByLetter,
    groupsOptions,
    selectedGrp,
    filterByTaxonomySearchMultiGrp,
    setView,
    view,
    setisGrid,
    setisTile,
    setisList,
    setImpotedUser,
    UserArray,
  };

  // -----------------Props for Pages Component end---------------

  if (!isUserSignedIn && showLoginScreen) {
    return <SignInPage signInWithPopup={signInWithPopup} />;
  }

  return (
    <Container>
      {isUserSignedIn && (
        
          <ContextProvider>
            <div className="edp" id="mainDiv">
              <div className="container">
                <div className="row" style={{ position: "relative" }}>
                  <div className="filterDiv">
                    {parsedData && parsedData?.TopBarFilterView !== "Hide" && (
                      <TopBarFilter
                        optimization={optimization}
                        SyncUserInfoFrom={parsedData?.SyncUserInfoFrom}
                        isImportedUser={isImportedUser}
                        UserArray={UserArray}
                        isClickClearFilter={isClickClearFilter}
                      />
                    )}
                    <CommandBar {...CommandBarProps} />
                  </div>
                  {/* -------------------PAGES CONTAINER START------------------- */}
                  <div>
                    {isDownloadCsvModal && (
                      <ExportToCsv
                        handleDownloadCSV={handleDownloadCSV}
                        setDownloadCsvModal={setDownloadCsvModal}
                        UserArray={UserArray}
                      />
                    )}

  <BirthdayAndAnniversary
                      isBirthAndAnivModalOpen={isBirthAndAnivModalOpen}
                      setBirthAndAnivModalOpen={setBirthAndAnivModalOpen}
                      Users={UserArray}
                      invalidAccount={invalidAccount}
                      showHomePage={showHomePage}
                    />

                    {pages[DASHBOARD_KEY] && (                      
                      !invalidAccount ?  (<Dashboard
                        users={UserArray}
                        // settingData={parsedData}
                        appSettings={Sett}
                        setShowHomePage={setShowHomePage}
                        setSettings={setSettings}
                        setshowDashboard={setshowDashboard}
                        setShowOrgChart={setShowOrgChart}
                      />):<>
                          <MessageBar
            messageBarType={MessageBarType.info}
            isMultiline={false}
            styles={messageBarInfoStyles}
            dismissButtonAriaLabel={"Close"}
            style={{ justifyContent: "center" }}
          >
           {code==400 && message=="Invalid Input"?"Looks like you are logged in with personal account, kindly login with the workspace account to continue using the Employee Directory 365": "No Records Found"}
          </MessageBar>
                      
                      </>
                    )}

                    {pages[SETTING_KEY] && <SettingsPage {...settingsPageProps} />}

                    {pages[Home_KEY] && (
                      <div>
                        <SearchFilters {...SearchFiltersProps} />
                        <div ref={targetRef} id="pdf-container">
                          <Home {...homePagePops} />
                        </div>
                      </div>
                    )}

                   {showHelpPage? <HelpPage
                      onDismiss={() => setShowHelpPage(false)}
                      isOpen={showHelpPage}
                    />:""}
                  {/* <Test /> */}
                    {pages[ORGCHART_KEY] && <OrgChartPage {...orgChartPageProps} />}
                  </div>
                  <div style={{ width: "100%", float: "right" }}></div>
                  {/* -------------------PAGES CONTAINER END------------------- */}
                </div>
              </div>
            </div>
          </ContextProvider>
        // </LanguageProvider>
      )}
    </Container>
  );
};



const TopBarFilter = ({ optimization, isClickClearFilter }) => {
  const { view } = useStore();
  const M365Users = useSelector((state) => state.storeSettings.M365Users);
  const VIEWS = useSelector((state) => state.storeSettings.views);
  const { departmentFields, FormatedUserData } = useFields();
  
  const [tabValues, setTabValues] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [data, setData] = useState(FormatedUserData);
  const [showMore, setShowMore] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    if (VIEWS?.isNonM365) {
      setTabValues(NonMUsers?.map((item) => ({
        value: item.department,
        label: item.department,
      })));
    } else {
      setTabValues(M365Users?.map((item) => ({
        value: item.department,
        label: item.department,
      })));
    }
  }, [VIEWS?.isNonM365, VIEWS?.isGrid, VIEWS?.isTile, VIEWS?.isList]);

  const MAX_VISIBLE_TABS = 10;
  const visibleTabs = showMore ? tabValues : tabValues.slice(0, MAX_VISIBLE_TABS);

  useEffect(() => {
    if (VIEWS?.isNonM365) {
      setData(NonMUsers);
    } else {
      setData(M365Users);
    }
  }, [view, VIEWS?.isNonM365, M365Users?.length, isClickClearFilter]);

  const handleTabClick = (x) => {
    setSelectedTab(x.value);
    optimization(x.value, "departments");
  };

  // Helper function to get the count of users in a department
  const getDepartmentCount = (department) => {
    return data?.filter((user) => user?.department === department)?.length || 0;
  };

  return (
    <div className="topbarFilterWrapper">
      {visibleTabs.map((x) => {
        const count = getDepartmentCount(x.value);
        if (count === 0) return null; // Skip empty departments

        return (
          <div
            key={x?.value}
            className={`topbarFilter ${selectedTab === x.value ? "topbarfilterBorderBottom" : ""}`}
            onClick={() => handleTabClick(x)}
          >
            <p>{x.label}</p>
            <p className="tabCount">{count}</p>
          </div>
        );
      })}

      {tabValues?.length > MAX_VISIBLE_TABS && (
        <div style={{ position: "relative" }}>
          <button
            onClick={() => setDropdownVisible(!dropdownVisible)}
            className="dropdownButton"
          >
            ...
          </button>
          {dropdownVisible && (
            <div className="dropdownContainer">
              {tabValues.slice(MAX_VISIBLE_TABS).map((x) => {
                const count = getDepartmentCount(x.value);
                return (
                  <div
                    key={x.value}
                    className="tabspivot"
                    onClick={() => handleTabClick(x)}
                  >
                    <p>{x.label}</p>
                    <p className="tabCount">{count}</p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};




const SignInPage = ({ signInWithPopup }) => {
  return (
    <div className="sign-in-page">
      <div className="sign-in-container">
        <div className="logo">
          <img src={DirectoryImage} alt="directory logo" />
        </div>

        <div className="heading">
          <h3>Welcome to Employee Directory</h3>
          <p>Employee Directory - Smart way to connect with your employees.</p>
        </div>

        <div className="sign-in-button-container">
          <button className="sign-in-button" onClick={signInWithPopup}>
            <img
              src="https://www.svgrepo.com/show/475656/google-color.svg"
              loading="lazy"
              alt="google logo"
            />
            <span>Sign in with Google</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleEmployeeDirectory;
