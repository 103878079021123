import { configureStore } from '@reduxjs/toolkit';
import appSettingsReducer from './appSettingsSlice';

const store = configureStore({
  reducer: {
    storeSettings: appSettingsReducer
  }
});

export default store;
