import logo from './logo.svg';
import GoogleEmployeeDirectory from "./Components/SelectSource";
import './App.css';
import { LanguageProvider } from './Language/LanguageContext';
// import { fetchSettings, fetchUsersDataSettings } from './Store/appSettingsSlice';
// import { useDispatch } from 'react-redux';
// import { useEffect } from 'react';


function App() {

  return (
 
    <div style={{fontFamily:"Segoe UI"}}>
      <LanguageProvider>

    <GoogleEmployeeDirectory  />
      </LanguageProvider>
    
    </div>
  
  );
}

export default App;
