// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  padding: 0;
  margin: 0;
  /* box-sizing: border-box; */
}
body {
  margin: 0;
  font-family:'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.linkContent-189 {
  background-color: rgb(0, 112, 220, .4);
}
::-webkit-scrollbar {
  height: 18px;
  width: 18px;
}

::-webkit-scrollbar-button {
  background-color: #fff;
  background-repeat: no-repeat;
  cursor: pointer;
}

::-webkit-scrollbar-corner {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 9px;
  border: solid 6px #fff;
  background-color: #98a3ac;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,4BAA4B;AAC9B;AACA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,sCAAsC;AACxC;AACA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":["*{\n  padding: 0;\n  margin: 0;\n  /* box-sizing: border-box; */\n}\nbody {\n  margin: 0;\n  font-family:'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.linkContent-189 {\n  background-color: rgb(0, 112, 220, .4);\n}\n::-webkit-scrollbar {\n  height: 18px;\n  width: 18px;\n}\n\n::-webkit-scrollbar-button {\n  background-color: #fff;\n  background-repeat: no-repeat;\n  cursor: pointer;\n}\n\n::-webkit-scrollbar-corner {\n  background-color: #fff;\n}\n\n::-webkit-scrollbar-thumb {\n  border-radius: 9px;\n  border: solid 6px #fff;\n  background-color: #98a3ac;\n}\n\n::-webkit-scrollbar-track {\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
