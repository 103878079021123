import { createSlice } from '@reduxjs/toolkit';
import { defaultSettingList, defaultUserList } from '../api/defaultSettings';
import { getSettingJson, SETTING_LIST, updateSettingJson, USER_LIST } from '../api/storage';
import { gapi } from "gapi-script";
import { removeDuplicatesFromObject } from '../Components/Helpers/HelperFunctions';
export const fetchSettings = () => async (dispatch) => {
  try {
    const data = await getSettingJson(SETTING_LIST);
     if(Object.keys(data)?.length){

       dispatch(setAllSettings(data)); 
     }else{
      dispatch(setAllSettings(defaultSettingList)); 
      updateSettingJson(SETTING_LIST, defaultSettingList,true);
     }
  } catch (error) {
    console.log("Failed to fetch settings:", error);
  }
};
export const fetchUsersDataSettings = () => async (dispatch) => {
  try {
    const data = await getSettingJson(USER_LIST);
     if(Object.keys(data)?.length){

       dispatch(setAllUsersData(data)); 
       alert("from rexxx")
     }else{
      dispatch(setAllUsersData(defaultUserList)); 
      updateSettingJson(USER_LIST, defaultUserList);
     }
  } catch (error) {
    console.log("Failed to fetch users settings:", error);
  }
};


const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: {
    appSettings: {},
    views:{
      isGrid:true,
      isList:false,
      isTile:false,
      isNonM365:false,
    },
    pages:{
      dashboard:false,
      home:true,
      settings:false,
      org:false
    },
    M365Users:[],
    selectedLetter:"All",
    NonM365Users:[],
    FetchUserError:{},
    CurrentUserData:[],
    JobTitle:[],
    departments:[],
    isCurrntUserAdmin:false,
    JobTitle:[],
    departments:[],
    NonM365JobTitle:[],
    NonM365departments:[],
    UsersData:{},

  },
  reducers: {
    setAllSettings: (state, action) => {
      state.appSettings = { ...state.appSettings, ...action.payload }; // Update state directly
      console.log("log from store", state.appSettings); // Log updated state
    },
    setfetchUserError: (state, action) => {
      state.FetchUserError = action.payload;
      console.log("log from store", state.FetchUserError); // Log updated state
    },
    setAllUsersData: (state, action) => {
      state.UsersData = { ...state.UsersData, ...action.payload }; // Update state directly
      console.log("log from store", state.UsersData); // Log updated state
    },
    setIsCurrentUserAdmin: (state, action) => {
      state.isCurrntUserAdmin = action.payload; 
      console.log("log from store", state.isCurrntUserAdmin); 
    },
    setCurrentUserData: (state, action) => {
      if(action.payload?.length){

      
      // Spread the existing CurrentUserData and the new data from action.payload
      let data=[...state.CurrentUserData, ...action.payload];
      state.CurrentUserData =removeDuplicatesFromObject(data,"email");
      console.log("log from store", state.CurrentUserData); // Log updated state
      }
    }
    
    ,
    setViewSetting: (state, action) => {
      
      state.views = {
        isGrid: false,
        isList: false,
        isTile: false,
        isNonM365: false,
        [action.payload]: true, 
      };
      console.log('Updated view:', state.views); // Log updated view state
    },
    setShowHidePages: (state, action) => {
      
      state.pages = {
        home: false,
        dashboard: false,
        settings: false,
        org:false,
        [action.payload]: true, 
      };
      console.log('Updated view:', state.views); // Log updated view state
    },
    setAllDepartments: (state, action) => {
     
      const uniqueDepartments = [...new Set(action.payload)];
      
      
      console.log('departments:', uniqueDepartments);
      return {
        ...state,
        departments: uniqueDepartments,
      };
    
      
    },
    setSelectedLetter: (state, action) => {
      state.selectedLetter = action.payload; 
      console.log('Updated SelectedLetter:', state.selectedLetter); 
    },
    addNewDepartments: (state, action) => {
      // Merge current departments with the new ones from the payload
      const updatedDepartments = [...state.departments, action.payload];
      
      // Remove duplicates using Set
      const uniqueDepartments = [...new Set(updatedDepartments)];
    
      console.log('departments:', uniqueDepartments);
      
      // Return the updated state with unique departments
      return {
        ...state,
        departments: uniqueDepartments,
      };
    },
    
    setAllJobTitle: (state, action) => {
     
      const uniqueAllJobTitle = [...new Set(action.payload)];
      
      
      console.log('JobTitle:', uniqueAllJobTitle);
      return {
        ...state,
        JobTitle: uniqueAllJobTitle,
      };
    
      
    },
    setAllNonDepartments: (state, action) => {
     
      const uniqueDepartments = [...new Set(action.payload)];
      
      
      console.log('NonM365departments:', uniqueDepartments);
      return {
        ...state,
        NonM365departments: uniqueDepartments,
      };
    
      
    },
    setAllNonJobTitle: (state, action) => {
     
      const uniqueAllJobTitle = [...new Set(action.payload)];
      
      
      console.log('uniqueAllJobTitle:', uniqueAllJobTitle);
      return {
        ...state,
        NonM365JobTitle: uniqueAllJobTitle,
      };
    
      
    },
    addM365User: (state, action) => {
      state.M365Users=action.payload; // Add the user to the M365Users array
      console.log('Added M365 user:', action.payload); // Log added M365 user
    },
    addNonM365User: (state, action) => {
      state.NonM365Users=action.payload // Add the user to the NonM365Users array
      console.log('Added Non-M365 user:', action.payload); // Log added Non-M365 user
    },
  }
});


export const { setAllSettings,setfetchUserError,setIsCurrentUserAdmin,setSelectedLetter,setShowHidePages,addNewDepartments,setAllNonJobTitle,setAllNonDepartments,setAllJobTitle,setViewSetting,setAllDepartments,addNonM365User,addM365User,setCurrentUserData} = appSettingsSlice.actions;
export default appSettingsSlice.reducer;
