// import * as React from "react";
// import { Icon, SearchBox } from "@fluentui/react";
// import { removeDuplicatesFromObject } from "../Helpers/HelperFunctions";
// import { useSttings } from "../SelectSource/store";
// import MultiSelect from "react-select";
// import { useFields, useLists } from "../../context/store";
// import { useLanguage } from "../../Language/LanguageContext";
// import {
//   getSettingJson,
//   SETTING_LIST,
//   updateSettingJson,
// } from "../../api/storage";

// const searchBoxStyles = {
//   root: {
//     border: "0px",
//     float: "left",
//     borderBottom: "1px solid #ddd",
//     selectors: {
//       "&:after": {
//         border: "none",
//       },
//       ".ms-SearchBox-field::placeholder": {
//         color: "#aaa",
//       },
//     },
//   },
// };

// const stylesForDropDown: any = {
//   control: (provided, state) => ({
//     ...provided,
//     width: 150,
//     height: 29,
//     border: "none",
//     borderBottom: "1px solid #DDD",
//     borderRadius: "0px",
//     minHeight: "32px",
//     boxShadow: state.isFocused ? `0 0 0 .5px black` : provided.boxShadow,
//     borderColor: state.isFocused ? `black` : provided.borderColor,
//     "&:hover": {
//       borderColor: state.isFocused ? `black` : provided.borderColor,
//     },
//   }),
//   placeholder: (provided) => ({
//     ...provided,
//     color: "#AAA",
//     fontSize: "15px",
//   }),
// };
// let whichViewIsEnabled = "Grid";
// let settingData :any= {};
// const SearchFilters = ({
//   optimization,
//   isClickClearFilter,
//   setClickClearFilter,
//   filterByLetter,
//   setselectedGrp,
//   groupsOptions,
//   selectedGrp,
//   filterByTaxonomySearchMultiGrp,
//   setView,
//   view,
//   setisGrid,
//   setisTile,
//   setisList,
//   setImpotedUser,
//   UserArray,
// }) => {
//   const { translation } = useLanguage();
//   // const { settingData } = useSttings();
//   const { departmentFields, jobTitleFields,setDepDropDown , showHideSettings} = useFields();
//   const { usersList} = useLists();
//   const ShowAsDropdown = settingData?.ShowHideViewModules?.ShowAsDropdown;

//   const [showMobileFreeSearch, setShowMobileFreeSearch] = React.useState(false);
//   const [showMobileViewSearch, setShowMobileViewSearch] = React.useState(false);
//   const [availableView, setAvailableView] = React.useState<any>([]);
//   const dropsDowns = ["Departments", "Title"];
//   const [searchValues, setSearchValues] = React.useState({
//     name: "",
//     departments: "",
//     title: "",
//     "free search": "",
//   });
//   const [dropdownValues, setDropdownValues] = React.useState({});

//   const [timeoutId, setTimeoutId] = React.useState(null);
//   const freeSearchRef = React.useRef(null);
//   React.useEffect(()=>{
//     async function getdata(){

//       settingData=await getSettingData();
//     }
//     getdata();
//   },[])
//   React.useEffect(() => {
//     let result = allView?.filter(({ name }) => {
//       if (name == "Grid" && !settingData?.hideShowViews?.grid) return false;
//       if (name == "List" && !settingData?.hideShowViews?.list) return false;
//       if (name == "Tile" && !settingData?.hideShowViews?.tile) return false;

//       return true;
//     });
//     setAvailableView(result);
//   }, []);

//   const getDeprtmentPivots = () => {
//     if (whichViewIsEnabled == "NonM365") {
//       const dep = usersList?.Users?.map((x) => ({
//         value: x.department,
//         label: x.department,
//       }));
//       let x = removeDuplicatesFromObject(dep, "value");
//       const sd = settingData?.FilterAttribute?.departments || [];
//       const ll = sd?.map((x:any) => ({ value: x.Department, label: x.Department }))||[];
//       if(x == undefined || x == null){
//         x = [];
//       }
//       const y = [...x, ...ll];
//       const xx = removeDuplicatesFromObject(y, "value");
//       setDepDropDown(xx);
//     } else {
//       const dep = UserArray.map((x) => ({
//         value: x.department,
//         label: x.department,
//       }));
//       const x = removeDuplicatesFromObject(dep, "value");
//       const sd = settingData?.FilterAttribute?.departments;
//       const ll = sd?.map((x) => ({ value: x.Department, label: x.Department }))||[];
//       const y = [...x, ...ll];
//       const xx = removeDuplicatesFromObject(y, "value");
//       setDepDropDown(xx);
//     }
//   };
//   async function getSettingData() {
//     let data = await getSettingJson(SETTING_LIST);
//     return data;
//   }

//   React.useEffect(() => {
//     getDeprtmentPivots();
//   }, [isClickClearFilter]);

//   React.useEffect(() => {
//     return () => {
//       if (timeoutId) {
//         clearTimeout(timeoutId);
//       }
//     };
//   }, [timeoutId]);

//   function handleFreeSearch(value, mode) {
//     setSearchValues((prevValues) => ({
//       ...prevValues,
//       [mode]: value,
//     }));

//     if (timeoutId) {
//       clearTimeout(timeoutId);
//     }

//     const id = setTimeout(() => {
//       optimization(value.trim(), mode);
//     }, 300);

//     setTimeoutId(id);
//   }

//   async function handleClearFilter() {
//     setClickClearFilter((prev) => !prev);
//     // if (settingData?.clearAlphaFilter) {
//     document.getElementById(`letter-${translation.All}`).click();
//     filterByLetter(translation.All);
//     // }
//     setSearchValues({
//       name: "",
//       departments: "",
//       title: "",
//       "free search": "",
//     });
//     setselectedGrp([]);
//     document.querySelectorAll(".topbarFilter").forEach((x) => {
//       if (x.classList.contains("topbarfilterBorderBottom")) {
//         x.classList.remove("topbarfilterBorderBottom");
//         x.classList.add("onInActiveTab");
//       }
//     });
//     setDropdownValues([]);
//   }

//   let allView = [
//     {
//       title: "Grid View",
//       iconName: "ContactCard",
//       name: "Grid",
//       onclick: () => {
//         setView("Grid");
//         whichViewIsEnabled = "Grid";
//         // updateSettingJson(SETTING_LIST,{...settingData,CurrentView:whichViewIsEnabled})
//         setisGrid(true);
//         setisList(false);
//         setisTile(false);
//         setImpotedUser(false);
//         getDeprtmentPivots();
//       },
//     },
//     {
//       title: "List View",
//       iconName: "BulletedList",
//       name: "List",
//       onclick: () => {
//         setView("List");
//         whichViewIsEnabled = "List";
//         // updateSettingJson(SETTING_LIST,{...settingData,CurrentView:whichViewIsEnabled})
//         setisGrid(false);
//         setisList(true);
//         setisTile(false);
//         setImpotedUser(false);
//         getDeprtmentPivots();
//       },
//     },
//     {
//       title: "Tile View",
//       iconName: "Waffle",
//       name: "Tile",
//       onclick: () => {
//         setView("Tile");
//         whichViewIsEnabled = "Tile";
//         // updateSettingJson(SETTING_LIST,{...settingData,CurrentView:whichViewIsEnabled})
//         setisGrid(false);
//         setisList(false);
//         setisTile(true);
//         setImpotedUser(false);
//         getDeprtmentPivots();
//       },
//     },
//   ];

//   const filterViews = (views, settings) => {
//     return views?.filter((view) => {
//       return settings[view?.name?.toLowerCase()] === true;
//     });
//   };

//   function showNonM365() {
//     setImpotedUser(true);
//     setView("NonM365");
//     whichViewIsEnabled = "NonM365";
//     // updateSettingJson(SETTING_LIST,{...settingData,CurrentView:whichViewIsEnabled})
//     setisGrid(false);
//     setisList(false);
//     setisTile(false);
//     getDeprtmentPivots();
//   }

//   React.useEffect(() => {
//     allView = filterViews(allView, settingData?.hideShowViews);
//   }, []);
//   console.log("availableView",availableView)
//   return (
//     <div>
//       <div className="search-con">
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             marginTop: "10px",
//             width: "100%",
//           }}
//         >
//           <div>
//             {settingData && (
//               <div
//                 className="desktopfilters"
//                 style={{
//                   display: "flex",
//                   gap: "20px ",
//                   alignItems: "center",
//                   flexWrap: "wrap",
//                 }}
//               >
//                 <SearchBox
//                   onBlur={() => setShowMobileFreeSearch(false)}
//                   componentRef={freeSearchRef}
//                   className="searchBoxStyles"
//                   styles={searchBoxStyles}
//                   placeholder="Free Search"
//                   onEscape={handleClearFilter}
//                   onChange={(e) =>
//                     handleFreeSearch(e?.target?.value || "", "free search")
//                   }
//                   value={searchValues["free search"] || ""}
//                 />
//                 {settingData?.SearchFiltersPrope?.map(
//                   (filter: any, index: number) => {
//                     if (filter.checkbox) {
//                       return ShowAsDropdown ? (
//                         dropsDowns.includes(filter.name) ? (
//                           <MultiSelect
//                             key={index}
//                             styles={stylesForDropDown}
//                             value={dropdownValues[filter.name] || []}
//                             isClearable={true}
//                             placeholder={filter.name}
//                             options={
//                               filter.name == "Departments"
//                                 ? departmentFields
//                                 : jobTitleFields
//                             }
//                             onChange={(e: any) => {
//                               handleFreeSearch(
//                                 e?.value || "",
//                                 filter.name.toLowerCase()
//                               );
//                               setDropdownValues((prev) => ({
//                                 ...prev,
//                                 [filter.name]: e?.value
//                                   ? { label: e?.value, value: e?.value }
//                                   : [],
//                               }));
//                             }}
//                           />
//                         ) : (
//                           <SearchBox
//                             key={index}
//                             autoComplete="off"
//                             className="searchBoxStyles"
//                             styles={searchBoxStyles}
//                             placeholder={filter.name}
//                             value={searchValues[filter.name.toLowerCase()]}
//                             onEscape={handleClearFilter}
//                             onClear={handleClearFilter}
//                             onChange={(e: any) => {
//                               if (filter.name === "Custom Fields") {
//                                 handleFreeSearch(
//                                   e?.target?.value || "",
//                                   "custom"
//                                 );
//                               } else {
//                                 handleFreeSearch(
//                                   e?.target?.value || "",
//                                   filter.name.toLowerCase()
//                                 );
//                               }
//                             }}
//                           />
//                         )
//                       ) : (
//                         <SearchBox
//                           key={index}
//                           autoComplete="off"
//                           className="searchBoxStyles"
//                           styles={searchBoxStyles}
//                           placeholder={filter.name}
//                           value={searchValues[filter.name.toLowerCase()] || ""}
//                           onEscape={handleClearFilter}
//                           onClear={handleClearFilter}
//                           onChange={(e: any) =>
//                             handleFreeSearch(
//                               e?.target?.value || "",
//                               filter.name.toLowerCase()
//                             )
//                           }
//                         />
//                       );
//                     }
//                   }
//                 )}
//                 {showHideSettings?.GroupsOn ? (
//                   <MultiSelect
//                     // options={filterOptions}
//                     // isMulti
//                     isClearable
//                     placeholder="Groups"
//                     styles={stylesForDropDown}
//                     options={groupsOptions}
//                     value={selectedGrp}
//                     onChange={(value) => filterByTaxonomySearchMultiGrp(value)}
//                   />
//                 ) : (
//                   ""
//                 )}

//                 {settingData?.SearchFiltersPrope && (
//                   <div
//                     style={{
//                       fontSize: "20px",
//                       color: "rgb(0, 120, 212)",
//                       cursor: "pointer",
//                     }}
//                   >
//                     <Icon
//                       iconName="ClearFilter"
//                       title="Clear Filters"
//                       onClick={handleClearFilter}
//                     />
//                   </div>
//                 )}
//               </div>
//             )}

//             <div className="mbviewSearch">
//               {!showMobileViewSearch && (
//                 <Icon
//                   iconName="search"
//                   onClick={() => {
//                     setShowMobileViewSearch(true);
//                     freeSearchRef.current?.focus();
//                   }}
//                 />
//               )}

//               {showMobileViewSearch && (
//                 <SearchBox
//                   onBlur={() => setShowMobileFreeSearch(false)}
//                   componentRef={freeSearchRef}
//                   className="searchBoxStyles"
//                   styles={searchBoxStyles}
//                   placeholder="Free Search"
//                   onEscape={handleClearFilter}
//                   onChange={(e) =>
//                     handleFreeSearch(e?.target?.value || "", "free search")
//                   }
//                   value={searchValues["free search"]}
//                 />
//               )}
//             </div>
//           </div>

//           <div style={{ display: "flex", gap: "20px" }}>
//             {settingData?.SyncUserInfoFrom?.toLowerCase() != "importeduser"
//               ? settingData?.SearchFiltersPrope && (
//                   <Icon
//                     title="Non google User"
//                     iconName="AddGroup"
//                     style={
//                       view === "NonM365"
//                         ? {
//                             fontSize: "20px",
//                             color: "rgb(0, 112, 220)",
//                             borderBottom: "2px solid rgb(0, 112, 220)",
//                             cursor: "pointer",
//                           }
//                         : {
//                             fontSize: "20px",
//                             color: "rgb(0, 112, 220)",
//                             cursor: "pointer",
//                           }
//                     }
//                     onClick={showNonM365}
//                   />
//                 )
//               : ""}
//             {settingData?.SearchFiltersPrope &&
//               availableView?.map(({ title, iconName, name, onclick }) => (
//                 <span
//                   key={name}
//                   style={
//                     view === name
//                       ? {
//                           fontSize: "20px",
//                           color: "rgb(0, 112, 220)",
//                           borderBottom: "2px solid rgb(0, 112, 220)",
//                           cursor: "pointer",
//                         }
//                       : {
//                           fontSize: "20px",
//                           color: "rgb(0, 112, 220)",
//                           cursor: "pointer",
//                         }
//                   }
//                 >
//                   <Icon title={title} onClick={onclick} iconName={iconName} />
//                 </span>
//               ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SearchFilters;
import * as React from "react";
import { Icon, SearchBox } from "@fluentui/react";
import { removeDuplicatesFromObject } from "../Helpers/HelperFunctions";
import { useSttings } from "../SelectSource/store";
import MultiSelect from "react-select";
import { useFields, useLists } from "../../context/store";
import { useLanguage } from "../../Language/LanguageContext";
import {
  getSettingJson,
  SETTING_LIST,
  updateSettingJson,
} from "../../api/storage";
import { useDispatch, useSelector } from "react-redux";
import { setViewSetting } from "../../Store/appSettingsSlice";
import styles from "../SCSS/Ed.module.scss"

const searchBoxStyles = {
  root: {
    border: "0px",
    float: "left",
    borderBottom: "1px solid #ddd",
    selectors: {
      "&:after": {
        border: "none",
      },
      ".ms-SearchBox-field::placeholder": {
        color: "#aaa",
      },
    },
  },
};

const stylesForDropDown: any = {
  control: (provided, state) => ({
    ...provided,
    width: 150,
    height: 29,
    border: "none",
    borderBottom: "1px solid #DDD",
    borderRadius: "0px",
    minHeight: "32px",
    maxHeight: "150px",
    boxShadow: "none",
    borderColor: "transparent",
    "&:hover": {
      // borderColor: state.isFocused ? `black` : provided.borderColor,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#AAA",
    fontSize: "15px",
  }),
};
let whichViewIsEnabled = "Grid";
let settingData :any= {};
const SearchFilters = ({
  // settingData,
  optimization,
  isClickClearFilter,
  setClickClearFilter,
  filterByLetter,
  setselectedGrp,
  groupsOptions,
  selectedGrp,
  filterByTaxonomySearchMultiGrp,
  setView,
  view,
  setisGrid,
  setisTile,
  setisList,
  setImpotedUser,
  UserArray,
}) => {
  const { translation } = useLanguage();
  const dispatch=useDispatch();
  const AllSettings=useSelector((state:any)=>state.storeSettings);
  settingData=AllSettings.appSettings;
  const viewsSettings=useSelector((state:any)=>state.storeSettings.views)
  const AllDepartments=useSelector((state:any)=>state.storeSettings.departments)
  const AllJobTitles=useSelector((state:any)=>state.storeSettings.JobTitle)
  // const { settingData } = useSttings();
  const { departmentFields, jobTitleFields,setDepDropDown , showHideSettings} = useFields();
  const { usersList} = useLists();
  const ShowAsDropdown = settingData?.ShowHideViewModules?.ShowAsDropdown;

  const [showMobileFreeSearch, setShowMobileFreeSearch] = React.useState(false);
  const [showMobileViewSearch, setShowMobileViewSearch] = React.useState(false);
  const [availableView, setAvailableView] = React.useState<any>([]);
  const dropsDowns = ["Departments", "Title"];
  const [searchValues, setSearchValues] = React.useState({
    name: "",
    departments: "",
    title: "",
    "free search": "",
  });
  const [dropdownValues, setDropdownValues] = React.useState({});

  const [timeoutId, setTimeoutId] = React.useState(null);
  const freeSearchRef = React.useRef(null);
  // React.useEffect(()=>{
  //   async function getdata(){

  //     settingData=await getSettingData();
  //   }
  //   getdata();
  // },[])


  async function getSettingData() {
    let data = await getSettingJson(SETTING_LIST);
    return data;
  }



  React.useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  function handleFreeSearch(value, mode) {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [mode]: value,
    }));

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      optimization(value.trim(), mode);
    }, 500);

    setTimeoutId(id);
  }

  async function handleClearFilter() {
    setClickClearFilter((prev) => !prev);
    // if (settingData?.clearAlphaFilter) {
    document.getElementById(`letter-${translation.All}`).click();
    filterByLetter(translation.All);
    // }
    setSearchValues({
      name: "",
      departments: "",
      title: "",
      "free search": "",
    });
    setselectedGrp([]);
    document.querySelectorAll(".topbarFilter").forEach((x) => {
      if (x.classList.contains("topbarfilterBorderBottom")) {
        x.classList.remove("topbarfilterBorderBottom");
        x.classList.add("onInActiveTab");
      }
    });
    setDropdownValues([]);
  }



  function showNonM365() {
    setImpotedUser(true);
    setView("NonM365");
    whichViewIsEnabled = "NonM365";
    // updateSettingJson(SETTING_LIST,{...settingData,CurrentView:whichViewIsEnabled})
    setisGrid(false);
    setisList(false);
    setisTile(false);
    dispatch(setViewSetting("isNonM365"))
    // getDeprtmentPivots();
  }

  
  function setDropDownOptions(name){
    if(viewsSettings.isNonM365){
     return name == "Departments" 
      ? AllSettings?.NonM365departments?.map((item) => ({
        value: item,
        label: item,
      })): AllSettings?.NonM365JobTitle?.map((item) => ({
        value: item,
        label: item,
      }))
    }else if(settingData?.SyncUserInfoFrom?.toLowerCase() != "importeduser" && !viewsSettings.isNonM365){
    return  name == "Departments" 
      ? AllDepartments?.map((item) => ({
        value: item,
        label: item,
      })): AllJobTitles ?.map((item) => ({
        value: item,
        label: item,
      }))
    }
   
  }
  
  return (
    <div>
      <div className="search-con">
        <div
         className={styles.SearchConStyles}
        >
          <div>
            {settingData && (
              <div
                className={`${styles.MobileFreeSearchflexContainer} desktopfilters`}
                
              >
                <SearchBox
                  onBlur={() => setShowMobileFreeSearch(false)}
                  componentRef={freeSearchRef}
                  className="searchBoxStyles"
                  styles={searchBoxStyles}
                  placeholder="Free Search"
                  onEscape={handleClearFilter}
                  onChange={(e) =>
                    handleFreeSearch(e?.target?.value || "", "free search")
                  }
                  value={searchValues["free search"] || ""}
                />
                {settingData?.SearchFiltersPrope?.map(
                  (filter: any, index: number) => {
                    if (filter.checkbox) {
                      return ShowAsDropdown ? (
                        dropsDowns.includes(filter.name) ? (
                          <MultiSelect
                            key={index}
                            styles={stylesForDropDown}
                            value={dropdownValues[filter.name] || []}
                            isClearable={true}
                            placeholder={filter.name}
                            options={

                              setDropDownOptions(filter.name)
                            }
                            onChange={(e: any) => {
                              handleFreeSearch(
                                e?.value || "",
                                filter.name.toLowerCase()
                              );
                              setDropdownValues((prev) => ({
                                ...prev,
                                [filter.name]: e?.value
                                  ? { label: e?.value, value: e?.value }
                                  : [],
                              }));
                            }}
                          />
                        ) : (
                          <SearchBox
                            key={index}
                            autoComplete="off"
                            className="searchBoxStyles"
                            styles={searchBoxStyles}
                            placeholder={filter.name}
                            value={searchValues[filter.name.toLowerCase()]}
                            onEscape={handleClearFilter}
                            onClear={handleClearFilter}
                            onChange={(e: any) => {
                              if (filter.name === "Custom Fields") {
                                handleFreeSearch(
                                  e?.target?.value || "",
                                  "custom"
                                );
                              } else {
                                handleFreeSearch(
                                  e?.target?.value || "",
                                  filter.name.toLowerCase()
                                );
                              }
                            }}
                          />
                        )
                      ) : (
                        <SearchBox
                          key={index}
                          autoComplete="off"
                          className="searchBoxStyles"
                          styles={searchBoxStyles}
                          placeholder={filter.name}
                          value={searchValues[filter.name.toLowerCase()] || ""}
                          onEscape={handleClearFilter}
                          onClear={handleClearFilter}
                          onChange={(e: any) =>
                            handleFreeSearch(
                              e?.target?.value || "",
                              filter.name.toLowerCase()
                            )
                          }
                        />
                      );
                    }
                  }
                )}
                {showHideSettings?.GroupsOn ? (
                  <MultiSelect
                    // options={filterOptions}
                    // isMulti
                    isClearable
                    placeholder="Groups"
                    styles={stylesForDropDown}
                    options={groupsOptions}
                    value={selectedGrp}
                    onChange={(value) => filterByTaxonomySearchMultiGrp(value)}
                  />
                ) : (
                  ""
                )}

                {settingData?.SearchFiltersPrope && (
                  <div
                    className={styles.clearFilterStyle}
                  >
                    <Icon
                      
                      iconName="ClearFilter"
                      title="Clear Filters"
                      onClick={handleClearFilter}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="mbviewSearch">
              {!showMobileViewSearch && (
                <Icon
                  iconName="search"
                  onClick={() => {
                    setShowMobileViewSearch(true);
                    freeSearchRef.current?.focus();
                  }}
                />
              )}

              {showMobileViewSearch && (
                <SearchBox
                  onBlur={() => setShowMobileFreeSearch(false)}
                  componentRef={freeSearchRef}
                  className="searchBoxStyles"
                  styles={searchBoxStyles}
                  placeholder="Free Search"
                  onEscape={handleClearFilter}
                  onChange={(e) =>
                    handleFreeSearch(e?.target?.value || "", "free search")
                  }
                  value={searchValues["free search"]}
                />
              )}
            </div>
          </div>

          <div style={{ display: "flex", gap: "20px" }}>
            {settingData?.SyncUserInfoFrom?.toLowerCase() != "importeduser"
              ? settingData?.SearchFiltersPrope && (
                  <Icon
                    title="Non google User"
                    iconName="AddGroup"
                    className={
                      viewsSettings?.isNonM365 
                        ? styles.SelectedViewStyle:styles.defaultViewStyle
                    }
                    onClick={showNonM365}
                  />
                )
              : ""}
            {settingData?.hideShowViews?.grid? <span className={viewsSettings?.isGrid ? styles.SelectedViewStyle:styles.defaultViewStyle}><Icon title={"Grid"} style={{cursor:"pointer"}} onClick={() => {dispatch(setViewSetting("isGrid")) ,setView("Grid"); whichViewIsEnabled = "Grid"; setisGrid(true); setisList(false); setisTile(false); setImpotedUser(false);}} iconName={"ContactCard"} /></span>:""}
            {settingData?.hideShowViews?.list? <span className={viewsSettings?.isList? styles.SelectedViewStyle:styles.defaultViewStyle}><Icon title={"List"} style={{cursor:"pointer"}} onClick={() => {dispatch(setViewSetting("isList")),setView("List"); whichViewIsEnabled = "List"; setisGrid(false); setisList(true); setisTile(false); setImpotedUser(false);}} iconName={"BulletedList"} /></span>:""}
            {settingData?.hideShowViews?.tile? <span className={viewsSettings?.isTile ? styles.SelectedViewStyle:styles.defaultViewStyle}><Icon onClick={() => {dispatch(setViewSetting("isTile")),setView("Tile"); whichViewIsEnabled = "Tile"; setisGrid(false); setisList(false); setisTile(true); setImpotedUser(false);}} iconName={"Waffle"} /></span>:""}

          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFilters;
