export const LangOptions = [
    {
      key: "cs",
      text: "Czech",
      value:"cs",
      label:"Czech",
    },
    {
      key: "nl",
      text: "Dutch",
      value:"nl",
      label:"Dutch",
    },
    {
      key: "en",
      text: "English",
      value:"en",
      label:"English",
    },
    {
      key: "fr",
      text: "French",
      value:"fr",
      label:"French",
    },
    {
      key: "de",
      text: "German",
      value:"de",
      label:"German",
    },
    {
      key: "hu",
      text: "Hungarian",
      value:"hu",
      label:"Hungarian",
    },
    {
      key: "it",
      text: "Italian",
      value:"it",
      label:"Italian",
    },
    {
      key: "ja",
      text: "Japanese",
      value:"ja",
      label:"Japanese",
    },
    {
      key: "pl",
      text: "Polish",
      value:"pl",
      label:"Polish",
    },
    {
      key: "pt",
      text: "Portuguese",
      value:"pt",
      label:"Portuguese",
    },
    {
      key: "ru",
      text: "Russian",
      value:"ru",
      label:"Russian",
    },
    {
      key: "es",
      text: "Spanish",
      value:"es",
      label:"Spanish",
    },
];
