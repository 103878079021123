// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailsListView_listStylesClass__czbSf {
  max-height: 700px;
  max-width: 100%;
}

.DetailsListView_listView__zTQZA {
  max-width: 100%;
  position: relative;
}

.DetailsListView_closeIconProfile__rymY9 {
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
  color: rgb(0, 120, 212);
}`, "",{"version":3,"sources":["webpack://./src/Components/SCSS/DetailsListView.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF;;AACA;EACE,eAAA;EACA,kBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,eAAA;EACA,uBAAA;AAGF","sourcesContent":[".listStylesClass {\n  max-height: 700px;\n  max-width: 100%;\n}\n.listView {\n  max-width: 100%;\n  position: relative;\n}\n.closeIconProfile {\n  position: absolute;\n  right: 10px;\n  top: 0px;\n  cursor: pointer;\n  color:rgb(0, 120, 212);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listStylesClass": `DetailsListView_listStylesClass__czbSf`,
	"listView": `DetailsListView_listView__zTQZA`,
	"closeIconProfile": `DetailsListView_closeIconProfile__rymY9`
};
export default ___CSS_LOADER_EXPORT___;
